@import 'src/Style/Style.scss';

.Fail-Container {
  @include FlexColumn;

  .Primary-Info-Block {
    @include FlexProps(center, flex-start);
    background: #d6edf5;
    border: 1px solid #183d5b2e;
    border-radius: 4px;
    padding: 21px 33px;
    margin-bottom: 58px;

    .Percentage {
      @include FlexCenter;
      @include TextProps(36px, bold, $white);
      height: 78px;
      width: 117px;
      background-color: #e57373;
      border-radius: 4px;
      margin-right: 23px;
    }

    .Description {
      @include TextProps(18px, normal, $bluish-black);
      word-break: break-word;
    }
  }

  .Secondary-Info-Block {
    @include FlexCenter;
    width: 100%;

    .Link {
      width: fit-content;
    }

    .Restart-Button,
    .Revisit-Button {
      @include FlexCenter;
      height: 58px;
      width: 353px;
      background-color: $bluish-black;
      border: 1px solid #183d5b2e;
      border-radius: 4px;
      transition: .3s;

      .Text {
        @include TextProps(20px, normal, $white);
      }

      &:hover {
        background-color: $white;
        border: 1px solid $bluish-black;

        .Text {
          color: $bluish-black;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Fail-Container {
    .Primary-Info-Block {
      flex-direction: column;

      .Percentage {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .Fail-Container {
    .Secondary-Info-Block {
      .Link {
        width: 100%;
      }

      .Restart-Button,
      .Revisit-Button {
        width: 100%;
      }
    }
  }
}

