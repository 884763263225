@import 'src/Style/Style.scss';

.CoursePackage-CreateEdit-Form {
  .Course-Package-Instructions {
    @include FlexCenter;
    flex-direction: column;
    background-color: $gray;
    border-radius: 5px;
    margin: 0 10px 10px;
    padding: 10px;

    h1 {
      @include TextProps(20px, bold, $white);
      margin-bottom: 10px;
    }

    ul {
      li {
        @include TextProps(14px, bold, $white);
        margin-bottom: 5px;
      }
    }
  }

  .Course-Package-Field-Instructions {
    background-color: $gray;
    border-radius: 5px;
    margin-top: 3px;
    padding-inline-start: 20px;
    padding-top: 5px;
    padding-bottom: 5px;

    li {
      @include TextProps(13px, normal, $white);
    }
  }

  .CreationForms{
    .Instructions {
      margin-bottom: 10px;
    }
  }

  .CreationForms-InputBox {
    margin-bottom: 10px;

    .InputBox-LogInBox {
      &:disabled {
        cursor: not-allowed;
      }
    }

    .TextArea {
      height: 120px;
      padding: 20px;
    }

    .CreationForms-ReactQuill {
      width: 100%;
      min-height: 100%;
    }

    .Renew-Reward-Field {
      margin: 10px 0;
    }
  }
}
