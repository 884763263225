@import 'src/Style/Style.scss';

.AdminDashboard-Credential {
  .Credential-Loader {
    @include FlexCenter;
  }

  .Exams-Container {
    background-color: $white;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

    .EditExam-Form {
      .Errors {
        ul {
          margin-bottom: 15px;

          li {
            font-size: 14px;
            color: $red;
          }
        }
      }

      .Submit-Button {
        width: 20%;
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;

      tr {
        @include TextProp;
        width: 312px;
        height: 61px;
        font-size: 14px;
        border-color: $bluish-black;

        th {
          font-weight: bold;
          background-color: rgba(24, 60, 91, 0.03);
        }

        td, th {
          border: 1px solid #ccc;
          vertical-align: middle;
          color: $bluish-black;
          font-size: 14px;
          padding: 10px 24px;
          min-width: 100px;
          max-width: 500px;
          word-break: break-all;
          line-height: 20px;
        }
      }
    }
  }
}
