.CreationForms-Title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #183D5B;
}

.CreationForms-Title .Title-SubTitle {
  display: flex;
  flex-direction: column;
}

.CreationForms-Form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CreationForms-InputBox {
  width: 100%;
}

.CreationForms-InputBox.Removed .InputBox-LogInBox {
  color: #8c8c8c;
  border-color: #8c8c8c;
}

.CreationForms-InputBox.Removed .ButtonBox-EditQuestions {
  background-color: #8c8c8c;
}

.CreationForms-InputBox .Note {
  font-family: 'LATO';
  font-size: 15px;
  background: #f0f0f0;
  color: #ff0000;
  border-radius: 5px;
  padding: 10px;
}

.CreationForms-InputBox .Note strong {
  margin-right: 5px;
  font-family: 'LATO';
}

.CreationForms-Button {
  width: 200px;
  margin: 10px 0;
}

.CreationForms-Credits {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
}

.CourseType-Select {
  width: 100%;
}

.CourseType-Select select {
  width: 100% !important;
  background-color: #fff;
}
