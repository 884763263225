@import 'src/Style/Style.scss';

.Workplace-Switch-Container {
  @include FlexCenter;
  @include FullHW;

  .Switcher-Loader {
    @include FlexCenter;
    @include FullHW;
    @include Fixed0;
    background-color: $black;
    opacity: 0.2;
    z-index: 99999;
  }

  .Switcher {
    @include TextProps(16px, bold, $bluish-black);
    display: flex;
    height: 50px;
    width: 100%;
    background-color: $white;
    border: none;
    border-bottom: 1px solid $gray;
    padding: 0 8px 0 0;
    text-indent: 40px;
  }
}
