.LessonBoxMini {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border: 2px solid #F0F0F0;
  cursor: pointer;
  padding: 10px;
}

.LessonBoxMini-Left{
  display: flex;
}

.LessonBoxMini-Right{
  display: flex;
}

.ButtonBox-LessonBoxMini-left {
  margin-bottom: 5px;
}

.ButtonBox-LessonBoxMini-right {
  margin-bottom: 5px 0;
}

.LessonBoxMini-ImageBox {
  width: 180px;
  height: 135px;
  margin-right: 15px;
}

.LessonBoxMini-DataBox {
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  justify-content: space-around;
  width: 380px;
}

.LessonBoxMini-DataBox-ButtonBoxes {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 15px;
  width: 130px;
}

@media only screen and (max-width: 1024px) {
  .LessonBoxMini-DataBox {
    width: 70%;
  }

  .LessonBoxMini-Left{
    width: 100%;
    display: flex;
  }
}

@media only screen and (max-width: 768px) {

  .LessonBoxMini {
    flex-direction: column;
  }

  .LessonBoxMini-Left{
    flex-direction: column;
    padding: 10px;
  }

  .LessonBoxMini-ImageBox {
    width: 60%;
    height: 100%;
    padding: 0px;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .LessonBoxMini-DataBox-title {
    margin: 0 auto;
    margin-bottom: 5px;
  }
  .LessonBoxMini-DataBox-description {
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .LessonBoxMini-Right{
    padding: 0 10px;

  }
  .LessonBoxMini-Right div:first-child{
    margin-bottom: 15px;
  }

  .LessonBoxMini-DataBox-ButtonBoxes {
    flex-direction: column;
    width: 100%;
    margin: 0px;
  }
}
