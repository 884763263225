.QuizAnswerButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  width: 100%;
}

.QuizAnswerButtons-ButtonBox {
  background-color: transparent;
  border: 1px solid #dbe0e3;
  height: 58px;
  margin: 3px;
}

.QuizAnswerButtons-ButtonBox i {
  margin-right: 10px;
}

.QuizAnswerButtons-ButtonBox i:last-of-type {
  margin-left: 10px;
}

.active {
  color: white;
  background-color: #6bd1be;
  border-color: #6bd1be;
}
