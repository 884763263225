@import 'src/Style/Style.scss';

.Clone-Modal-Container {
  @include FlexColumn;
  margin-top: 20px;

  .Buttons-Container {
    @include FlexProps(center, flex-end);
    margin-top: 100px;

    .Clone-Button {
      width: 200px;
    }
  }
}
