table {
  border-collapse: collapse;
}

.table_fill_in-Container .QuestionMini-QuestionBox {
  align-items: flex-start;
  flex-direction: column;
}

.table_fill_in-Container .QuestionMini-QuestionBox-Question {
  width: auto;
}

table, th, td {
  border: 2px solid #ebeef0;
}

th, td {
  vertical-align: top;
}

th {
  background-color: #f2f8fa;
}

@media only screen and (max-width: 768px) {
  table {
    max-width: 100%;
  }
}
