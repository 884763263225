@import 'src/Style/Style.scss';

@media only screen and (max-width: 768px) {
  .YouTube-Video {
    div:first-child {
      height: 250px !important;
      width: 500px !important;
    }
  }
}

@media only screen and (max-width: 550px) {
  .YouTube-Video {
    div:first-child {
      height: 100% !important;
      width: 100% !important;
    }
  }
}
