.LessonBox {
  position: relative;
  display: flex;
  border-radius: 5px;
  padding-bottom: 30px;
  flex-direction: column;
  background-color: white;
  border: 1px solid #183d5b20;
  box-shadow: 0 3px 12px rgba(27,31,35,.15);
  transition: 0.3s;
}

.LessonBox:hover {
  transform: scale(1.02);
  box-shadow: #32325d40 0 13px 27px -5px, #0000004d 0 8px 16px -8px;
  transition: 0.3s;
}

.LessonBox-Visible__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  max-width: 100%;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}

.LessonBox-Databox__container {
  display: flex;
  width: 70%;
}

.LessonBox-Position {
  height: 30px;
  width: 30px;
  margin-right: 15px;
  color: white;
  background-color: rgb(126, 194, 212);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.LessonBox-DataBox {
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  border-right: 1px solid #e0e2e2;
  padding-right: 25px;
  width: calc(100% - 25px);
  height: 110px;
}

.LessonBox-DataBox-title {
  margin-bottom: 10px;
}

.LessonBox-DataBox-description {
  margin-bottom: 30px;
}

.LessonBox-QuizResult__constainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LessonBox-Button-Icon__dropdown {
    background-color: transparent;
    border: none;
}

.LessonBox-Button-Icon__dropdown i {
  font-size: 22px;
  color: #183D5B;
}

.trial {
  opacity: 0.5;
}

@media screen and (min-width: 2300px) {
  .LessonBox-DataBox {
    height: 150px;
  }
}

@media only screen and (max-width: 1024px) {
  .LessonBox-DataBox {
      min-width: 0;
  }
}

@media only screen and (max-width: 768px) {
  .LessonBox {
      flex-direction: column;
      align-items: center;
  }

  .LessonBox-DataBox-description {
      margin-bottom: 20px;
  }

  .LessonBox-Visible__container {
      padding: 10px;
      flex-direction: column;
      width: calc(100% - 20px);
  }

  .LessonBox-Databox__container {
      width: 100%;
  }

  .LessonBox-DataBox {
      height: 130px;
      border-right: none;
      width: 100%;
  }
}

@media only screen and (max-width: 360px) {
  .LessonBox-DataBox {
      height: 150px;
  }
}
