.AudioProgressBox-Container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  height: 100%;
  width: 100%;
}

.AudioProgressBox-Container .AudioProgressBox-Button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6bd1be;
  background: transparent;
  border: none;
  margin-right: 5px;
}

.AudioProgressBox-Container .AudioProgressBox-Progress {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5px;
  width: 30%;
  background-color: #fff;
  border: 1px solid #183d5b2e;
  border-radius: 3px;
  position: relative;
}

.AudioProgressBox-Container .AudioProgressBox-Progress .AudioProgressBox-ProgressFiller {
  top: 0;
  left: 0;
  height: 100%;
  background-color: #6bd1be;
  border-radius: 3px;
  position: absolute;
  transition: width .5s linear;
}

.AudioProgressBox-Container .AudioProgressBox-Error {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: red;
  font-size: 16px;
}
