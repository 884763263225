@import 'src/Style/Style.scss';

.Study-Deck-Container {
  @include FlexColumn;
  background-color: $white;
  border-radius: 5px;
  padding: 20px;
  margin: 37px;

  .Head {
    @include FlexProps(center, space-between);
    flex-wrap: wrap;
    border-bottom: 1px solid $lightest-gray;
    padding-bottom: 10px;

    .Title {
      @include TextProps(20px, bold, $bluish-black);
    }

    .Actions {
      display: flex;
    }
  }

  .Body {
    display: flex;
    margin: 20px 0;

    .Study-Decks {
      @include FlexWrap;
      gap: 20px 30px;

      .Study-Deck {
        @include FlexColumn;
        min-height: 300px;
        width: 250px;
        background-color: var(--pColor);
        padding: 10px;
        border: 1px solid var(--sColor);
        border-radius: 5px;
        @include BoxShadow;

        .Name {
          @include FlexCenter;
          @include TextProps(18px, bold, $bluish-black);
          flex: 80%;
          word-break: break-word;
        }

        .Operations {
          @include FlexCenter;
          flex: 20%;
          background-color: var(--sColor);
          padding: 10px;
          border-radius: 5px;

          .Show-Study-Deck,
          .Edit-Study-Deck,
          .Delete-Study-Deck,
          .Play-Study-Deck {
            height: 37px;
            width: 37px;
            margin: 2px;

            .Icon {
              color: $bluish-black;
              font-size: 23px;
              transition: 0.3s;

              &:hover {
                transform: scale(1.3);
              }
            }
          }
        }
      }
    }
  }

  .Foot {
    display: flex;
    padding-top: 10px;
    border-top: 1px solid $lightest-gray;
  }
}

@media only screen and (max-width: 425px) {
  .Study-Deck-Container {
    margin: 15px;
  }
}
