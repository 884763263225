@import 'src/Style/Style.scss';

.SortingBox {
  @include FlexColumn;
  align-items: center;
  width: calc(100% - 80px);

  .SortingBox-Questions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }

  .SortingBox-Questions__container {
    @include FlexColumn;
    padding: 5px;
    align-items: center;

    .SortingBox-DropArea {
      @include FlexWrap;
      min-height: 200px;
      width: 100%;
      border: 2px solid #6bd1be;
      border-radius: 5px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .SortingBox {
    width: calc(100% - 40px);

    .SortingBox-Questions {
      grid-template-columns: 1fr;
    }
  }
}
