@import 'src/Style/Style.scss';

.Study-Card-Container {
  @include FlexColumn;
  background-color: $white;
  border-radius: 5px;
  padding: 20px;
  margin: 37px;

  .Head {
    @include FlexProps(center, space-between);
    flex-wrap: wrap;
    border-bottom: 1px solid $lightest-gray;
    padding-bottom: 10px;

    .Back-To-Study-Deck {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      gap: 5px;
    }

    .Title {
      @include TextProps(20px, bold, $bluish-black);
    }

    .Actions {
      @include FlexWrap;
      gap: 5px;
    }
  }

  .Body {
    display: flex;
    margin: 20px 0;

    .Study-Cards {
      @include FlexWrap;
      gap: 20px 30px;

      .Study-Card {
        @include FlexSpaceBetween;
        flex-direction: column;
        min-height: 300px;
        width: 250px;
        background-color: var(--pColor);
        padding: 10px;
        border: 1px solid var(--sColor);
        border-radius: 5px;
        @include BoxShadow;

        .Content {
          @include FlexCenter;
          flex-direction: column;
          height: 80%;
          margin: 10px 0;

          .Text {
            @include TextProps(16px, bold, $bluish-black);
            word-break: break-word;
          }
        }

        .Operations {
          @include FlexCenter;
          height: 20%;
          width: 100%;
          background-color: var(--sColor);
          padding: 10px;
          border-radius: 5px;

          .Edit-Study-Card,
          .Delete-Study-Card {
            height: 37px;
            width: 37px;
            margin: 2px;

            .Icon {
              color: $bluish-black;
              font-size: 23px;
              transition: 0.3s;

              &:hover {
                transform: scale(1.3);
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .Study-Card-Container {
    margin: 15px;
  }
}
