@import 'src/Style/Style.scss';

.B2B-Admin-Roster-Management-Container {
  @include FlexSpaceBetween;
  @include FlexColumn;
  background-color: $white;
  margin: 20px 62px;
  border-radius: 4px;
  padding: 22px 0px 0px 24px;

  .No-Data {
    @include TextProps(16px, bold, $red);
    margin: 10px 0;
  }

  .Actions-Container {
    @include FlexSpaceBetween;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
    min-height: 50px;

    .Actions {
      display: flex;
      flex-wrap: wrap;

      .Bulk-Action-And-Per-Page {
        display: flex;
        flex-wrap: wrap;

        .Bulk-Action {
          @include FlexCenter;
          position: relative;
          height: 47px;
          width: 185px;
          background: $white;
          border: 1px solid #7ec2d4;
          border-radius: 4px;
          margin: 5px 20px 5px 0;
          text-transform: capitalize;
          box-shadow: 0px 2px 2px rgba(0,0,0,0.04);

          .Icon {
            margin: 0 8px 0 16px;
            color: #6d86a1;
          }

          .Text {
            @include TextProps(16px, normal, $bluish-black);
            display: flex;
            min-width: 120px;
            text-transform: capitalize;
            appearance: none;
            cursor: pointer;
          }

          .ToolTip {
            margin-right: 15px;
          }

          .Rectangle-Img {
            position: absolute;
            top: 50px;
            left: 0px;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
            z-index: 999;
          }

          .Drop-Down {
            position: absolute;
            top: 80px;
            left: 0px;
            width: 300px;
            z-index: 1000 ;

            .Temporary-Password, .Set-Deadline, .Deactivate-List {
              @include TextProps(16px, normal, $bluish-black);
              display: flex;
              align-items: center;
              height: 30px;
              padding-left: 24px;
              letter-spacing: 0.2px;
              list-style: none;
              cursor: pointer;

              &:hover {
                background: #ededed;
              }
            }

            .Deactivate-List {
              color: #ef5350;
            }
          }
        }

        .Per-Page-Drop-Down {
          @include FlexCenter;
          position: relative;
          height: 47px;
          width: 185px;
          background-color: $white;
          border: 1px solid #7ec2d4;
          border-radius: 4px;
          margin: 5px 18px 5px 0;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);

          .Records-Per-Page {
            @include TextProps(16px, normal, $bluish-black);
            width: 185px;
            background-color: $white;
            border-radius: 4px;
            padding: 0px 8px 0px 24px;
            letter-spacing: 0.2px;
            appearance: none;
            border: none;
          }

          .Drop-Down-Arrow-Icon {
            position: absolute;
            color: #6A6A6A;
            right: 8px;
            cursor: pointer;
          }
        }
      }

      .SearchBar {
        .Input {
          @include TextProps(16px, normal, $bluish-black);
          height: 47px;
          width: 530px;
          background-color: $white;
          border: 1px solid #7ec2d4;
          border-radius: 30px;
          margin:5px 0;
          padding: 0px 8px 0px 45px;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
        }
      }
    }

    .Invite-Users {
      margin: 5px 24px 5px 0;
    }
  }

  .Scrollable-Table {
    overflow-x: scroll;
    width: 1395px;

    .Table {
      width: 100%;
      margin-bottom: 20px;
      border-collapse: collapse;

      tbody {
        tr:nth-child(odd) {
          background-color: $white;
        }

        tr:nth-child(even) {
          background-color: #183c5b1a;
        }
      }

      tr {
        @include TextProps(16px, bold, $bluish-black);
        text-align: center;
        white-space: nowrap;
        letter-spacing: 0.2px;
        background-color: rgba(24, 60, 91, 0.03);

        th {
          padding: 20px;
          font-size: 16px;
          max-width: 500px;
          text-align: center;
          white-space: nowrap;
          background-color: rgba(24, 60, 91, 0.03);
        }

        td {
          padding: 20px;
          font-size: 14px;
          max-width: 500px;
          text-align: center;
          white-space: nowrap;
          font-weight: normal;
          background-color: rgba(24, 60, 91, 0.03);

          .CheckBox-Container {
            @include TextProps(14px, normal, $bluish-black);
            display: flex;
            margin: 0 0 5px;

            .CheckBox {
              height: 14px;
              width: 14px;
              margin: 0 3px 3px 0;

              input {
                height: 12px;
                width: 12px;
              }

              .CheckBox-Custom {
                height: 15px;
                width: 15px;
                background-color: $white;
                border-color: $light-green;
                margin: 4px;

                &::after {
                  top: 0;
                  left: 2px;
                  height: 8px;
                  width: 5px;
                  border-color: $white;
                }
              }
            }

            .CheckBox input:checked ~ .CheckBox-Custom {
              background-color: $light-green;
            }
          }

          .Name {
            color: $dark-green;
            font-weight: bold;
          }

          .Access-Yes {
            color: #28a818;
          }

          .Access-No {
            color: #ff3b30;
          }

          .Link {
            color: $dark-green;
            text-decoration: underline;
          }

          li {
            text-align: start;
          }
        }

        .DropDown-Td {
          position: relative;
          padding: 10px 45px;

          .Select-Action {
            height: 45px;
            width: 110px;
            font-size: 16px;
            background: transparent;
            border: none;
            appearance: none;
          }

          .Drop-Down-Arrow-Icon {
            position: absolute;
            left: 150px;
            top: 26px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .B2B-Admin-Roster-Management-Container {
    .Actions-Container {
      justify-content: center;

      .Actions {
        justify-content: center;

        .Bulk-Action-And-Per-Page {
          justify-content: center;
        }

        .SearchBar {
          .Input {
            max-width: 300px;
            padding: 0px 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .B2B-Admin-Roster-Management-Container {
    margin: 20px;

    .Actions-Container {
      .Actions {
        .SearchBar {
          .Input {
            max-width: 220px;
            padding: 0px 10px;
          }
        }
      }
    }
  }
}
