.CanopyLearnPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CanopyLearnPage,
.CanopyLearnPage > div:first-child {
  background-color: #e7ebed;
}

.CanopyLearnPage .CanopyLearnPage-Loading {
    margin-top: 100px;
}

.CanopyLearnPage.Warning-Message {
  align-items: center;
  justify-content: center;
}

.CanopyLearnPage.Warning-Message .Message-Container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 50%;
    width: 50%;
    background-color: white;
    border-radius: 5px;
    text-align: center;
    padding: 20px;
}

.bg-white {
    background-color: #fff;
}

.CanopyLearnPage-Banner__container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.CanopyLearnPage-Banner {
    color: white;
    background-color: #183D5B;
    padding: 10px 30px;
    position: relative;
}

.CanopyLearnPage-Banner::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 10px solid rgb(231, 235, 237);
    position: absolute;
    top: 0px;
    left: 0px;
}

.CanopyLearnPage-Banner::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-right: 10px solid rgb(231, 235, 237);
    position: absolute;
    top: 0px;
    right: 0px;
}

@media only screen and (max-width: 768px) {
    .CanopyLearnPage.Warning-Message .Message-Container {
        height: 90%;
        width: 90%;
        padding: 10px;
    }
}
