/* LessonBox Buttons  */
.ButtonBox-LessonBox-left {
  cursor: pointer;
  width: 100%;
  height: 78px;
  border: 2px solid #F0F0F0;
  border-radius: 4px;
  background-color: solid #F0F0F0;
  color: #669dac;
}

.ButtonBox-LessonBox-left:last-child:hover {
  color: #F0F0F0
}

.ButtonBox-LessonBox-left:hover {
  background-color: inherit;
  border: 2px solid #F0F0F0;
}

.ButtonBox-LessonBox-right {
  cursor: pointer;
  width: 100%;
  height: 78px;
  border: 2px solid #F0F0F0;
  border-radius: 4px;
  background-color: inherit;
  color: #F0F0F0;
}

.ButtonBox-LessonBox-right:last-child:hover {
  color: #669dac;
}

.ButtonBox-LessonBox-right:hover {
  background-color: solid #F0F0F0
}

.CreationDashboards-ButtonBox {
  cursor: pointer;
  width: 100%;
  height: 40px;
  border: 2px solid #7fc3d5;
  border-radius: 4px;
  background-color: #7fc3d5;
  color: #F0F0F0;
  padding: 0 10px;
}

.CreationDashboards-ButtonBox:hover {
  background-color: #F0F0F0;
  color: #669dac;
}

.TopicBox-ButtonBox {
  border: 2px solid #F0F0F0;
  margin: 5px 0;
}

/* LessonBox Buttons End */

/* LessonBoxMini Buttons */

.ButtonBox-LessonBoxMini-left {
  cursor: pointer;
  width: 100%;
  height: 50px;
  border: 2px solid #7fc3d5;
  border-radius: 2px;
  background-color: #7fc3d5;
  color: white;
}

.ButtonBox-LessonBoxMini-left:hover {
  background-color: inherit;
  border: 2px solid #F0F0F0;
  color: #7fc3d5;
}

.ButtonBox-LessonBoxMini-right {
  cursor: pointer;
  width: 100%;
  height: 50px;
  border: 2px solid #7fc3d5;
  border-radius: 2px;
  background-color: inherit;
  color: #7fc3d5;
}

.ButtonBox-LessonBoxMini-right:hover {
  background-color: #7fc3d5;
  color: #669dac;
}

/* LessonBoxMini Buttons End */

/* NavButtonBox Button */
.ButtonBox-NavButtonBox {
  background-color: transparent;
  border: 2px solid #F0F0F0;
  border-radius: 5px;
  color: #76e4e0;
  width: 100%;
  height: 100%;
}

.ButtonBox-NavButtonBox:hover {
  background-color: #76e4e0;
  border: 2px solid #F0F0F0;
  border-radius: 5px;
  color: white;
  width: 100%;
  height: 100%;
}

.ButtonBox-DashboardNavButtons {
  background-color: transparent;
  border: 2px solid #F0F0F0;
  border-radius: 5px;
  color: white;
  width: 100%;
  height: 100%;
}
/* NavButtonBox Button End */

/** Accordion Button */
.ButtonBox-Accordion {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: white;
}

.ButtonBox-Accordion img {
  width: 17px;
  height: 17px;
  margin-right: 10px;
}

.ButtonBox-Accordion i {
  font-size: 24px;
}

.ButtonBox-AccordionBox-NavigationLesson {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0px;
  color: #7fc3d5;
}

.ButtonBox-AccordionBox-NavigationLesson img {
  width: 17px;
  height: 17px;
  margin-right: 10px;
}
/** Accordion Button Ends*/

/* AuthPage Button */
.ButtonBox-AuthPage.AddLanguage{
  border: none;
  color: #183c5b;
  font-size: 14px;
  font-family: Lato;
  border-radius: 30px;
  letter-spacing: 0.4px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
}

.ButtonBox-AuthPage.AddLanguage span {
  font-weight: bold;
}

.ButtonBox-AuthPage {
  width: 100%;
  height: 52px;
  color: #F0F0F0;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #7fc3d5;
  background-color: #7fc3d5;
}

.ButtonBox-AuthPage-reverse {
  cursor: pointer;
  width: 100%;
  height: 52px;
  border: 2px solid #7fc3d5;
  border-radius: 3px;
  background-color: white;
  color: #7fc3d5;
}

 .ButtonBox-AuthPage:hover, .ButtonBox-AuthPage-reverse:hover {
  background-color: #3d6280;
  color: #F0F0F0;
}
/* AuthPage Button End */

/* AdminForm Button */
.ButtonBox-AdminForm {
  cursor: pointer;
  width: 100%;
  height: 52px;
  border: 1px solid #7fc3d5;
  border-radius: 3px;
  background-color: #7fc3d5;
  color: #F0F0F0;
}

.ButtonBox-AdminForm-reverse {
  cursor: pointer;
  width: 100%;
  height: 52px;
  border: 2px solid #7fc3d5;
  border-radius: 3px;
  background-color: white;
  color: #7fc3d5;
}

 .ButtonBox-AdminForm:hover, .ButtonBox-AdminForm-reverse:hover {
  background-color: #3d6280;
  color: #F0F0F0;
}
/* AdminForm Button End */

/* MultiButtonChoiceBox Buttons */
.ButtonBox-MultiButtonChoiceBox {
  color: #183D5B;
  background-color: #f9fdfd;
  border: 2px solid #a1d4e1;
  border-radius: 1px;
  min-height: 75px;
  width: 100%;
}

.ButtonBox-MultiButtonChoiceBoxMini {
  color: #183D5B;
  background-color: #f9fdfd;
  border: 2px solid #a1d4e1;
  border-radius: 1px;
  min-height: 40px;
  width: 100%;
}

.ButtonBox-MultiButtonChoiceBox-selected {
  color: #f9fdfd;
  background-color: #183D5B;
  border: 2px solid #a1d4e1;
  border-radius: 1px;
  min-height: 75px;
  width: 100%;
}

.ButtonBox-MultiButtonChoiceBox-correct {
  color: #183D5B;
  background-color: #f5fcf9;
  border: 2px solid #7de3bb;
  border-radius: 1px;
  min-height: 75px;
  width: 100%;
}

.ButtonBox-MultiButtonChoiceBoxMini-correct {
  color: #183D5B;
  background-color: #f5fcf9;
  border: 2px solid #7de3bb;
  border-radius: 1px;
  min-height: 40px;
  width: 100%;
}

.ButtonBox-MultiButtonChoiceBoxMini-selected {
  color: #f9fdfd;
  background-color: #183D5B;
  border: 2px solid #a1d4e1;
  border-radius: 1px;
  min-height: 40px;
  width: 100%;
}

.ButtonBox-MultiButtonChoiceBox-wrong {
  color: #183D5B;
  background-color: #fcf9f9;
  border: 2px solid #D47e7e;
  border-radius: 1px;
  min-height: 75px;
  width: 100%;
}

.ButtonBox-MultiButtonChoiceBoxMini-wrong {
  color: #183D5B;
  background-color: #fcf9f9;
  border: 2px solid #D47e7e;
  border-radius: 1px;
  min-height: 40px;
  width: 100%;
}
/* MultiButtonChoiceBox Buttons End */

/* AudioPlayButton Buttons */
.ButtonBox-AudioPlayButton {
  background-color: transparent;
  border: none;
  color: #7fc3d5;
  height: 43px;
  width: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ButtonBox-AudioPlayButton:hover {
  color: #3d6280;
}

.ButtonBox-AudioPlayButton-icon {
  background-color: #f8fcfd;
  border: 0 solid;
  border-radius: 50%;
  color: #e0524a;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.ButtonBox-AudioPlayButton-icon p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ButtonBox-AudioPlayButton-icon i {
  font-size: 22px;
}

.Download-Button-Holder .Download-Button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
}

/* AudioPlayButton Buttons End */

.ButtonBox-FlashCard {
  background-color: #f8fcfd;
  border: 2px solid #b9dbe6;
  border-radius: 5px;
  color: #3d6280;
  padding: 10px;
}

.ButtonBox-FlashCard-icon {
  background-color: #f8fcfd;
  border: 2px solid #7fc3d5;
  border-radius: 50%;
  color: #7fc3d5;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.ButtonBox-FlashCard-icon:hover {
  border-color: #3d6280;
  color: #3d6280;
}

.ButtonBox-FlashCard-icon p, .ButtonBox-FlashCard-icon p .ImageBox {
  height: 100%;
  width: 100%;
  padding: 2px;
}

.ButtonBox-EditQuestions {
  background-color: #7fc3d5;
  color: #f8fcfd;
  border: none;
  border-radius: 3px;
  height: 35px;
  width: 35px;
  margin-left: 5px;
}

.ButtonBox-EditQuestions:hover {
  background-color: #F0F0F0;
  color: #3d6280;
}

/* SideBar Buttons */
.ButtonBox-SideBar {
  height: 38px;
  width: 38px;
  background-color: transparent;
  border: none
}
/* SideBar Button End */

.ButtonBox-CheckAnswers {
  cursor: pointer;
  width: 300px;
  height: 52px;
  margin-top: 30px;
  border: 2px solid #7fc3d5;
  border-radius: 3px;
  background-color: #7fc3d5;
  color: #F0F0F0;
}

.ButtonBox-CheckAnswers:hover {
  background-color: #F0F0F0;
  color: #a8a8a8;
}

.ButtonBox-Exit-X {
  background: none;
  border: none;
}

.ButtonBox-Exit-X i {
  font-size: 30px;
  color: #8c9dac;
}

@media only screen and (max-width: 768px) {
  .ButtonBox-FlashCard-icon {
    height: 28px;
    width: 28px;
  }
}
