.TableEntryBox {
  display: flex;
  align-items: center;
  background-color: inherit;
  min-height: 50px;
  min-width: 150px;
  padding: 10px;
}

@media only screen and (max-width: 768px) {
  .TableEntryBox {
    min-width: 0;
    padding: 5px;
  }
}