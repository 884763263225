.CreationDashboards {
  background-color: white;
  min-height: calc(100vh - 177px);
  display: flex;
  flex-direction: column;
  font-family: Lato;
}

.CreationDashboard-CourseTypes {
  display: flex;
  flex-direction: column;
  margin: 20px 60px;
}

.CreationDashboard-CourseTypes .CourseTypes-Title {
  padding: 0 0 20px 0;
  border-bottom: 1px solid;
  font-weight: bold;
}

.CreationDashboard-CourseTypes .CourseTypes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0 0 0;
}

.CourseTypes .CourseType {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.CreationDashboards-Menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 60px;
  padding-bottom: 20px;
  border-bottom: 1px solid #183D5B;
}

.CreationDashboards-DataBox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.CreationDashboards-Course-Box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 40px;
}

.CreationDashboards-Course-CircularProgressBar {
  width: 80%;
  margin-top: 15px;
  margin-bottom: 20px;
}

.CreationDashboards-Course-Button {
  width: 80%;
  margin-bottom: 20px;
}

.CreationDashboards-Menu-Button {
  width: 200px;
}

@media only screen and (max-width: 1024px) {
  .CreationDashboards-DataBox {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .CreationDashboards-Course-Box{
    margin: 30px 0;
  }

  .CreationDashboards-Course-CircularProgressBar {
    width: 50%;
  }

  .CreationDashboards-Course-Button {
    width: 50%;
  }

  .CreationDashboards-DataBox {
    grid-template-columns: 1fr;
  }
}
