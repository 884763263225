@import 'src/Style/Style.scss';

.Grant-Access-Container {
  .Manage-User-Access {
    .Full-Width {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .CreateUser-Update-Button {
      margin-bottom: 5px;
    }

    .Verify-Body {
      position: relative;

      .ValidEmail {
        background-color: $light-green;
        border-color: rgb(107, 209, 190);
        color: white;
      }

      .Check-Icon {
        display: inline-block;
        position: absolute;
        right: 20px;
        top: 5px;
        color: $white;
        font-size: 30px;
      }
    }
  }

  .Button-Container {
    @include FlexProps(center, space-between);

    .DashboardFormBox-CancelBtn {
      border: 1px solid $lightest-gray;
      margin: 0;
    }
  }
}
