.CreationForms-OptionsBox, .CreationForms-AnswersBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.CreationForms-SpanishKeyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}