@import 'src/Style/Style.scss';

.Score-Card-Description-Container {
  width: 100%;
  background-color: #e5e8eb;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 16px 0 32px 0;
  padding: 16px 24px;

  @media print {
    box-shadow: none;
  }

  .Description-Summary {
    @include TextProps(18px, normal, $bluish-black);
    line-height: 26px;
    letter-spacing: 0.2px;
    white-space: normal;

    @media print {
      letter-spacing: 0.6px;
    }

    a {
      color: $bluish-black;
      text-decoration: underline;
    }
  }

  .Description-Columns {
    @include FlexWrap;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    @media print {
      @include FlexWrap;
      width: 100%;
    }

    .Description-Column {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      margin-top: 16px;
      padding-left: 12px;
      padding-right: 12px;

      .Score-Card-Description {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        height: 100%;
        padding: 16px;
        background-color: $white;

        @media print {
          box-shadow: none;
        }

        .Description-Title {
          @include TextProps(14px, bold, $gray);
          text-transform: uppercase;
          margin-bottom: 5px;
        }

        .Description-Level {
          @include FlexCenter;
          min-width: 185px;
          height: 76px;
          line-height: 60px;
          float: left;
          border-radius: 4px;
          margin-right: 16px;
          padding: 8px;

          .Canopy-Level {
            @include TextProps(16px, bolder, $bluish-black);
            letter-spacing: 0.2px;
            line-height: 20px;

            @media print {
              font-size: 14px;
            }
          }

          @include mobile {
            float: none;
            width: 100%;
            max-width: 100%;
            margin-bottom: 16px;
          }

          @media print {
            float: left;
            width: 150px;
            margin-right: 0px;
          }
        }

        .Description-Desc {
          @include TextProps(18px, normal, $bluish-black);
          letter-spacing: 0.2px;
          white-space: normal;
          text-align: left;
          margin-bottom: 16px;
          line-height: 27px;
        }

        &.Vertical {
          @include FlexColumn;
          align-items: center;

          @include tablet-down {
            display: inherit;
          }

          @media print {
            display: flex;
            height: 100%;
          }

          .Description-Level {
            float: unset;
            margin-bottom: 8px;

            .Canopy-Level {
              @include TextProps(16px, bold, $bluish-black);
              white-space: normal;

              @media print {
                font-size: 18px;
              }
            }

            @include tablet-down {
              float: left;
            }

            @media print {
              float: unset;
            }
          }
        }

        &.Horizontal {
          .Description-Level {
            @media print {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}
