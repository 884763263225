.sidebar-modal {
  margin: auto;
  width: 303px !important;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  box-shadow: 8px 0px 15px rgba(0,0,0,0.1);
  z-index: 20 !important;
}

.modal-content-navigation {
  background-color: white;
  max-width: 1024px;
  width: 100%;
  min-height: 300px;
  border-radius: 2px;
  overflow: auto;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 1s;
  animation-name: animatetop;
  animation-duration: 1s;
}

@-webkit-keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}

@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}

.NavigationBox {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}

.NavigationBox-Title {
  border-bottom: 1px solid #d8d8d7;
  background-color: white;
  width: 100%;
  max-width: 939px;
  padding: 18px;
  display: block;
  justify-content: space-between;
  position: fixed;
  margin-top: 65px;
}

.NavigationBox-Title .NavigationBox-Back p {
  height: 13px;
  font-family: Lato;
  font-size: 10px;
  color: #6bd1be;
  text-decoration: none solid rgb(107, 209, 190);
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
}

.NavigationBox-Title .NavigationBox-Data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NavigationBox-Title .NavigationBox-Data h4 {
  width: 100%;
  height: 22px;
  font-family: Lato;
  font-size: 18px;
  color: #193c5a;
  text-decoration: none solid rgb(25, 60, 90);
}

.NavigationBox-Title .NavigationBox-Data button {
  padding: 0;
  background-color: #ebebeb;
}

.NavigationBox-Title .NavigationBox-Progress {
  height: 30px;
  margin-top: 5px;
  text-transform: uppercase;
}

.NavigationBox-Title .NavigationBox-Progress span {
  padding: 5px 10px;
  background-color: #6bd1be;
  border-radius: 4px;
  color: white;
  font-size: 12px;
}

.NavigationBox-Content {
  margin: 25vh 0 10vh 0;
  height: 100%;
}

.NavigationLesson {
  padding: 0 10px 0 20px;
  display: flex;
  flex-direction: column;
}

.NavigationLesson-fixed {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  margin: 5px 0;
}

.NavigationLesson-Title {
  width: 100%;
  display: flex;
  align-items: center;
}

.NavigationLesson-Title p {
  font-size: 14px;
  width: 200px;
  cursor: pointer;
}

.NavigationLesson-Title .CircularProgressbar {
  width: 24px;
  height: 24px;
  float: right;
}

.NavigationLesson-Position {
  height: 30px;
  width: 30px;
  margin-right: 15px;
  color: white;
  background-color: #52a1aa;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NavigationTopic-Button {
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.NavigationTopic-Button i {
  font-size: 14px;
  margin-right: 15px;
  color: #183D5B;
}

.sidebar-modal .NavigationTopic-Button i {
  margin-right: 0 !important;
  margin-left: 10px;
}

.NavigationTopic-Button-white i {
  color: white;
}

.NavigationTopics {
  padding: 0 0 0 20px;
}

.NavigationTopics-Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.3;
}

.NavigationTopics-Loader .css-0 {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavigationTopic {
  width: 100%;
  border-radius: 5px;
}

.NavigationTopic-fixed {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  align-items: center;
  margin: 5px 0;
}

.NavigationTopic:last-of-type {
  margin-bottom: 0;
}

.NavigationTopic-Title {
  display: flex;
  align-items: center;
}

.NavigationTopic-Title a {
  display: flex;
  align-items: center;
}

.NavigationTopic-Title p {
  font-size: 14px;
  width: 200px;
  cursor: pointer;
}

.NavigationTopic-Title .CircularProgressbar {
  width: 24px;
  height: 24px;
  float: right;
}

.NavigationTopic-ImageBox {
  margin-right: 15px;
}

.NavigationSection {
  padding: 0 0 0 20px;
  min-height: 50px;
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.NavigationSection:hover {
  background-color: #f3f3f3;
}

.NavigationSection p {
  font-size: 14px;
  width: 100%;
}

.NavigationSection .CircularProgressbar {
  width: 24px;
  height: 24px;
  float: right;
  margin-right: 24px;
}

.NavigationSection .ImageBox-Done {
  height: 25px;
  width: 25px;
  margin-right: 25px;
}

.NavigationSection-ImageBox-padding {
  background-color: #52a1aa;
  margin-right: 20px;
  padding: 6px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavigationSection-Selected {
  border-left-width: 5px;
  border-style: solid;
  border-color: #6bd1be;
  background-color: #f3f3f3;
  background-size: cover;
  font-family: Lato;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  cursor: pointer;
}

.NavigationBox-Footer {
  border-top: 1px solid #d8d8d7;
  background-color: white;
  width: calc(100% - 80px);
  max-width: 939px;
  padding: 25px 40px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
}

.NavigationBox-Footer-link {
  display: flex;
}

.NavigationBox-Footer-link i {
  margin: 0 10px;
  font-size: 20px;
  color: #52a1aa;
}

@media only screen and (min-width : 3000px) and (max-width : 5120px) {
  .NavigationBox-Title .NavigationBox-Back p {
    font-size: 15px !important;
  }

  .NavigationBox-Title .NavigationBox-Data h4 {
    font-size: 22px !important;
  }

  .NavigationBox-Title .NavigationBox-Progress span {
    font-size: 16px !important;
  }

  .NavigationLesson-Title p {
    font-size: 22px !important;
  }
}

@media screen and (min-width: 2300px) {

  .NavigationBox-Title .NavigationBox-Back p {
    font-size: 12px;
  }

  .NavigationBox-Title .NavigationBox-Data h4 {
    font-size: 20px;
  }

  .NavigationBox-Title .NavigationBox-Progress span {
    font-size: 14px;
  }

  .NavigationLesson-Title p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar-modal {
    width: 80%;
    background-color: inherit !important;
    top: 0 !important;
    z-index: 9 !important;
  }

  .modal-content-navigation {
    position: fixed;
    top: 76px;
    z-index: 10;
    height: calc(100vh - 76px);
  }

  .NavigationBox-Title {
    margin-top: 0;
  }

  .NavigationBox-Content {
    margin: 144px 0 71px 0;
  }

  .NavigationLesson {
    margin: 0px !important;
    width: calc(100% - 20px);
  }

  .NavigationLesson-fixed {
    padding: 0px;
    margin: 0 10px 0 0;
  }

  .NavigationTopics {
    padding: 15px;
    width: calc(100% - 30px);
  }

  .NavigationTopic {
    background-color: white;
    width: 100%;
  }
}
