.DashboardFormBox {
  background-color: white;
  width: 100%;
  margin: 10px 0;
  border-radius: 4px;
}

.DashboardFormBox-EditModeBtns,
.DashboardFormBox-ViewModeBtns {
  display: flex;
  align-items: center;
}

.DashboardFormBox-SaveBtn {
  height: 40px !important;
  width: 40% !important;
  color: white !important;
  background-color: #7fc3d5 !important;
}

.DashboardFormBox-CancelBtn {
  height: 40px !important;
  width: 40% !important;
}

.DashboardFormBox-Button_save {
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #183c5b;
  height: 28px;
  width: 74px;
  padding: 0 10px;
}

.DashboardFormBox-Button_save.full-width {
  height: auto;
  width: auto;
}

.DashboardFormBox-Button_save p {
  font-family: 'LATO';
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.DashboardFormBox-Button_save:disabled {
  opacity: 0.5;
}

.DashboardFormBox-Button_cancel {
  border: none;
  border-radius: 4px;
  background-color: transparent;
  color: rgba(24,60,91,0.8);
  height: 28px;
  width: 74px;
  margin-right: 16px;
}

.DashboardFormBox-Button_cancel:hover {
  background-color: #183c5b17;
}

.DashboardFormBox-Button_cancel p {
  font-family: 'LATO';
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.DashboardFormBox-Title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 51px;
  padding: 0 20px;
  border-bottom: 1px solid #d8d8d8;
}

.DashboardFormBox-Title_container.Inner {
  padding: 60px 0 15px;
}

.DashboardFormBox-Title {
  height: 22px;
}

.DashboardFormBox-Title .Title {
  color: rgba(24, 60, 91, 0.8);
  text-transform: uppercase;
}

.DashboardFormBox-Title p {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.4px;
}


.DashboardFormBox-Content_container {
  padding: 15px;
}

.DashboardFormBox-Content {
  display: flex;
  flex-direction: column;
}

.DashboardFormBox-Line {
  display: flex;
  align-items: flex-start;
}

.DashboardFormBox-Line.full-line .DashboardFormBox-Label {
  max-width: 500px;
}

.DashboardFormBox-Label {
  display: flex;
  flex: 50%;
  font-size: 12px;
  font-weight: bold;
  font-family: Lato;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  color: rgba(24, 60, 91, 0.5);
}

.DashboardFormBox-Label.Password-Label {
  flex-direction: column;
  margin-right: 10px;
}

.DashboardFormBox-Email {
  display: flex;
  flex-direction: column;
}

.DashboardFormBox-Email .email {
  font-family: 'LATO';
  font-size: 14px;
  color: #183c5b;
  letter-spacing: 0.2px;
}

.DashboardFormBox-Email .email-info {
  font-family: 'LATO';
  font-size: 9px;
  color: #183c5b;
  margin-top: 4px;
}

.DashboardFormBox-ProfilePhoto {
  display: flex;
  align-items: center;
}

.DashboardFormBox-ProfilePhoto .ProfilePhoto-Field {
  margin-left: 24px;
}

.DashboardFormBox-Button_edit {
  height: 28px;
  width: 74px;
  border: none;
  background-color: transparent;
  color: #7ec2d4;
  padding: 0;
}

.DashboardFormBox-Button_edit p {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
  letter-spacing: 0.4px;
  background-color: #6bd1be;
  text-transform: uppercase;
}

.blue {
  color: #183c5b;
  font-size: 14px;
  font-weight: normal;
}

.DashboardFormBox-Input_container {
  display: flex;
  flex-direction: column;
}

.DashboardFormBox-Input_container.Full {
  min-width: 300px;
}

.DashboardFormBox-Line.Half {
  grid-column-gap: 0% !important;
}

.DashboardFormBox-Input_container.Half {
  width: 25%;
}

.DashboardFormBox-Input.Quater {
  width: 100px;
}

.DashboardFormBox-Select-Language {
  width: 100%;
  height: 38px;
  padding: 0px 8px;
  border: 1px solid #7ec2d4;
  border-radius: 4px;
  font-family: Lato;
  font-size: 13px;
  color: #183c5b;
  text-decoration: none solid rgb(24, 60, 91);
  background-color: white;
}

.DashboardFormBox-Select {
  width: 100%;
  height: 32px;
  padding: 0px 8px;
  border: 1px solid #6bd1be;
  border-radius: 4px;
  font-family: Lato;
  font-size: 14px;
  color: #183c5b;
  text-decoration: none solid rgb(24, 60, 91);
  background-color: white;
}

.DashboardFormBox-Input {
  height: 32px;
  padding: 0px 8px;
  border: 1px solid #6bd1be;
  border-radius: 4px;
  background-size: cover;
  font-family: Lato;
  font-size: 14px;
  color: #183c5b;
  text-decoration: none solid rgb(24, 60, 91);
}

.DashboardFormBox-Info {
  margin: 24px 0;
}

.DashboardFormBox-Info p {
  color: #183c5b;
  font-size: 12px;
  line-height: 19px;
  font-family: 'LATO';
}

.DashboardFormBox-Line.Verify-User-Existence .Verify-Head,
.DashboardFormBox-Line.Manage-User-Access .Verify-Head {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.DashboardFormBox-Line.Verify-User-Existence .Verify-Head .formWidth,
.DashboardFormBox-Line.Manage-User-Access .Verify-Head .formWidth {
  display: flex;
  justify-content: space-between;
}

.DashboardFormBox-Line.Verify-User-Existence .Verify-Body,
.DashboardFormBox-Line.Manage-User-Access .Verify-Body {
  display: flex;
  justify-content: center;
  width: 100%;
}

.DashboardFormBox-Line.Verify-User-Existence .Verify-Body input,
.DashboardFormBox-Line.Manage-User-Access .Verify-Body input {
  margin-top: 0;
  margin-bottom: 0;
}

.DashboardFormBox-Content_container .Big-Text b,
.DashboardFormBox-Content_container .Small-Text b {
  font-weight: bold;
}

.DashboardFormBox-Content_container .Big-Text {
  font-size: 17px;
}

.DashboardFormBox-Content_container .Small-Text {
  font-size: 14px;
}

.DashboardFormBox-Content .Flex-Space-Between {
  display: flex;
  justify-content: space-between;
}

.DashboardFormBox-Content .Flex-Center {
  display: flex;
  justify-content: space-between;
}

.DashboardFormBox-Content .Danger-Message {
  color: red;
  margin-bottom: 10px;
}

.DashboardFormBox-Footer_container {
  display: flex;
  width: 100%;
}

.DashboardFormBox-Footer_container .DashboardFormBox-EditModeFooterBtns {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 22px;
  border-top: 1px solid #e7ebee;
}

@media only screen and (max-width: 1300px) {
  .EditUser .DashboardFormBox .DashboardFormBox-Line,
  .New-Workplace .DashboardFormBox .DashboardFormBox-Line,
  .My-Workplaces .DashboardFormBox .DashboardFormBox-Line,
  .New-Lingo .DashboardFormBox .DashboardFormBox-Line,
  .My-Lingos .DashboardFormBox .DashboardFormBox-Line {
    flex-direction: column;
  }

  .DashboardFormBox-Input_container.Half,
  .DashboardFormBox-Input_container.Full,
  .DashboardFormBox-Input_container {
    min-width: 100%;
  }

  .DashboardFormBox-Label {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 768px) {
  .DashboardFormBox-Line {
    align-items: baseline;
    margin: 15px 0;
  }

  .DashboardFormBox-Input {
    width: 100%;
  }

  .DashboardFormBox-Label {
    max-width: 200px;
  }

  .left {
    text-align: left;
    margin-bottom: 10px;
  }

  .DashboardFormBox-Select {
    width: 100%;
    height: 32px;
    padding: 0px 8px;
    border: 1px solid #6bd1be;
    border-radius: 4px;
    font-family: Lato;
    font-size: 14px;
    color: #183c5b;
    text-decoration: none solid rgb(24, 60, 91);
    background-color: white;
  }
}

@media screen and (min-width: 769px) {
  .DashboardFormBox-Line {
    margin: 15px 0;
  }

  .left {
    text-align: left;
    margin-bottom: 10px;
  }
    
  .DashboardFormBox-Label {
    max-width: 250px;
  }

  .DashboardFormBox-Input {
    width: 100%;
  }

  .DashboardFormBox-Input_container label {
    color: rgba(24,60,91,0.8);
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 425px) {
  .DashboardFormBox-Title_container {
    flex-direction: column;
    padding: 10px;
  }
}
