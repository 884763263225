@import 'src/Style/Style.scss';

.Study-Deck-Delete-Container {
  .Alert {
    .modal {
      .modal-content {
        border: none;
        border-radius: 5px;
        min-height: 200px;

        .modal-header {
          @include FlexProps(center, space-between);

          .Title {
            @include TextProps(20px, bold, $bluish-black);
          }
        }

        .modal-body {
          @include FlexColumn;
          margin: 30px 0;

          .Instructions {
            @include TextProps(18px, normal, $bluish-black);
          }
        }

        .modal-footer {
          @include FlexProps(center, flex-end);

          .Red-Button {
            background-color: $red !important;
            border-color: $red !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .Study-Deck-Delete-Container {
    .Alert {
      .modal {
        .modal-content {
          width: 90%;
        }
      }
    }
  }
}
