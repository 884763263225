@import 'src/Style/Style.scss';

.CourseLeftover-Container {
  .modal {
    z-index: 999999;

    .modal-content {
      overflow: inherit;
      border-radius: 4px;

      .modal-header {
        @include FlexSpaceBetween;
        min-height: 50px;
        border-bottom: 1px solid $light-gray;
        margin-bottom: 20px;

        .Heading {
          @include TextProps(25px, bold, $bluish-black);
          text-transform: uppercase;
        }

        .Button {
          @include FlexCenter;
          @include BacBorNone;
          height: 40px;
          width: 40px;
          transition: .5s;

          &:hover {
            background-color: $lightest-gray;
            border-radius: 5px;
          }
        }
      }

      .modal-body {
        .Description {
          @include TextProps(16px, normal, $bluish-black);
          margin-bottom: 20px;
        }

        .Hierarchy {
          display: flex;
          max-height: 500px;
          overflow-y: auto;

          .Accordion-Container {
            border-left: 2px dotted $bluish-black;
            margin-left: 20px;
            height: 100%;

            &.Topics-Accordion {
              margin: 20px;
            }

            .Entity {
              padding: 20px 30px;

              .Title {
                display: flex;
                @include BacBorNone;
                position: relative;

                p {
                  @include TextProps(16px, normal, $bluish-black);
                  text-align: start;

                  &:hover {
                    font-weight: bold;
                  }
                }

                &:before,
                &.Active:before {
                  @include FlexCenter;
                  @include TextProps(17px, bold, $white);
                  position: absolute;
                  left: -49px;
                  top: -8px;
                  height: 35px;
                  width: 35px;
                  font-family: "Font Awesome 5 Free";
                  background: $light-green;
                  border-radius: 3px;
                }

                &:before {
                  content: "\f107";
                }

                &.Active {
                  p {
                    font-weight: bold;
                  }

                  &:before {
                    content: "\f106";
                  }
                }
              }

              .Gray-Box {
                background: $lightest-gray;
                border-radius: 5px;
                margin-top: 20px;
                padding: 20px;

                ul {
                  padding: 10px 0 0 15px;
                }

                .Text, .Heading {
                  @include TextProps(16px, normal, $bluish-black);
                }

                .Heading {
                  font-size: 18px;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .CourseLeftover-Container {
    .modal {
      .modal-content {
        width: 80%;

        .modal-body {
          .Hierarchy {
            max-height: 300px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .CourseLeftover-Container {
    .modal {
      .modal-content {
        .modal-header {
          flex-wrap: wrap-reverse;

          .Close-Button {
            width: 100%;
            text-align: right;
          }
        }

        .modal-body {
          .Hierarchy {
            .Accordion-Container {
              .Entity {
                padding: 20px;

                .Title {
                  &:before,
                  &.Active:before {
                    left: -39px;
                  }
                }

                .Gray-Box {
                  width: 90%;
                  padding: 10px 10px 10px 25px;

                  .Text {
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

