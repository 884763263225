@import 'src/Style/Style.scss';

.Manage-Tests-Container {
  .Manage-User-Access {
    .Full-Width {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .CreateUser-Update-Button {
      margin-bottom: 5px;
    }

    .Verify-Body {
      position: relative;

      .ValidEmail {
        background-color: $light-green;
        border-color: rgb(107, 209, 190);
        color: white;
      }

      .Check-Icon {
        display: inline-block;
        position: absolute;
        right: 20px;
        top: 5px;
        color: $white;
        font-size: 30px;
      }
    }
  }

  .No-Tests {
    @include TextProps(16px, normal, $bluish-black);
    display: flex;
    background: $lightest-gray;
    border-radius: 5px;
    padding: 20px;
  }

  .Credential-Tests {
    display: flex;

    ol {
      width: 100%;
      padding-left: 0px;

      li {
        .Credential-Test {
          border-bottom: 1px solid $light-gray;
          margin-bottom: 20px;

          .Details  {
            @include FlexColumn;
            padding: 10px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }

          .Reset-Button, .Waive-Button {
            @include FlexCenter;
            display: inline-flex;
            height: 40px;
            min-width: 100px;
            color: $white;
            background-color: #7fc3d5;
            border: none;
            border-radius: 4px;
            margin: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .Button-Container {
    @include FlexProps(center, center);

    .DashboardFormBox-CancelBtn {
      border: 1px solid $lightest-gray;
      margin: 0;
    }
  }
}
