@import 'src/Style/Style.scss';

.Study-Card-Form-Container {
  .Form {
    .modal {
      .modal-content {
        border: none;
        border-radius: 5px;

        .modal-header {
          @include FlexProps(center, space-between);

          .Title {
            @include TextProps(20px, bold, $bluish-black);
          }
        }

        .modal-body {
          @include FlexColumn;
          margin: 50px 0;

          .Instructions {
            @include TextProps(16px, normal, $bluish-black);
            margin-bottom: 20px;
          }

          .Error-Container {
            ul {
              li {
                color: $red;
              }
            }
          }
        }

        .modal-footer {
          @include FlexProps(center, flex-end);
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .Study-Card-Form-Container {
    .Form {
      .modal {
        .modal-content {
          width: 90%;
        }
      }
    }
  }
}
