@import 'src/Style/Style.scss';

.ActivityPage {
  max-width: calc(100vw - 300px);
  margin: 0 auto;
  padding-top: 20px;

  .LearningPage-LessonAnswerButtons_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .ActivityPage-Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 80px);
    background-color: #fff;
    border: 2px solid #183d5b2e;
    border-radius: 4px;
    margin: 20px 0;
    padding: 40px;

    .QuizPage-TextBox {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .ActivityPage {
    max-width: 876px;
  }
}

@media only screen and (max-width: 900px) {
  .ActivityPage {
    .ActivityPage-Content {
      margin-bottom: 50px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .ActivityPage {
    max-width: 95vw;
  }
}

@media only screen and (max-width: 500px) {
  .ActivityPage {
    .ActivityPage-Content {
      width: calc(100% - 40px);;
      padding: 20px;
    }
  }
}
