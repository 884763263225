.createLicenseContainer__checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked {
            ~ {
                .checkmark {
                    background-color: #7ec2d4;
                    &:after {
                        display: block;
                    }
                }
            }
        }
    }
    .checkmark {
        border: solid 1px #7ec2d4;
        border-radius: 2px;
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: #ffffff;
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 4px;
            top: 0;
            width: 8px;
            height: 13px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.createLicenseContainer {
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin: auto;
    margin-top: 100px;
    border: solid 1px white;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    line-height: 1;

    &__header {
        font-size: 21px;
        text-align: left;
        font-weight: bold;
    }

    &__subheader {
        font-size: 16px;
        margin: 20px 0 30px 0;
    }

    &__textinput__small {
        height: 40px;
        width: 50%;
        color: #183d5b;
        background-color: rgb(248, 252, 253);
        border: 1px solid rgb(126, 194, 212);
        border-radius: 5px;
        font-size: 18px;
        margin: 5px 0 20px 0;
        padding: 0 2px;
    }

    &__textinput__large {
        height: 40px;
        width: 100%;
        color: #183d5b;
        background-color: rgb(248, 252, 253);
        border: 1px solid rgb(126, 194, 212);
        border-radius: 5px;
        font-size: 18px;
        margin: 5px 0 20px 0;
        padding: 0 5px;
    }

    &__textinput-error__ {
        height: 40px;
        width: 100%;
        color: #183d5b;
        background-color: rgba(212, 126, 126, 0.05);
        border: 1px solid rgb(212, 126, 126);
        border-radius: 5px;
        font-size: 18px;
        margin: 5px 0 20px 0;
        padding: 0 2px;
    }

    &__label-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__label {
        font-size: 14px;
        font-weight: 400;
        color: rgb(25, 60, 90);
    }

    &__label-error {
        font-size: 14px;
        font-weight: 400;
        color: rgb(212, 126, 126);
    }

    &__button {
        border: none;
        border-radius: 5px;
        font-size: 15px;
        color: white;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
        height: 50px;
        width: 90vw;
        background-color: rgb(24, 61, 91);
    }

    &__button:disabled {
        background-color: #cccccc;
    }

    &__flash {
        background-color: #7de3bb;
        color: white;
        padding: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
        border-radius: 3px;
      }

    &__select {
        height: 40px;
        width: 100%;
        border:none;
        color: #193c5a;
        background-color: rgb(248, 252, 253);
        font-size: 15px;
        border: 1px solid rgb(126, 194, 212);
        border-radius: 5px;
        margin: 5px 0 20px 0;
        padding: 0 5px;
    }

    .Error-Container {
        color: #e0524a;
        margin: 5px 0;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
    .createLicenseContainer {
        padding: 30px;
        &__button {
            width: 50%;
        }
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .createLicenseContainer {
        width: 800px;
        margin: auto;
        margin-top: 20px;
        &__button {
            width: 50%;
        }
    }
}
