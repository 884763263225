@import '~highcharts/css/highcharts';
@import 'src/Style/Style.scss';

.DonutChart {
  .highcharts-container {
    overflow-x: auto !important;

    .highcharts-tooltip {
      stroke: #E0EDF8;
      fill: $white;

      .highcharts-tooltip-box {
        fill: $white;
      }

      .heading {
        @include TextProps(14px, normal, $bluish-black);
        margin-bottom: 8px;
      }

      .sub-heading {
        @include TextProps(14px, normal, #193c5ab3);
        margin-bottom: 16px;
      }

      .started, .in-progress, .completed {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
      }

      .box {
        height: 8px;
        width: 8px;
        border-radius: 2px;
        margin-right: 4px;
      }

      .percentage {
        @include TextProps(12px, normal, $bluish-black);
        letter-spacing: 0.4px;
      }
    }
  }
}
