@import 'src/Style/Style.scss';

.Content-Loader {
  @include FlexCenter;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;

  .Message {
    @include TextProps(25px, bold, $dark-gray);
    margin-bottom: 10px;
  }
}

.Mirror-Loader,
.Progress-Loader {
  @include FlexCenter;
  @include FullHW;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #193c5a80;
  z-index: 999999;
  backdrop-filter: blur(2px);

  .Loader {
    @include FlexCenter;
    @include FlexColumn;

    .Message {
      @include TextProps(25px, bold, $white);
      margin-bottom: 10px;
    }

    .ProgressBar-Container {
      flex-direction: column;
      width: 80%;
      margin: 10px 0;

      .ProgressBar {
        height: 20px;
        width: 100%;
      }

      .ProgressBar-Text {
        margin-top: 10px;

        p {
          @include TextProps(16px, bold, $white);
          color: $white !important;
        }
      }
    }
  }
}
