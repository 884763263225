@-webkit-keyframes MoveUpDown {
  0% { transform: translateY(-25px); }
  100% { transform: translateY(0); }
}

@keyframes MoveUpDown {
  0% { transform: translateY(-25px); }
  100% { transform: translateY(0); }
}

.LessonsDashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LessonsDashboard-Title__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(100vw - 300px);
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid rgba(25, 60, 90, 0.25);
  -webkit-animation: MoveUpDown;
  animation: MoveUpDown;
  animation-duration: 1s;
}

.LessonsDashboard-Buttons {
  display: flex;
  align-items: center;
}

.LessonsDashboard-ButtonBox {
  background-color: transparent;
  border: 2px solid rgb(126, 194, 212);
  border-radius: 4px;
  height: 58px;
  width: 58px;
  color: rgb(126, 194, 212);
  flex-shrink: 0;
  margin-left: 20px;
}

.LessonsDashboard-Databox-LessonsSummaryBox {
  width: 100%;
  max-width: calc(100vw - 300px);
}

@media only screen and (max-width: 768px) {
  .LessonsDashboard {
    margin-top: 50px;
  }
  
  .LessonsDashboard-Databox {
      flex-direction: column-reverse;
      align-items: center;
  }

  .LessonsDashboard-Title__container {
      margin: 0 20px;
      width: calc(100% - 40px);
  }

  .LessonsDashboard-Title__container, .LessonsDashboard-Databox-LessonsSummaryBox {
    max-width: 95vw;
  }
}


@media only screen and (max-width: 1024px) {
  .LessonsDashboard-Buttons {
    flex-direction: column;
  }

  .LessonsDashboard-ButtonBox {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .LessonsDashboard-Title__container {
    flex-direction: column;
  }
}
