@import 'src/Style/Style.scss';

.User-Level-Setting {
  .Setting-Btn {
    @include FlexCenter;
    height: 28px;
    min-width: 74px;
    border: none !important;
    border-radius: 4px;
    padding: 0 10px;

    &.Green-Button {
      background-color: $light-green;
    }

    &.Red-Button {
      background-color: $red !important;
    }

    .Setting-Btn-Txt {
      @include TextProps(14px, bold, $white);
      letter-spacing: 0.4px;
    }
  }
}
