.Confirm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
}

.Confirm-ButtonBox-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.modal-content-confirmation {
  background-color: #F0F0F0;
  margin: 10% auto;
  padding: 20px;
  width: 400px;
  border: 1px solid #00030d;
  border-radius: 2px;
}