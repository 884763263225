@import 'src/Style/Style.scss';

.Learn-Dashboard {
  @include FlexColumn;
  margin-top: 32px;

  .ButtonHolder {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 65px;

    .ManageButton {
      @include FlexCenter;
      height: 45px;
      min-width: 280px;
      background: $white;
      color: $bluish-black;
      border: 1px solid $bluish-black;
      border-radius: 4px;
      margin-right: 30px;
      cursor: pointer;
      text-transform: uppercase;

      p {
        @include TextProps(16px, bold, inherit);
        letter-spacing: 1px;
      }

      &:hover {
        color: $white;
        background-color: $bluish-black;
      }
    }

    .Learn-Button {
      width: 280px !important;
    }
  }

  .Dynamic-Width {
    overflow-x: auto;
  }

  .Community-Activity-Chart-Container {
    @include FlexColumn;
    margin: 30px 60px;

    .Title {
      font-weight: bold;
      color: #183c5b;
      margin-bottom: 24px;
    }

    .Community-Activity-Chart {
      display: flex;
      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      padding: 24px;
    }
  }

  .Start-Learning-Link {
    width: 0;
    margin: 10px;
  }

  .My-Progresses-Container, .My-Activities-Container {
    @include FlexColumn;
    margin: 30px 60px;

    .Title {
      font-weight: bold;
      color: #183c5b;
      margin-bottom: 24px;
    }

    .CardHolder {
      @include FlexColumn;
      background: $white;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

      .Title-Container {
        @include FlexSpaceBetween;
        height: 65px;
        background-color: #f5f5f5;
        border-bottom: 1px solid #c2c2c2;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        padding: 0 24px;

        .Text {
          p {
            @include TextProps(18px, normal, $bluish-black);
          }
        }

        .Button {
          .btn {
            box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
          }
        }
      }

      .Tab-Container {
        display: flex;
        margin-bottom: 24px;
        overflow-x: auto;

        .Tab {
          @include FlexCenter;
          @include TextProps(18px, normal, $bluish-black);
          height: 65px;
          min-width: 228px;
          border-bottom: 2px solid transparent;
          cursor: pointer;
        }

        .Tab.Active {
          border-color: #3d6280;
        }
      }

      .Content-Container {
        padding: 30px;
      }

      .LearnTable, .ActivityTable {
        width: 100%;
        max-width: 100%;
        border: 1px solid #183c5b26;
        border-collapse: inherit;
        border-radius: 8px;

        thead {
          tr {
            background-color: #183c5b08;

            th {
              font-size: 16px;
              height: 64px;
              color: #183c5b;
              border: none;
              border-right: 1px solid #183c5b26;
              background: transparent;
              padding: 0 24px;
              text-align: left;
              font-weight: bold;
              vertical-align: middle;
              cursor: pointer;

              i {
                margin: 0 0 0 5px;
              }
            }

            th:last-of-type {
              border-right: none;
            }
          }
        }

        tbody {
          tr:nth-child(odd) {
            background-color: #fff;
          }

          tr:nth-child(even) {
            background-color: #183c5b1a;
          }

          tr {
            td {
              font-size: 16px;
              height: 64px;
              color: #183c5b;
              background: transparent;
              border: none;
              border-top: 1px solid #183c5b26;
              border-right: 1px solid #183c5b26;
              padding: 0 24px;
              text-align: left;
              vertical-align: middle;

              .Pass {
                color: #009688;
                font-weight: bold;
              }

              .Fail {
                color: #e0524a;
                font-weight: bold;
              }

              .Link {
                color: #009688;
                text-decoration: underline;
              }
            }

            td:last-of-type {
              border-right: none;
            }
          }
        }
      }
    }
  }

  .My-Bookmarks-Container,
  .My-Comments-Container {
    @include FlexColumn;
    margin: 30px 60px;
    border: 2px solid #747677;

    .Head {
      display: flex;
      align-items: center;
      height: 67px;
      border-bottom: 1px solid;
      padding: 0 34px;

      .Title {
        @include TextProps(18px, bold, $bluish-black);
        margin: 0 50px 0 0;
      }

      .Level-Btn-Holder {
        display: flex;

        .Btn {
          @include FlexCenter;
          @include TextProps(18px, normal, $bluish-black);
          height: 39px;
          width: 165px;
          background-color: #dadada;
          cursor: pointer;
        }

        .Btn.Active {
          color: $white;
          background-color: $bluish-black;
        }
      }
    }

    .Scrollable-Table {
      height: 410px;

      thead {
        tr {
          th {
            position: sticky;
            top: -1px;
            background-color: #e7ebed !important;
          }
        }
      }
    }

    .Content {
      min-width: 100%;

      .Table {
        width: 100%;
        border-collapse: collapse;

        tr {
          th {
            @include TextProps(18px, normal, $bluish-black);
            cursor: pointer;
          }

          td {
            @include TextProps(16px, normal, $bluish-black);
          }

          th, td {
            @include BacBorNone;
            text-align: left;
            min-width: 130px;
            max-width: 500px;
            padding: 10px 20px;
            word-break: break-word;

            .Link {
              color: $dark-green;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .Learn-Dashboard {
    .My-Bookmarks-Container,
    .My-Comments-Container {
      .Head {
        flex-direction: column;
        height: auto;
        padding: 10px 34px;

        .Title {
          margin: 0 0 10px;
        }

        .Level-Btn-Holder {
          flex-direction: column;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .Learn-Dashboard {
    .ButtonHolder {
      flex-direction: column;

      .ManageButton, .Learn-Button {
        margin: 10px 0;
      }
    }

    .My-Progresses-Container, .My-Activities-Container {
      .CardHolder {
        .Title-Container {
          flex-direction: column;
          padding: 10px 24px;
        }

        .Tab-Container {
          flex-direction: column;
          align-items: center;

          .Tab {
            width: auto;
            max-width: 228px;
          }
        }
      }
    }
  }
}
