@import 'src/Style/Style.scss';

.Overview-Container {
  @include FlexColumn;
  padding: 20px 0;

  .Chart-Container {
    .Chart {
      display: flex;
      border-bottom: 3px solid $light-gray;
      padding-bottom: 30px;
      margin-bottom: 30px;

      &:last-of-type {
        border-bottom: none;
      }

      #b2b_user-three_months-graph,
      #b2c_user-three_months-graph,
      #b2c_user-nine_months-graph {
        width: 100%;

        .highcharts-title {
          @include TextProps(inherit, bold, $bluish-black);
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Overview-Container {
    .Chart-Container {
      .Chart {
        flex-direction: column;
      }
    }
  }
}
