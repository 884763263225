.CoursePackageBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 352px;
  width: 300px;
  background-color: white;
  border-radius: 11px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, .2);
  margin-right: 30px;
  margin-bottom: 30px;
  position: relative;
}

.CoursePackageBox .CoursePackageTag {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 212px;
  top: -25px;
  left: 50px;
  font-size: 16px;
  background: #ff6f00;
  border-radius: 56px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.CoursePackageBox-DataBox {
  border: 1px solid white;
  border-bottom: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 32px 24px;
}

.CoursePackageBox-Series h5 {
  font-family: 'LATO';
  font-size: 12px;
  color: #183d5bbf;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.CoursePackageBox-Name {
  padding: 0 20px;
  margin: 8px 0 0;
}

.CoursePackageBox-Name h5 {
  font-family: 'LATO';
  font-size: 20px;
  font-weight: bold;
  color: #2c405a;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.CoursePackageBox-Name p {
  font-family: 'LATO';
  text-align: center;
}

.CoursePackageBox-Price {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0;
}

.CoursePackageBox-Price p {
  font-family: 'LATO';
  font-size: 14px;
  color: #183d5b;
  text-align: center;
}

.CoursePackageBox-Price span {
  font-family: 'LATO';
  font-size: 20px;
  color: #183d5b;
  text-align: center;
  margin-top: 6px;
}

.CoursePackageBox-Description .Description {
  font-family: 'LATO';
  font-size: 14px;
  color: #183d5b;
  padding: 0 20px;
  line-height: 1.25em;
}

.CoursePackageBox-Description .Description img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.CoursePackageBox__large {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 430px;
    padding: 20px;
    background-image: linear-gradient(38deg, rgb(39, 118, 184) 0%, rgb(155, 212, 235) 100%);
    border-radius: 10px;
    box-shadow: 0 3px 12px rgba(27,31,35,.15);
    margin-bottom: 30px;
}

.premiumPackage p {
  font-family: 'LATO';
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 7px;
}

.premiumPackage ul li {
  font-family: 'LATO';
  font-size: 14px;
  color: #ffffff;
  line-height: 24px;
}

.CoursePackageBox-Name-large {
  margin: 0 30px;
}

.CoursePackageBox-Certificates-label p {
  font-family: 'LATO';
  font-size: 14px;
  color: #183d5b;
}

.CoursePackageBox-Certificates-label ul {
  padding: 0;
}

.CoursePackageBox-Credits {
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CoursePackageBox-Credits-large {
  margin-bottom: 25px;
}

.CoursePackageBox-ButtonBox {
}

.ButtonBox-CoursePackageBox {
  background-color: #183D5B;
  color: #f8fcfd;
  border: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  height: 75px;
}

.ButtonBox-CoursePackageBox p {
  font-family: 'LATO';
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
    .CoursePackageBox__large {
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        padding: 20px;
        background-image: linear-gradient(38deg, rgb(39, 118, 184) 0%, rgb(155, 212, 235) 100%);
        border-radius: 10px;
        box-shadow: 0 3px 12px rgba(27,31,35,.15);
        margin-bottom: 30px;
    }

}

@media only screen and (max-width: 1100px) {
    .CoursePackageBox-DataBox-large {
        width: 100%;
    }

    .CoursePackageBox-ButtonBox {
    }
}
