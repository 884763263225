@import 'src/Style/Style.scss';

.WorkPlace {
  @include FlexColumn;
  width: 100%;

  .WorkPlace-Title-Container {
    @include FlexSpaceBetween;
    height: 60px;
    width: auto;
    background-color: $white;
    border-bottom: 1px solid #183c5b1a;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    margin: 10px 0 0 0;

    .Title {
      @include TextProps(14px, bold, $bluish-black);
      letter-spacing: 0.2px;
      text-transform: uppercase;
      margin-left: 21px;
    }
  }

  .WorkPlace-Description-Container {
    @include FlexCenter;
    background-color: $white;
    border-bottom: 1px solid #183c5b1a;
    padding: 21px;

    .Description {
      @include TextProps(12px, normal, $bluish-black);
      line-height: 19px;
    }
  }

  .WorkPlace-New-Container {
    @include FlexSpaceBetween;
    height: 60px;
    width: auto;
    background-color: $white;
    border-bottom: 1px solid #183c5b1a;

    .New-WorkPlace-ButtonBox {
      margin-left: 21px;

      .New-WorkPlace-Button {
        @include BacBorNone;

        .Btn {
          @include FlexCenter;
          @include TextProps(14px, bold, $bluish-black);
          letter-spacing: 0.4px;

          .Icon {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .WorkPlace-My-Container {
    .My-WorkPlace {
      .DashboardFormBox {
        margin: 0 0 10px;

        .ViewMode {
          .DashboardFormBox-Line {
            margin: 13px 0 !important;
          }
        }

        .EditMode {
          .DashboardFormBox-Line {
            margin: 5px 0 !important;
          }
        }

        .Designation, .Degree-In-Progress {
          display: flex;
          align-items: center;
          margin-bottom: 5px;

          .Add-Degree-Designation, .Add-Degree-In-Progress {
            @include FlexCenter;
            @include BacBorNone;
            margin-left: 24px;

            .Btn {
              @include FlexCenter;
              @include TextProps(14px, bold, $bluish-black);
              letter-spacing: 0.4px;

              .Icon {
                margin-right: 4px;
              }
            }
          }
        }

        .WorkPlace-Errors {
          @include FlexColumn;
          margin: 0 0 10px;

          li {
            @include TextProps(14px, normal, $red);
          }
        }
      }
    }
  }
}
