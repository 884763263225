@import 'src/Style/Style.scss';

.Certificate-Locator-Container {
  display: flex;

  &.Lesson-Dashboard-Tile {
    position: absolute;
    top: 60px;
    left: 30px;

    .Opener {
      height: 20px;
      width: fit-content;
      background-color: $light-gray;
      border-radius: 4px;
      padding: 7px;
    }
  }

  .Opener {
    display: flex;

    .Button {
      @include FlexCenter;
      @include BacBorNone;
      text-decoration: underline;

      .ImageBox {
        margin-right: 5px;
      }

      p {
        @include TextProps(16px, normal, $bluish-black);
      }
    }
  }

  .Popup {
    display: flex;

    .modal {
      .modal-content {
        .Modal-Title {
          margin-bottom: 30px;

          .Text {
            @include TextProps(25px, bold, $bluish-black);
          }
        }

        .Modal-Body {
          margin-bottom: 30px;

          .Description {
            @include FlexWrap;
            @include TextProps(16px, normal, $bluish-black);
            margin-bottom: 15px;

            &:last-of-type {
              margin-bottom: 0;
            }

            .Profile-Link {
              margin: 0 5px;
              text-decoration: underline;
            }
          }
        }

        .Modal-Footer {
          @include FlexColumn;

          .Check-Button {
            width: 100%;
            margin-bottom: 20px;
          }

          .Contact-Button {
            width: 100%;
            flex-direction: row-reverse;
            text-transform: inherit;

            .ImageBox {
              width: 30px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Certificate-Locator-Container {
    .Popup {
      .modal {
        .modal-content {
          width: 90%;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .Certificate-Locator-Container {
    .Popup {
      .modal {
        .modal-content {
          width: 80%;
        }
      }
    }
  }
}
