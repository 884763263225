@import 'src/Style/Style.scss';

.QuizSummaryBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .QuizSummaryBox-Content__container {
    max-width: 1024px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .QuizSummaryBox-Backdrop__review {
    min-height: 347px;
    width: 100%;
    background-color: rgb(214, 237, 245);
    margin: 10px 0;
  }

  .QuizSummaryBox-Recommended {
    width: 100%;
    background-color: rgb(214, 237, 245);
    padding: 60px 0;
    margin: 10px 0;

    .QuizSummaryBox-Recommended__container {
      @include FlexCenter;
      flex-wrap: wrap;
    }

    h2 {
      @include FlexCenter;
      @include TextProp;
      margin: 30px 0;
      font-weight: bold;
      font-style: italic;
      text-decoration: none solid rgb(24, 61, 91);
      letter-spacing: 1px;
    }

    .QuizSummaryBox-Recommended__box {
      @include FlexColumn;
      background-color: #3d6280;
      width: 30%;
      min-height: 250px;
      border-radius: 5px;
      margin: 10px 50px;
      color: $white;

      .QuizSummaryBox-Score__text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        width: calc(100% - 30px);
      }

      .QuizSummaryBox-Recommended__text {
        @include FlexSpaceBetween;
        margin: 0 28px;

        h2 {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-style: normal;
          color: #fff;
          text-align: center;
        }

        p {
          font-size: 28px;
          font-weight: bold;
        }
      }

      .QuizSummaryBox-Recommended__SubHeading {
        @include FlexSpaceBetween;
        margin-top: -24px;
        margin-left: 28px;
        font-size: 16px;
      }

      .QuizSummaryBox-Recommended__Ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        ul {
          margin: 20px;

          li {
            font-size: 24px;
            margin: 20px 0;
          }
        }
      }
    }
  }

  .QuizSummaryBox-Score__container {
    @include FlexCenter;
    @include FullHW;

    .QuizSummaryBox-Score__box {
      @include FlexCenter;
      @include FlexColumn;
      background-color: rgb(126, 194, 212);
      min-height: 219px;
      width: 373px;
      border-radius: 4px;
      margin: 10px;

      h1 {
        font-size: 58px;
        font-weight: normal;
        color: $white;
        margin: 20px 0;
      }

      h5 {
        font-size: 24px;
        color: $white;
        margin: 5px 0;
      }
    }

    .QuizSummaryBox-Analysis__box {
      @include FlexColumn;
      align-items: flex-start;
      min-height: 219px;
      width: 40%;
      margin: 10px;

      .QuizSummaryPage-Banner {
        color: $white;
        background-color: $bluish-black;
        padding: 10px 30px;
        margin: 0 10px 10px;
        position: relative;
        font-size: 22px;

        &::before {
          content: "";
          width: 0;
          height: 0;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-left: 10px solid rgb(214, 237, 245);
          position: absolute;
          top: 0px;
          left: 0px;
        }

        &::after {
          content: "";
          width: 0;
          height: 0;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-right: 10px solid rgb(214, 237, 245);
          position: absolute;
          top: 0px;
          right: 0px;
        }
      }

      .QuizSummaryPage-Desc {
        @include TextProp;
        font-size: 22px;
        line-height: 36px;
        margin: 10px;
      }
    }
  }
}

.QuizReviewBox__container {
  width: 100%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 637px) {
  .QuizSummaryBox {
    .QuizSummaryBox-Backdrop__review {
      .QuizSummaryBox-Score__container {
        flex-direction: column;

        .QuizSummaryBox-Score__box {
          width: 90%;
        }

        .QuizSummaryBox-Analysis__box {
          width: 90%;

          .QuizSummaryPage-Banner::before {
            border-top: 28px solid transparent;
            border-bottom: 28px solid transparent;
          }

          .QuizSummaryPage-Banner::after {
              border-top: 28px solid transparent;
              border-bottom: 28px solid transparent;
          }
        }
      }
    }

    .QuizSummaryBox-Recommended {
      .QuizSummaryBox-Recommended__container {
        .QuizSummaryBox-Recommended__box {
          width: 90%;
          margin: 10px;
        }
      }
    }
  }
}
