@import 'src/Style/Style.scss';

.createLicenseContainer {
  .License-Domains-Container {
    .License-Domain {
      @include FlexCenter;

      .createLicenseContainer__textinput__large {
        margin: 5px 0;
      }

      &.remove {
        .createLicenseContainer__textinput__large {
          color: $gray;
          border-color: $gray;
        }

        .ButtonBox-EditQuestions {
          background-color: $gray;
        }
      }
    }
  }
}
