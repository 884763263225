@import 'src/Style/Style.scss';

.Create-User-Resource-Container {
  .Verify-User-Existence {
    .Full-Width {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .Verify-Btn {
      margin-bottom: 5px;
    }

    .Verify-Body {
      position: relative;

      .ValidEmail {
        background-color: $light-green;
        border-color: rgb(107, 209, 190);
        color: white;
      }

      .Check-Icon {
        display: inline-block;
        position: absolute;
        right: 20px;
        top: 5px;
        color: $white;
        font-size: 30px;
      }
    }
  }

  .User-Resource-Form {
    .Input-Container {
      .Input {
        width: 100%;
      }

      .Input[type=file] {
        height: 40px;
        background-color: rgb(248, 252, 253);
        border: 1px solid rgb(126, 194, 212);
        border-radius: 5px;
        margin: 5px 0 20px 0;
        padding: 8px;
        font-size: 13px;
      }
    }
  }

  .Button-Container {
    @include FlexProps(center, space-between);

    .DashboardFormBox-CancelBtn {
      border: 1px solid $lightest-gray;
      margin: 0;
    }
  }
}
