.WordScrambleBox {
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 10px 5px;
  position: relative;
}

.WordScrambleBox-List__container {
  display: flex;
}

.Pin-Unanswered {
  width: 45px;
  height: 34px;
  color: #fff;
  background-color: #ff8f00;
  border-radius: 3px;
  position: absolute;
  top: 15px;
  left: -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

@media only screen and (max-width: 550px) {
  .WordScrambleBox-List__container {
    overflow-y: scroll;
  }
}
