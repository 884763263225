@import 'src/Style/Style.scss';

.Recurring-Subscriptions, .Canopy-Subscriptions, .Canopy-User-Resources {
  .Recurring-Subscription, .Canopy-Subscription, .Canopy-User-Resource {
    @include FlexCenter;
    flex-direction: column;
    width: calc(100% - 40px);
    margin: 20px;

    .Information {
      @include FlexProps(flex-start, center);
      flex-direction: column;
      width: 100%;
      margin-bottom: 20px;

      .Piece {
        @include FlexProps(center, inherit);
        @include TextProps(16px, normal, $bluish_black);
        height: 25px;
        margin-bottom: 5px;

        .Bold {
          font-weight: bold;
        }

        .AutoRenewal-Link {
          margin-left: 5px;

          .Turn-Off {
            @include BacBorNone;
            @include TextProps(16px, bold, $red);
            height: inherit;
            width: inherit;
            text-decoration: underline;
          }

          .Auto-Renew-Buttons {
            @include FlexCenter;
            flex-direction: column;
            width: 100%;
            margin: 10px;

            .Yes-Cancel-Subscription-Button, .No-Cancel-Subscription-Button {
              height: 75px;
              width: 85%;
              text-align: start;
              color: $white;
              border: none;
              margin-bottom: 20px;
              border-radius: 4px;
              padding: 10px 15px;
            }

            .Yes-Cancel-Subscription-Button {
              background-color: #669dac;
            }

            .No-Cancel-Subscription-Button {
              background-color: $white;
              color: #669dac;
              border: 1px solid #669dac;
            }
          }
        }
      }
    }

    .ProgressTable {
      @include FlexProps(flex-start, center);
      flex-direction: column;
      width: 100%;
      padding-bottom: 20px;
      overflow-x: auto;

      table {
        flex: 1;
        width: 100%;
        box-sizing: border-box;
        white-space: nowrap;

        thead {
          height: 61px;
          border-radius: 6px;
          border-style: solid;
          border-top-width: 1px;
          border-left-width: 1px;
          border-bottom-width: 1px;
          padding: 0px 0px 0px 24px;
          border-color: rgba(24, 60, 91, 0.15);
          background-color: rgba(24, 60, 91, 0.03);

          tr {
            height: 61px;

            th {
              @include TextProps(14px, bold, $bluish-black);
              min-width: 100px;
              padding: 0 10px 0 18px;
              text-align: left;
              vertical-align: middle;
              background-color: #f8f9fa;

              img {
                display: inline-block;
                vertical-align: middle;
              }
            }
          }
        }

        tr {
          .Related-Resource {
            color: #009688;
          }

          .Renewal {
            display: flex;

            .Renew-Link  {
              margin-right: 5px;

              a {
                color: #347cff;
              }
            }

            .AutoRenewal-Link  {
              margin-right: 5px;

              a {
                color: #347cff;
              }
            }

            .Days-Left {
              @include FlexCenter;
              @include TextProps(12px, bold, $white);
              height: 19px;
              min-width: 90px;
              background-color: $light-orange;
              border-radius: 4px;
              text-decoration: none solid rgb(255, 255, 255);
            }
          }


          .Achievement {
            color: #247cff;
          }

          td {
            @include TextProp;
            height: 50px;
            font-size: 14px;
            padding: 0 25px;
            text-align: left;
            vertical-align: middle;
            text-transform: capitalize;

            img {
              display: inline-block;
              vertical-align: middle;
            }
          }
        }

        tr:nth-child(even) {
          background-color: #e7ebee;
        }
      }
    }
  }
}
