@import 'src/Style/Style.scss';

.Credential-Score-Card {
  text-transform: none;

  .Score-Card-Button {
    color: $white;
    background: $bluish-black;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 5px 0;
  }

  .Score-Card-Modal {
    .modal {
      .modal-content {
        @include FullHW;
        background-color: $app-background;

        .modal-header {
          @include FlexProps(center, flex-end);
          width: 88%;
          margin: 0 auto;

          .Print-Button {
            .Print-Icon {
              font-size: 30px;
              color: $bluish-black;
            }
          }

          .Close-Button {
            margin-left: 20px;
          }
        }

        .modal-body {
          @include FlexCenter;
          width: 88%;
          height: auto;
          background-color: $white;
          padding: 4%;
          border-radius: 5px;
          margin: 20px auto 0 auto;

          .Error, .Missing-Results {
            @include FlexCenter;
            @include FullHW;

            p {
              @include TextProps(20px, normal, $bluish-black);

              a {
                color: $light-green;
              }
            }
          }

          .Score-Card {
          }
        }
      }
    }
  }

  .Score-Card-Row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .Score-Card-Col {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media print {
  @page {
    size: 1120px 1685px;
    margin: 4%;
    overflow: visible;
  }
}
