@import 'src/Style/Style.scss';

.License-Management-Container {
  width: 90%;
  margin: 10px auto;
  padding: 0;

  .Instructions {
    padding-left: 30px;

    li {
      @include TextProps(16px, normal, $bluish-black);
      line-height: 23px;

      .Download-Sample {
        color: $blue;
      }
    }
  }

  .Error-Container {
    .Errors {
      li {
        @include TextProps(16px, normal, $red);
      }
    }
  }

  .Institution-Field, .License-Field, .File-Field {
    @include FlexColumn;
    margin: 10px 0;

    .Label {
      @include TextProps(15px, bold, $bluish-black);

      .Link {
        color: $blue;
      }
    }

    .Input[type=file] {
      width: 100%;
      font-size: 13px;
      border: 1px solid #193c5a4d;
      border-radius: 5px;
      margin: 10px 0;
      padding: 10px 5px;
    }

    .Licenses {
      @include FlexWrap;
      padding: 0 20px;

      .License {
        display: flex;
        min-width: 250px;
        color: $bluish-black;
        margin: 10px 0;

        input[type=radio] {
          margin-right: 5px;
        }
      }
    }
  }

  .Actions {
    .Action {
      @include FlexProps(flex-start, center);
      flex-direction: column;
      padding: 15px 0;
      border-top: 1px dashed $light-gray;

      &:last-of-type {
        border-bottom: 1px dashed $light-gray;
      }

      .Text {
        @include TextProps(16px, bold, $bluish-black);
      }

      .Disclaimer {
        @include TextProps(14px, bold, $dark-gray);
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .License-Management-Container {
    .Actions {
      .Action {
        flex-direction: column-reverse;
        padding: 0;

        .Text {
          margin: 10px 0;
        }
      }
    }
  }
}
