.LandingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #7fc3d5;
}

.LandingPage-ImageBox-container {
    margin-top: 100px;
    width: 1050px;
}

@media only screen and (max-width: 1050px) {
  .LandingPage-ImageBox-container{
    width: 100%;
  }
  
  .LandingPage-ImageBox-container img {
    object-fit: cover;
    object-position: center;
  }
}

