.RadioBox-EditUser {
  display: flex;
  margin: 10px 20px;
  color: #183D5B;
}

.RadioBox-EditUser input{
  margin-right: 5px;
  cursor: pointer;
}

