.CreationForms-ReactQuill {
  width: 720px;
  min-height: 300px;
}

.ql-editor {
  background-color: white;
  height: 300px;
}

.ql-snow .ql-editor h1 {
  font-size: 46px;
  margin: 0px;
  font-weight: normal;
  color: rgb(24, 61, 91);
}

.ql-snow .ql-editor h2 {
  font-size: 42px;
  margin: 0px;
  font-weight: normal;
  color: rgb(24, 61, 91);
}

.ql-snow .ql-editor h3 {
  font-size: 34px;
  margin: 0px;
  font-weight: normal;
  color: rgb(24, 61, 91);
}

.ql-snow .ql-editor p {
  font-size: 18px;
  margin: 0px;
  font-weight: normal;
  color: rgb(24, 61, 91);
}