@import 'src/Style/Style.scss';

.Institution-Resource-Exhibition-Page {
  @include FlexColumn;
  margin: 50px;

  .Tabs {
    @include FlexCenter;
    height: 50px;
    background-color: $light-gray;
    border-radius: 5px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;

    .Tab {
      @include FlexCenter;
      height: 46px;
      width: 30%;
      border-radius: 5px;
      margin: 0 3px;
      cursor: pointer;
      transition: .5s;

      .Name {
        @include TextProps(14px, bold, $white);
        letter-spacing: 0.4px;
        text-transform: uppercase;
      }

      .Icon {
        color: $white;
        margin-right: 6px;
      }

      &.Active {
        background-color: $light-green;
        box-shadow: 0 3px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
        transition: 0.5s;
      }
    }
  }

  .Page-Head {
    @include FlexSpaceBetween;
    margin-bottom: 21px;

    .Title {
      @include TextProps(24px, bold, $bluish-black);
    }

    .Filters-Container {
      @include FlexWrap;
      align-items: center;

      .Dropdown {
        @include FlexCenter;
        position: relative;
        height: 45px;
        width: 216px;
        background-color: $white;
        border: 1px solid $light-green;
        border-radius: 4px;
        margin: 2px 10px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);
        cursor: pointer;

        .Image {
          position: absolute;
          left: 14px;
        }

        .Select {
          @include FullHW;
          @include TextProps(14px, normal, $bluish_black);
          @include BacBorNone;
          text-transform: capitalize;
          appearance: none;
          padding: 0 8px 0 41px;
          cursor: pointer;
        }

        .Icon {
          position: absolute;
          right: 8px;
          color: $bluish-black;
        }
      }

      .Search {
        @include FlexCenter;
        position: relative;
        height: 45px;
        width: 306px;
        background-color: $white;
        border: 1px solid $light-green;
        border-radius: 4px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);

        .Icon {
          position: absolute;
          left: 14px;
          top: 14px;
        }

        .Input {
          @include FullHW;
          @include BacBorNone;
          @include TextProps(14px, normal, $bluish-black);
          padding: 0 8px 0 41px;
        }
      }
    }
  }

  .Institution-Resources {
    @include FlexWrap;
    justify-content: space-between;

    .Institution-Resource {
      @include FlexColumn;
      width: 49%;
      min-height: 386px;
      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      margin-bottom: 34px;

      &.Archived {
        background-color: #ffffff33;
        opacity: 0.7;

        .Foot {
          .Reactions {
            .Reaction {
              .Icon {
                cursor: default;
              }
            }
          }
        }
      }

      &.Global {
        border: 1px solid #6bd1be;
      }

      .Head {
        @include FlexProps(center, flex-start);
        height: 45px;
        padding: 0 22px;
        border-bottom: 1px solid #1a3c591a;

        .Title {
          @include TextProps(14px, bold, $bluish-black);
          letter-spacing: 0.2px;
          text-transform: uppercase;
        }
      }

      .Body {
        display: flex;
        min-height: 290px;
        padding: 24px;

        .Content {
          @include FlexColumn;
          width: 63%;

          .Attribute {
            display: flex;
            margin-bottom: 24px;

            .Name {
              @include TextProps(12px, bold, $white);
              @include FadeColor($bluish-black, 50%);
              height: 15px;
              width: 25%;
              letter-spacing: 0.2px;
              line-height: 18px;
            }

            .Value {
              @include TextProps(14px, normal, $bluish-black);
              width: 75%;
              letter-spacing: 0.2px;
              line-height: 18px;

              &.Date {
                @include FadeColor($bluish-black, 50%);
                font-style: italic;
                font-size: 12px
              }
            }
          }
        }

        .Actions {
          @include FlexColumn;
          width: 37%;

          .Download-Button {
            height: 28px;
            width: 221px;
            background-color: $light-green;
            border-radius: 4px;
            margin-bottom: 16px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            text-align: center;

            p {
              @include TextProps(14px, bold, $white);
            }
          }

          .Preview-Button {
            @include BacBorNone;
            display: flex;
            letter-spacing: 0.4px;
            text-transform: uppercase;

            .Icon {
              margin-right: 6px;
            }

            p {
              @include TextProps(14px, bold, $light-green);
            }
          }

          .Archived {
            @include FlexCenter;
            @include TextProps(14px, bold, $white);
            height: 28px;
            width: 221px;
            background-color: #1a3c5980;
            border: none;
            border-radius: 4px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
          }
        }
      }

      .Foot {
        @include FlexSpaceBetween;
        position: relative;
        padding: 16px 21px;
        border-top: 1px solid #1a3c591a;

        .Reacting {
          @include FlexCenter;
          @include Absolute0;
          @include FadeBackgroundColor($bluish-black, 60%);
          z-index: 99999;
        }

        .Reactions {
          display: flex;

          .Reaction {
            @include FlexCenter;
            margin-right: 24px;

            .Icon {
              @include BacBorNone;
              color: #bdbdbd;
              font-size: 20px;

              &.Liked {
                color: $light-green;
              }

              &.Disliked {
                color: $red;
              }

              &.Dislike {
                i {
                  transform: rotateX(190deg) rotate(183deg);
                }
              }

              &.Favorite {
                color: $light-green;
              }
            }
          }

          .Count {
            @include TextProps(12px, normal, #bdbdbd);
            margin-left: 6px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .Institution-Resource-Exhibition-Page {
    .Institution-Resources {
      .Institution-Resource {
        .Body {
          flex-direction: column;
          padding-bottom: 24px;

          .Content, .Actions {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .Institution-Resource-Exhibition-Page {
    margin: 10px;

    .Tabs {
      flex-direction: column;
      height: auto;
      width: 98%;

      .Tab.Active {
        width: 98%;
      }
    }

    .Page-Head {
      flex-wrap: wrap;

      .Title {
        margin-bottom: 10px;
      }

      .Filters-Container {
        justify-content: center;
      }
    }

    .Institution-Resources {
      .Institution-Resource {
        width: 98%;

        .Body {
          flex-direction: row;
          margin-bottom: 0;

          .Content {
            width: 63%;
          }

          .Actions {
            width: 37%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .Institution-Resource-Exhibition-Page {
    .Institution-Resources {
      .Institution-Resource {
        .Body {
          flex-direction: column;
          padding-bottom: 24px;

          .Content, .Actions {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Institution-Resource-Exhibition-Page {
    .Page-Head {
      .Filters-Container {
        .Dropdown, .Search {
          width: 100%;
          margin: 5px 0;
        }
      }
    }

    .Institution-Resources {
      .Institution-Resource {
        width: 100%;

        .Body {
          .Content {
            .Attribute {
              flex-direction: column;
              margin-bottom: 15px;

              .Name {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
}
