.SortingBoxMini-ButtonBox-container {
    display: flex;
    flex-direction: column;
}

.SortingBoxMini-ButtonBox-div {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
}