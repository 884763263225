@import 'src/Style/Style.scss';

.BatchProfileUpload-Container {
  width: 90%;
  margin: 10px auto;
  padding: 0;

  .Instructions {
    padding-left: 30px;

    li {
      @include TextProps(16px, normal, $bluish-black);
      line-height: 23px;

      .Download-Sample {
        color: $blue;
      }
    }
  }

  .File-Field {
    @include FlexColumn;
    margin: 30px 0;

    .Label {
      @include TextProps(15px, bold, $bluish-black);
    }

    .Input[type=file] {
      width: 100%;
      font-size: 13px;
      border: 1px solid #193c5a4d;
      border-radius: 5px;
      margin-top: 5px;
      padding: 10px 5px;
    }
  }

  .Submit-Button {
    display: flex;
  }
}
