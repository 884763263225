@import 'src/Style/Style.scss';

/* Root */
.LessonAnswerButtons-Desktop {
  @include FlexCenter;
  position: fixed;
  height: auto;
  width: 100px;
  top: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 99999;

  .Slope-Container {
    @include FlexCenter;
    position: relative;
    bottom: 2px;
    min-width: 600px;
    height: 0;
    border: 65px solid transparent;
    border-top: 0;
    border-bottom-color: $darker-green;

    &::before {
      content: "";
      position: absolute;
      top: -3px;
      right: -28px;
      width: 60px;
      height: 60px;
      background-color: $darker-green;
      border-top-right-radius: 52px;
      box-shadow: 0 -40px 0 0 $light-green;
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      top: -3px;
      left: -28px;
      width: 60px;
      height: 60px;
      background-color: $darker-green;
      border-top-left-radius: 52px;
      box-shadow: 0 -40px 0 0 $light-green;
      z-index: 1;
    }

    .Left-Curve {
      position: absolute;
      top: 23px;
      left: -84px;
      width: 40px;
      height: 40px;
      background-color: $darker-green;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        right: -12px;
        bottom: 1px;
        width: 60px;
        height: 60px;
        background-color: $light-green;
        border-bottom-right-radius: 52px;
        transform: rotate(5deg);
        z-index: 1;
      }
    }

    .Right-Curve {
      position: absolute;
      top: 23px;
      right: -84px;
      width: 40px;
      height: 40px;
      background-color: $darker-green;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        bottom: 1px;
        left: -12px;
        width: 60px;
        height: 60px;
        background-color: $light-green;
        border-bottom-left-radius: 52px;
        transform: rotate(-5deg);
        z-index: 1;
      }
    }

    .Buttons-Bar {
      @include FlexCenter;
      @include Absolute0;
      width: 100%;
      height: 50px;
      margin: 10px 0;
      transition: all 0.3s ease;
      overflow: hidden;
      z-index: 9;

      .LessonAnswerButtons-ButtonBox_container {
        margin: 0;

        .LessonAnswerButtons-ButtonBox {
          height: auto;
          min-width: inherit;
          max-width: inherit;
          background-color: transparent;
          border: none;

          p:first-of-type {
            .Box {
              @include FlexCenter;
              width: 50px;
              height: 50px;
              transition: 0.3s;

              i {
                font-size: 17px;
                color: $light-gray;
                margin-top: 27px;
                transition: 0.3s;
              }

              p {
                @include TextProps(14px !important, bold, $light-gray);
                color: $light-gray;
                transition: 0.3s;
                width: 130px;
                margin-top: 12px;
              }

              &:hover {
                width: 125px;

                i {
                  text-shadow: 0 0 1px $white, 0 0 2px $white, 0 0 2px $white;
                  color: $white;
                  margin-top: 0;
                  margin-bottom: 10px;
                  transition: 0.3s;
                }

                p {
                  color: $white;
                  margin: 0;
                  text-shadow: 0 0 0.5px $white, 0 0 0.5px $white, 0 0 0.5px $white;
                  transition: 0.3s;
                }
              }
            }
          }

          &.active,
          &.LessonAnswerButtons-SavingProgressIndicator,
          &.LessonAnswerButtons-SubmitButton {
            p:first-of-type {
              .Box {
                width: 125px;

                i {
                  text-shadow: 0 0 1px $white, 0 0 2px $white, 0 0 2px $white;
                  color: $white;
                  margin-top: 0;
                  margin-bottom: 10px;
                  transition: 0.3s;
                }

                p {
                  color: $white;
                  margin: 0;
                  text-shadow: 0 0 0.5px $white, 0 0 0.5px $white, 0 0 0.5px $white;
                  transition: 0.3s;
                }
              }
            }
          }
        }
      }
    }
  }
}

.Compliance-Platform {
  .LessonAnswerButtons-Desktop {
    .Slope-Container {
      border-bottom-color: $darker-blue;

      &::before {
        background-color: $darker-blue;
        box-shadow: 0 -40px 0 0 $compliance-blue;
      }

      &::after {
        background-color: $darker-blue;
        box-shadow: 0 -40px 0 0 $compliance-blue;
      }

      .Left-Curve {
        background-color: $darker-blue;

        &::before {
          background-color: $compliance-blue;
        }
      }

      .Right-Curve {
        background-color: $darker-blue;

        &::before {
          background-color: $compliance-blue;
        }
      }
    }
  }
}

.LessonAnswerButtons-Mobile-Bottom {
  @include FlexProps(center, space-between);
  position: fixed;
  height: 92px;
  width: auto;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f5f5f5;
  border: 1px solid #183d5b2e;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0 20px;
  box-shadow: 0 -1px 16px rgba(0, 0, 0, 0.12);
  z-index: 99999;
  overflow-x: scroll;
}

.LessonAnswerButtons-Mobile-Title {
  display: flex;
  position: absolute;
  top: 100px;
  box-shadow: 0 -1px 16px rgba(0, 0, 0, 0.12);
}
/* Root */

/* Group */
.LessonAnswerButtons-ButtonBox_container {
  display: flex;
  margin: 0 15px;
}

.LessonAnswerButtons-ButtonBox_container > div:first-child > button {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.LessonAnswerButtons-ButtonBox_container > div:last-child > button {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* Group */

/* Button */
.LessonAnswerButtons-ButtonBox {
  height: 60px;
  min-width: 65px;
  max-width: 72px;
  background-color: $white;
  border: 1px solid #dbe0e3;
  color: $bluish-black;

  .Box {
    @include FlexCenter;
    flex-direction: column;

    i {
      font-size: 22px;
      margin-bottom: 5px;
    }

    p {
      font-size: 12px !important;
      font-family: 'Lato', sans-serif !important;
      font-weight: normal !important;
      color: #193c5a;
      line-height: 0.8;
    }
  }

  &:hover,
  &.active {
    background-color: $light-green;
    border-color: $white;

    .Box {
      i,
      p {
        color: $white;
      }
    }
  }

  &.LessonAnswerButtons-SavingProgressIndicator {
    width: 130px;
    max-width: 130px;
  }

  &.LessonAnswerButtons-SubmitButton {
    .SubmitButton {
      @include FlexProps(center, space-evenly);
    }
  }
}

.teal {
  color: rgb(108, 224, 219);
}

.active {
  color: $white;
  background-color: $light-green;
  border-color: $light-green;
}
/* Button */

@media only screen and (max-width: 1430px) {
  .LessonAnswerButtons-Desktop {
    .Slope-Container {
      min-width: 520px;
      right: 70px;
    }
  }
}

@media only screen and (max-width: 1220px) {
  .LessonAnswerButtons-Desktop {
    .Slope-Container {
      min-width: 420px;

      .Buttons-Bar {
        .LessonAnswerButtons-ButtonBox_container {
          .LessonAnswerButtons-ButtonBox {
            p:first-of-type {
              .Box {
                width: 45px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .LessonAnswerButtons-ButtonBox_container {
    margin: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .LessonAnswerButtons-Desktop {
    .Slope-Container {
      min-width: 300px;

      .Buttons-Bar {
        .LessonAnswerButtons-ButtonBox_container {
          .LessonAnswerButtons-ButtonBox {
            p:first-of-type {
              .Box {
                width: 25px;
              }
            }
          }
        }
      }
    }
  }
}
