@import 'src/Style/Style.scss';

.Institution-Resource-Management-Page {
  margin: 50px;

  .Institution-Selection-Container {
    @include FlexColumn;
    background-color: $white;
    border-radius: 5px;
    padding: 30px;

    .Label {
      @include TextProps(18px, normal, $bluish-black);
      margin-bottom: 10px;
    }

    .Select {
      width: 100%;
    }
  }

  .Institution-Resource-Management-Container {
    @include FlexColumn;

    .Page-Head {
      @include FlexProps(flex-start, space-between);
      margin-bottom: 24px;

      .Title {
        @include TextProps(24px, bold, $bluish-black);
      }

      .Add-Resource-Button {
        @include FlexCenter;
        flex-direction: row-reverse;
        height: 53px !important;
        width: 280px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);

        span {
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        i {
          color: $white;
          margin-right: 8px;
        }
      }
    }

    .Filters-Container {
      @include FlexProps(center, flex-end);
      flex-wrap: wrap;
      margin-bottom: 24px;

      .Dropdown {
        @include FlexCenter;
        position: relative;
        height: 45px;
        width: 216px;
        background-color: $white;
        border: 1px solid $light-green;
        border-radius: 4px;
        margin: 2px 10px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);
        cursor: pointer;

        .Image {
          position: absolute;
          left: 14px;
        }

        .Select {
          @include FullHW;
          @include TextProps(14px, normal, $bluish-black);
          @include BacBorNone;
          text-transform: capitalize;
          appearance: none;
          padding: 0 8px 0 41px;
          cursor: pointer;
        }

        .Icon {
          position: absolute;
          right: 8px;
          color: $bluish-black;
        }
      }

      .Search {
        @include FlexCenter;
        position: relative;
        height: 45px;
        width: 306px;
        background-color: $white;
        border: 1px solid $light-green;
        border-radius: 4px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);

        .Icon {
          position: absolute;
          left: 14px;
          top: 14px;
        }

        .Input {
          @include FullHW;
          @include BacBorNone;
          @include TextProps(14px, normal, $bluish-black);
          padding: 0 8px 0 41px;
        }
      }
    }

    .Selected-Institution-Name {
      @include TextProps(24px, bold, $bluish-black);
      margin-bottom: 10px;
    }

    .Platform-Filters-And-Institution-Resources {
      @include FlexColumn;
      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      padding: 0 30px 30px;

      .Platform-Filters {
        @include FlexWrap;

        .Platform-Filter {
          @include FlexCenter;
          @include TextProps(14px, bold, #193c5a8c);
          height: 46px;
          min-width: 120px;
          padding: 0 16px;
          border-bottom: 3px solid #193c5a1a;
          margin: 0 8px;

          &.Active {
            color: $bluish-black;
            border-color: $bluish-black;
          }
        }
      }

      .No-Records {
        margin-top: 20px;
      }

      .Institution-Resources {
        @include FlexColumn;

        .Institution-Resource {
          display: flex;
          min-height: 365px;
          width: 100%;
          padding: 32px 0;
          border-bottom: 1px solid #183c5b1a;

          &:last-of-type {
            border-bottom: none;
          }

          .Content {
            @include FlexColumn;
            flex: 70%;

            .Title {
              @include TextProps(14px, bold, $bluish-black);
              letter-spacing: 0.2px;
              text-transform: uppercase;
              margin-bottom: 23px;

              .Archived {
                @include TextProps(11px, bold, $white);
                background-color: #183c5b80;
                padding: 4px 25px;
                border-radius: 5px;
                margin-left: 16px;
                letter-spacing: 0.2px;
              }
            }

            .Attribute {
              display: flex;
              margin-bottom: 16px;

              .Name {
                @include FlexProps(flex-start, flex-end);
                @include TextProps(12px, bold, #193c5a80);
                min-width: 100px;
                margin-right: 24px;
                line-height: 24px;
                letter-spacing: 0.2px;
              }

              .Value {
                @include FlexProps(flex-start, flex-start);
                @include TextProps(16px, normal, $bluish-black);
                flex-direction: column;
                line-height: 24px;
                letter-spacing: 0.2px;

                .Link {
                  color: $blue;
                }
              }

              .Reactions {
                display: flex;

                .Like, .Dislike {
                  @include FlexCenter;

                  .Icon {
                    font-size: 23px;
                    color: #bdbdbd;
                  }

                  .Count {
                    @include TextProps(12px, bold, #bdbdbd);
                    margin-left: 6px;
                  }
                }

                .Like {
                  margin-right: 24px;
                }
              }
            }
          }

          .Actions {
            @include FlexCenter;
            flex-direction: column;
            flex: 30%;

            .Edit-Button {
              @include FlexCenter;
              @include BacBorNone;
              flex-direction: row-reverse;
              height: 34px;
              width: 216px;
              background-color: $light-green;
              border-radius: 4px;
              margin-bottom: 16px;

              i {
                font-size: 17px;
                color: $white;
                margin-right: 8px;
              }

              p {
                @include TextProps(14px, bold, $white);
                letter-spacing: 0.4px;
                text-transform: uppercase;
              }
            }

            .Dates {
              @include FlexColumn;

              .Updated, .Uploaded {
                @include TextProps(12px, normal, #183c5bcc);
                font-style: italic;
                letter-spacing: 0.2px;
                margin-bottom: 9px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .Institution-Resource-Management-Page {
    .Institution-Resource-Management-Container {
      .Platform-Filters-And-Institution-Resources {
        .Institution-Resources {
          .Institution-Resource {
            flex-direction: column;

            .Content {
              flex: 100%;
            }

            .Actions {
              flex: 100%;
              align-items: flex-start;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Institution-Resource-Management-Page {
    margin: 20px;

    .Institution-Resource-Management-Container {
      .Filters-Container {
        justify-content: center;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .Institution-Resource-Management-Page {
    .Institution-Resource-Management-Container {
      .Page-Head {
        align-items: center;
        flex-direction: column;

        .Title {
          margin-bottom: 10px;
        }
      }

      .Filters-Container {
        .Dropdown, .Search {
          width: 100%;
          margin: 3px 0;
        }
      }

      .Platform-Filters-And-Institution-Resources {
        .Platform-Filters {
          .Platform-Filter {
            width: 100%;
          }
        }

        .Institution-Resources {
          .Institution-Resource {
            .Content {
              .Attribute {
                flex-direction: column;

                .Name {
                  justify-content: flex-start;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
