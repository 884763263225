.select-css {
    width: 250px;
	display: block;
	font-size: 16px;
	font-weight: 300;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	box-sizing: border-box;
    margin: 20px 0 20px 0;
	border: 1px solid #cccccc;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: .2em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #ffffff;
}
.select-css::-ms-expand {
	display: none;
}
.select-css:hover {
	border-color: #888;
}
.select-css:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
.select-css option {
	font-weight:normal;
}
