@import 'src/Style/Style.scss';

.CircleProgressBar {
  @include FlexCenter;
  flex-direction: column;
  background-color: $white;

  .Progress {
    @include FullHW;
    position: relative;

    svg {
      display: flex;
      height: 150px;
      width: 150px;
      position: relative;
      transform: rotate(-90deg);

      circle {
        @include FullHW;
        fill: #f3f5f6;
        stroke: $lightest-gray;
        stroke-width: 10;
        stroke-linecap: round;

        &:last-of-type {
          stroke-dasharray: 440px;
          stroke-dashoffset: calc(440px - (440px * var(--percent)) / 100);
          stroke: #92c4ff;
        }
      }
    }

    .Text {
      @include FlexColumn;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .Heading {
        @include TextProps(16px, normal, #565656);
        margin-bottom: 4px;
        line-height: 24px;
        text-transform: capitalize;
      }

      .Sub-Heading {
        @include TextProps(16px, bold, #4f6476);
        line-height: 25px;
        text-transform: uppercase;
      }
    }
  }
}
