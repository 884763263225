@import 'src/Style/Style.scss';

.CoursesDashboard {
  font-family: Lato;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px;
  padding: 0 40px;
  background-color: #e7ebed;

  .CoursesDashboard-Course-Box {
    @include FlexSpaceBetween;
    @include FlexColumn;
    align-content: center;
    margin-top: 40px;
    padding: 10px;
    max-width: 320px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 12px rgba(27,31,35,.15);
    position: relative;
    transition: 0.3s;

    &:hover {
      @include GrowScaleWithShadow;
    }
  }
}

.CoursesDashboard-Course-CircularProgressBar {
  width: 80%;
  margin-top: 15px;
  margin-bottom: 20px;
}

.CoursesDashboard-Course-Button {
  @include FlexCenter;
  width: 80%;
  margin-bottom: 20px;
}

.CoursesDashboard-Button__start {
  cursor: pointer;
  height: 40px;
  border: 2px solid #7fc3d5;
  border-radius: 4px;
  color: white;
  background-color: #7fc3d5;
}

.Expired-Overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255,0.5);
  z-index: 5;
}

.Expired-Overlay i {
  color: #183D5B;
  font-size: 72px;
  margin-top: 120px;
}

@media only screen and (max-width: 1100px) {
  .CoursesDashboard {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .CoursesDashboard {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .CoursesDashboard-Course-Box{
    margin-bottom: 40px;
    margin-top: 50px;
  }
}
