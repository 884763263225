@import 'src/Style/Style.scss';

.UserRosterUpload-Container {
  width: 90%;
  margin: 10px auto;
  padding: 0;

  .Instructions {
    padding-left: 30px;

    li {
      @include TextProps(16px, normal, $bluish-black);
      line-height: 23px;

      .Download-Sample {
        color: $blue;
      }
    }
  }

  .Error-Container {
    .Errors {
      li {
        @include TextProps(16px, normal, $red);
      }
    }
  }

  .License-Field, .Group-Field, .File-Field {
    @include FlexColumn;
    margin: 10px 0;

    .Label {
      @include TextProps(15px, bold, $bluish-black);

      .Link {
        color: $blue;
      }
    }

    .Input[type=file] {
      width: 100%;
      font-size: 13px;
      border: 1px solid #193c5a4d;
      border-radius: 5px;
      margin-top: 5px;
      padding: 10px 5px;
    }

    .Licenses, .Groups {
      @include FlexWrap;
      padding: 0 20px;

      .License, .Group  {
        display: flex;
        min-width: 250px;
        color: $bluish-black;
        margin: 5px 0;

        input[type=radio] {
          margin-right: 5px;
        }
      }
    }
  }

  .Submit-Button {
    display: flex;
  }
}
