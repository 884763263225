.Certificates {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Certificates h1 {
	margin-bottom: 20px;
	margin-top: 20px; 
}

.Certificates .Sub-Heading {
	width: 800px;
	color: #526d84;
	text-align: center;
}

.Certificates .Card-Container {
	border: 1px solid black;
	background-color: white;
	min-height: 100px;
	width: 800px;
	border-radius: 5px;
	border-color: #c7d0d8;
	box-shadow: #0000001a 0 10px 15px -3px, #0000000d 0 4px 6px -2px;
}

.Certificates .Card-Container h1 {
	color: #1b3f5d;
	padding: 20px 45px;
}

.Certificates .Card-Container .Card-Desc {
	padding: 20px 45px;
}

.Certificates .Card-Holder {
	margin: 30px;
}

.Certificates .Card-Holder .Card-Inner {
	display: flex; 
	justify-content: space-between;
	align-items: center;
}

.Certificates .Card-Holder .Card-Inner .Actions {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-direction: column;
}

.Certificates .Card-Holder .Card-Inner .Actions .Certificate-Button,
.Certificates .Card-Holder .Card-Inner .Actions .CMEO-Button {
	min-width: 240px;
	box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
	margin: 3px;
}

.Certificates .Card-Holder .Card-Inner .Actions .Certificate-Button p,
.Certificates .Card-Holder .Card-Inner .Actions .CMEO-Button p {
	color: white;
	padding: 0 10px;
	font-weight: bold;
}

.Certificates .Card-Holder .Card-Inner p {
	color: #526d84;
}

.Certificates .Card-Holder .Card-Inner .Status {
	color: #526d84;
	font-weight: 300;
	font-family: inherit;
	font-size: 20px;
	border: 0;
	padding: 0;
	background-color: transparent;
}

@media only screen and (max-width: 1200px) {
	.Certificates .Sub-Heading {
		width: 80%;
	}

	.Certificates .Card-Holder {
		width: 80%;
	}

	.Certificates .Card-Container {
		width: 100%;
	}
}

@media only screen and (max-width: 1024px) {
	.Certificates .Card-Holder .Card-Container h1 {
		text-align: center;
		padding: 20px;
	}

	.Certificates .Card-Holder .Card-Container .Card-Desc {
		padding: 20px;
	}

	.Certificates .Card-Holder .Card-Desc .Card-Inner {
		flex-direction: column;
	}

	.Certificates .Card-Holder .Card-Desc .Card-Inner > * {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		width: 100%;
		margin: 10px 0;
	}
}

@media only screen and (max-width: 450px) {
	.Certificates .Card-Holder .Card-Desc .Card-Inner .Actions .Certificate-Button,
	.Certificates .Card-Holder .Card-Desc .Card-Inner .Actions .CMEO-Button {
		width: 200px;
		min-width: 200px;
	}
}
