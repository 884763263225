.TopicBox {
    display: flex;
    flex-direction: column;
    border: 2px solid #e0e2e2;
    border-radius: 5px;
    width: calc(100% - 150px);
    margin: 5px 0;
}

.TopicBox-Visible {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    width: calc(100% - 30px);
    cursor: pointer;
}

.TopicBox:last-of-type {
    margin-bottom: 15px;
}

.TopicBox-DataBox-ProgressBar {
    width: 100%;
}

.TopicBox-DataBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 40%;
}

@media only screen and (max-width: 768px) {
    .TopicBox {
        width: calc(100% - 30px);
    }
}

@media only screen and (max-width: 480px) {
    .TopicBox-Visible {
        flex-direction: column;
        align-items: flex-start;
    }

    .TopicBox-DataBox {
        width: 100%;
    }

    .TopicBox-DataBox-title {
        margin: 10px 0;
    }
}