@import 'src/Style/Style';

@mixin Field-Join-Left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@mixin Field-Join-Right {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@mixin Field-Join-Top {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@mixin Field-Join-Bottom {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.Onboarding {
  .Purchase {
    @include FlexColumn;
    height: auto;
    width: auto;
    background-color: $white;
    border-radius: 4px;
    margin: 100px 30px 30px;
    padding: 50px 200px;

    .Return {
      display: flex;
      margin-bottom: 20px;

      a {
        display: flex;

        i, img, span {
          @include FlexCenter;
          height: 26px;
          min-width: 26px;
        }

        i {
          margin-right: 4px;
        }

        img {
          margin-right: 8px;
        }

        span {
          font-family: 'LATO';
          font-size: 20px;
        }
      }
    }

    .Content {
      display: flex;
      justify-content: space-between;

      .Details {
        @include FlexColumn;
        flex: 0 1 37%;
        margin: 0 30px;

        .Congratulations {
          @include FlexColumn;
          align-items: center;
          min-height: 175px;
          max-width: 402px;
          color: $white;
          background-image: linear-gradient(42deg, #2776b8 0%, #9bd4eb 100%);
          border-radius: 5px;
          margin-bottom: 20px;
          box-shadow: 0 3px 12px rgba(27, 31, 35, 0.35);

          .Title {
            font-size: 14px;
            font-weight: bold;
            margin: 24px 25px 0;
            letter-spacing: 1px;
            text-transform: uppercase;
          }

          .PackageName {
            font-size: 28px;
            margin: 5px 25px 20px;
            letter-spacing: 2px;
            text-transform: uppercase;
          }

          .Price {
            font-size: 42px;
            margin-bottom: 25px;
          }

          .Price.Strike-Through {
            text-decoration: line-through;
            text-decoration-color: red;
            margin-bottom: 10px;
          }

          .Discounted-Price {
            font-size: 32px;
            margin-bottom: 25px;
          }

          .PremiumPackage {
            @include FlexColumn;
            margin: 0 25px;

            p {
              font-size: 20px;
              font-weight: bold;
              line-height: 28px;
              margin-bottom: 7px;
            }

            ul {
              li {
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
        }

        .Summary {
          @include FlexColumn;
          min-height: 180px;
          max-width: 402px;
          border: 2px solid $light-green;
          border-radius: 5px;
          margin-bottom: 20px;

          .Head {
            @include TextProp;
            display: flex;
            align-items: center;
            font-size: 16px;
            height: 42px;
            width: auto;
            background-color: $light-green;
            padding: 0 20px;
            text-transform: uppercase;
          }

          .Body {
            @include FlexColumn;

            .Info {
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              padding: 20px;

              .Left, .Right {
                @include TextProp;
                font-size: 18px;
              }
            }

            .Divider {
              border-bottom: 2px solid $light-green;
            }
          }
        }

        .Terms-Privacy {
          @include FlexCenter;
          flex-wrap: wrap;
          max-width: 402px;
          font-family: 'LATO';
          font-size: 14px;
          color: #193c5abf;
          margin-bottom: 20px;
        }

        .Coupon {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-height: 180px;
          max-width: 402px;
          border: 2px solid #6bd1be;
          border-radius: 5px;
          margin-bottom: 20px;

          .Field {
            padding: 20px;

            .Input {
              height: 41px;
              width: 100%;
              font-family: 'LATO';
              font-size: 16px;
              color: #193c5a;
              background-color: #f8fcfd;
              border: 1px solid #6bd1be;
              border-radius: 4px;
              padding: 0 16px;
            }
          }

          .Error {
            color: red;
          }

          .Applied {
            display: flex;
            width: 100%;

            .Info {
              display: flex;
              flex-direction: column;
              width: 100%;
              border: 2px dotted #a1d4e1;
              margin: 20px;

              p {
                font-family: 'LATO';
                font-size: 18px;
                color: #193c5a;
                margin: 10px;
              }
            }
          }

          .Submit {
            padding: 20px;

            .Btn {
              height: 47px;
              width: 200px;
              color: #fff;
              background-color: #183d5b;
              border: none;
              border-radius: 4px;

              &:disabled {
                background-color: #bfbfbf;
                cursor: not-allowed;
              }
            }
          }
        }
      }

      .Form {
        @include FlexColumn;
        flex: 0 1 52%;
        border-radius: 5px;
        padding: 30px 38px;
        box-shadow: 0 3px 12px rgba(27, 31, 35, 0.30);

        .Group {
          @include FlexColumn;
          margin-bottom: 30px;

          .Title {
            @include TextProp;
            font-size: 16px;
            margin-bottom: 20px;
          }

          .Form-Error {
            font-family: 'LATO';
            font-size: 16px;
            color: $red;
            margin-bottom: 10px;
          }

          .Field {
            @include FlexColumn;
            margin-bottom: 20px;

            label {
              font-family: 'LATO';
              font-size: 14px;
              color: #193c5acc;
              margin-bottom: 6px;
            }

            input, select {
              @include TextProp;
              height: 41px;
              width: 100%;
              font-size: 16px;
              background-color: #f8fcfd;
              border: 1px solid $light-green;
              border-radius: 4px;
              padding: 0 16px;
            }

            .Error {
              color: $red;
              border-color: $red;

              &:focus {
                color: $bluish-black;
              }
            }

            .StripeInput {
              @include TextProp;
              font-size: 16px;
              background-color: #f8fcfd;
              border: 1px solid $light-green;
              border-radius: 4px;
              padding: 11px 16px;
            }

            .Duo-Horizontal {
              display: flex;

              .Left {
                @include Field-Join-Left;
                display: flex;
                flex: 50%;
              }

              .Right {
                @include Field-Join-Right;
                display: flex;
                flex: 50%;
              }
            }

            .Duo-Vertical {
              @include FlexColumn;

              .Top {
                @include Field-Join-Top;
              }

              .Bottom {
                @include Field-Join-Bottom;
              }
            }

            .Trio {
              @include FlexColumn;

              .Top {
                @include FlexColumn;
                @include Field-Join-Top;
              }

              .Bottom {
                display: flex;

                .Left {
                  @include FlexColumn;
                  @include Field-Join-Bottom;
                  @include Field-Join-Left;
                  flex: 0 1 50%;
                }

                .Right {
                  @include FlexColumn;
                  @include Field-Join-Bottom;
                  @include Field-Join-Right;
                  flex: 0 1 50%;
                }
              }
            }
          }
        }

        .Submit {
          @include FlexCenter;
          margin-top: 10px;

          .Btn {
            height: 47px;
            width: 432px;
            color: $white;
            background-color: #183d5b;
            border: none;
            border-radius: 4px;

            &:disabled {
              @include DisableBtn;
            }

            p {
              font-family: 'LATO';
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1350px) {
  .Onboarding {
    .Purchase {
      padding: 50px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .Onboarding {
    .Purchase {
      .Content {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .Onboarding {
    .Purchase {
      padding: 50px 0;
      margin: 100px 0;

      .Content {
        .Form {
          .Group {
            .Field {
              .Duo-Horizontal {
                flex-direction: column;

                .Left, .Right {
                  flex: auto;
                  border-radius: 4px;
                  border: 1px solid #6bd1be;
                }

                .Left {
                  @include Field-Join-Top
                }

                .Right {
                  @include Field-Join-Bottom
                }
              }
            }
          }

          .Submit {
            .Btn {
              width: 225px;
            }
          }
        }
      }
    }
  }
}
