.dashboardProgressMobile {
    background-color: white;
    margin: auto;
    width: 100vw;
    height: 100vh;
    border-collapse: collapse;
    
    &__cell-green {
        text-align: center;
        background-color: #6bd1be;
        color: #ffffff;
    }

    &__text {
        position: relative;
        top: 20px;
        font-size: 12px;
        font-weight: thin;
    }

    &__cell-white {
        text-align: center;
        background-color: white;
        color: #000000;
    }

    &__cell-grey {
        text-align: center;
        background-color: #f0f0f0;
        color: #000000;
    }
}

@media screen and (min-width: 769px) {
    .dashboardProgressMobile {
        width: 50vw;
        margin: 0px;
    }
}
