@import 'src/Style/Style.scss';

.HomePage {
  @include FlexColumn;

  .Banner-Container {
    @include FlexProps(center, space-between);
    position: relative;
    height: 120px;
    width: auto;
    border-radius: 8px;
    margin: 10px 60px 10px 20px;
    box-shadow: 0px 3px 4px rgba(175, 175, 175, 0.1);
    background: linear-gradient(0deg, rgba(24, 60, 91, 0.6), rgba(24, 60, 91, 0.6)), $white;

    .Texts {
      @include FlexColumn;
      position: absolute;
      margin-left: 30px;
      z-index: 2;

      .Heading {
        @include TextProps(38px, bold, $white);
        margin-bottom: 3px;
        line-height: 52px;
      }

      .Sub-Heading {
        @include TextProps(14px, normal, #e7ebed);
        line-height: 22px;
      }
    }

    .BannerImage {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      z-index: 1;
    }
  }

  .Profile-Completeness-Container {
    @include FlexColumn;
    min-height: 110px;
    width: auto;
    background-color: $white;
    border-radius: 8px;
    margin: 0 60px 20px 20px;
    padding: 30px;
    box-shadow: 0 3px 4px rgba(175, 175, 175, 0.1);

    .Text-Holder {
      @include FlexSpaceBetween;
      margin-bottom: 18px;

      .Text {
        @include TextProps(22px, bold, #4f6476);
        line-height: 30px;
      }

      .Progress {
        @include TextProps(16px, bold, #565656);
        line-height: 25px;
        text-transform: uppercase;
      }
    }

    .Progress-Bar {
      position: relative;
      display: flex;
      height: 10px;
      width: 100%;
      background: #f3f5f6;
      border-radius: 16px;
      margin-bottom: 27px;
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);

      .Filler {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        background: linear-gradient(90deg, #00d9c7 0%, #92c4ff 100%);
        border-radius: 16px;
        box-shadow: inset 0px -7px 10px -1px rgba(78, 139, 163, 0.3);
      }
    }

    .Ticks-Container {
      @include FlexSpaceBetween;

      .Tick-Container {
        @include FlexCenter;

        .Fill {
          @include FlexCenter;
          height: 18px;
          width: 18px;
          background-color: #01ab9d;
          border-radius: 2px;

          i {
            font-size: 10px;
            color: $white;
          }
        }

        .Empty {
          @include FlexCenter;
          height: 17px;
          width: 17px;
          background-color: $white;
          border: 1px solid #01ab9d;
          border-radius: 2px;
        }

        .Text {
          @include TextProps(14px, normal, #565656);
          margin-left: 13px;
          line-height: 22px;
        }
      }
    }
  }

  .Links-Container {
    @include FlexSpaceBetween;
    margin: 0 60px 20px 20px;

    .Link {
      width: 25%;
      margin: 5px;

      .Badge {
        @include FlexProps(center, initial);
        height: 60px;
        width: 100%;
        background: $white;
        border-radius: 8px;
        padding: 0 25px;
        cursor: pointer;
        box-shadow: 0px 3px 4px rgba(175, 175, 175, 0.1);

        .Icon {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }

        .Text {
          @include TextProps(16px, bold, #4F6476);
          text-transform: uppercase;
          line-height: 25px;
        }
      }
    }
  }

  .Cards-Holder {
    @include FlexProps(flex-start, space-between);
    margin: 0px 60px 20px 20px;
    flex-wrap: wrap;

    .Card {
      @include FlexColumn;
      margin-bottom: 20px;
      width: 49%;

      .Card-Header {
        @include FlexProps(center, flex-start);
        height: 60px;
        width: auto;
        background: #01ab9d;
        border-radius: 8px 8px 0px 0px;
        padding: 0 30px;

        .Icon {
          height: 40px;
          width: 40px;
          margin-right: 10px;
        }

        .Text {
          @include TextProps(22px, bold, $white);
        }
      }

      .Card-Content {
        @include FlexProps(flex-start, space-between);
        flex-direction: column;
        min-height: 230px;
        width: auto;
        background-color: $white;
        border-radius: 0 0 8px 8px;
        padding: 15px 30px 20px;

        .Statement {
          @include TextProps(16px, normal, #565656);
          margin-bottom: 10px;
          line-height: 24px;
        }

        .Information {
          margin-bottom: 15px;

          li {
            @include TextProps(14px, normal, #404040);

            &::marker {
              font-size: 30px;
              color: #53d1dd;
            }
          }
        }

        .Alert, .Info {
          @include FlexCenter;

          .Icon {
            margin-right: 12px;
          }

          .Text {
            @include TextProps(14px, normal, #404040);
            line-height: 22px;
          }
        }

        .Link-And-Progress-Holder {
          @include FlexProps(flex-end, space-between);
          width: 100%;

          .Button {
            @include FlexCenter;
            height: 50px;
            width: 240px;
            border: 1px solid $bluish-black;
            border-radius: 8px;
            padding: 0 20px;

            span {
              @include TextProps(16px, bold, $bluish-black);
              text-transform: uppercase;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .HomePage {
    .Cards-Holder {
      .Card {
        .Card-Content {
          .Link-And-Progress-Holder {
            flex-direction: column;
            align-items: center;

            .Progress-Circle {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .HomePage {
    .Banner-Container {
      .Texts {
        .Heading {
          font-size: 25px;
        }
      }
    }

    .Links-Container {
      flex-direction: column;
    }

    .Cards-Holder {
      flex-direction: column;

      .Card {
        width: 100%;
        margin-bottom: 10px;

        .Card-Content {
          .Link-And-Progress-Holder {
            flex-direction: row;
            align-items: flex-end;

            .Progress-Circle {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .HomePage {
    .Banner-Container {
      .Texts {
        .Heading {
          line-height: 30px;
        }
      }
    }

    .Profile-Completeness-Container {
      .Ticks-Container {
        align-items: flex-start;
        flex-direction: column;

        .Tick-Container {
          margin: 16px 0;
        }
      }
    }

    .Links-Container {
      flex-wrap: initial;
      flex-direction: column;

      .Link {
        width: 100%;

        .Badge {
          width: 100%;
        }
      }
    }

    .Cards-Holder {
      .Card {
        .Card-Content {
          .Link-And-Progress-Holder {
            flex-direction: column;
            align-items: center;

            .Progress-Circle {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .HomePage {
    .Banner-Container {
      margin-right: 20px;
    }

    .Profile-Completeness-Container {
      margin-right: 20px;
    }

    .Links-Container {
      margin-right: 20px;
    }

    .Cards-Holder {
      margin-right: 20px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .HomePage {
    .Banner-Container {
      .Texts {
        .Heading {
          font-size: 20px;
          line-height: 20px;
        }
      }

      .BannerImage {
        height: 100%;
        width: 100%;
      }
    }

    .Profile-Completeness-Container {
      .Text-Holder {
        align-items: flex-start;
        flex-direction: column;

        .Progress {
          color: #01ab9d;
        }
      }
    }

    .Cards-Holder {
      .Card {
        .Card-Content {
          .Link-And-Progress-Holder {
            .Button {
              width: 80%;
            }
          }
        }
      }
    }
  }
}
