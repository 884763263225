@import 'src/Style/Style.scss';

.Tour {
  max-width: 510px !important;
  border-radius: 5px !important;

  .Step {
    @include FlexColumn;

    .Heading {
      @include TextProps(21px, bold, $bluish-black);
      margin-bottom: 15px;
    }

    .Content {
      .Text {
        @include TextProps(16px, normal, $bluish-black);
        margin-bottom: 10px;
      }
    }
  }

  [data-tour-elem="controls"] {
    justify-content: space-between;

    [data-tour-elem="left-arrow"],
    [data-tour-elem="right-arrow"] {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .Tour {
    max-width: 400px !important;
    padding: 15px !important;
  }
}

@media only screen and (max-width: 500px) {
  .Tour {
    max-width: 375px !important;

    [data-tour-elem="controls"] {
      flex-direction: column;

      [data-tour-elem="navigation"] {
        margin: 10px 0;
      }
    }
  }
}
