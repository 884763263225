@import 'src/Style/Style.scss';

.Select-Course-Entity-Container {
  @include FlexColumn;

  .Input-Container {
    @include FlexColumn;
    margin: 7px 0;

    .Label {
      @include TextProps(16px, bold, $bluish-black);
      margin: 3px 0;
    }

    .Select {
      width: 100%;
    }
  }

  .Sectionable-Input-Container {
    @include FlexProps(center, space-around);
    flex-direction: row;
    height: 30px;
    margin: 7px 0;
  }

  .Empty-Options {
    @include TextProps(18px, bold, $red);
  }
}
