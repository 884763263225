@import 'src/Style/Style.scss';

.UserResources-Container {
  @include FlexCenter;
  flex-direction: column;
  width: 100%;

  .ProgressTable {
    table {
      tbody {
        tr {
          td.Download-Resource {
            color: $dark-green;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
