@import 'src/Style/Style.scss';

.Institution-Resource-Form {
  .modal {
    .modal-content {
      border: none;
      border-radius: 5px;
      box-shadow: 0px 1px 20px rgba(255, 255, 255, 0.24);

      .modal-header {
        @include FlexSpaceBetween;
        margin-bottom: 32px;

        .Title {
          @include TextProps(16px, bold, $bluish-black);
          letter-spacing: 0.4px;
          text-transform: uppercase;
        }

        .Close-Button {
          @include BacBorNone;
        }
      }

      .modal-body {
        .Form-Errors {
          margin-bottom: 10px;

          li {
            @include TextProps(14px, normal, $red)
          }
        }

        .Field-Holder {
          display: flex;
          margin-bottom: 16px;

          .Label {
            @include FlexProps(flex-start, flex-end);
            @include TextProps(12px, bold, #193c5a80);
            min-width: 100px;
            margin-right: 24px;
            padding-top: 12px;
            letter-spacing: 0.2px;

            &.Top {
              padding-top: 0;
            }
          }

          .Input, .Text-Area {
            @include TextProps(16px, normal, $bluish-black);
            background-color: $white;
            padding: 8px;
            border: 1px solid #183c5b33;
            border-radius: 4px;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

            &:focus {
              border: 3px solid #57bdaa75;
              box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.1);
            }
          }

          .Input {
            height: 45px;
            width: 346px;
          }

          .Text-Area {
            height: 78px;
            width: 639px;
          }

          .Files {
            @include FlexColumn;

            .File {
              display: flex;
              height: 20px;
              margin-bottom: 7px;

              &:last-of-type {
                margin-bottom: 24px;
              }

              .Name {
                @include TextProps(16px, bold, $green);
                text-decoration: underline;
                letter-spacing: 0.2px;
              }

              .Remove {
                @include BacBorNone;
                margin-left: 16px;

                p {
                  @include TextProps(8px, normal, $dark-orange);
                  letter-spacing: 0.2px;
                }
              }
            }

            .Upload {
              @include FlexProps(center, flex-start);
              cursor: pointer;

              .Icon, .Text {
                @include TextProps(12px, bold, $green);
                letter-spacing: 0.2px;
                margin-right: 4px;
              }

              .Input {
                display: none;
              }
            }
          }

          .CheckBoxes {
            @include FlexColumn;
            line-height: 1;

            label {
              display: flex;

              .CheckBox {
                height: 14px;
                width: 14px;
                margin: 0 8px 3px 0;

                .CheckBox-Custom {
                  height: 15px;
                  width: 15px;
                  border-color: $light-green;

                  &::after {
                    top: 0;
                    left: 3px;
                    height: 8px;
                    width: 5px;
                    border-color: $white;
                  }
                }

                input:checked ~ .CheckBox-Custom {
                  background-color: $light-green;
                }
              }

              .Text {
                @include TextProps(14px, normal, $bluish-black);
              }
            }
          }
        }
      }

      .modal-footer {
        @include FlexSpaceBetween;

        .Left {
          @include FlexCenter;

          .Archive-Button {
            @include BacBorNone;
            @include FlexCenter;
            flex-direction: row-reverse;
            height: 15px;
            letter-spacing: 0.2px;

            p {
              @include TextProps(12px, bold, #9e9e9e);
            }

            i {
              color: #9e9e9e;
              font-size: 13px;
              margin-right: 6px;
            }
          }
        }

        .Right {
          @include FlexCenter;

          .Cancel-Button,
          .Save-Button {
            letter-spacing: 1px;
            text-transform: uppercase;
          }

          .Cancel-Button {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Institution-Resource-Form {
    .modal {
      .modal-content {
        width: 90%;

        .modal-body {
          .Field-Holder {
            .Input, .Text-Area {
              width: 100%;
            }
          }
        }

        .modal-footer {
          flex-direction: column;

          .Left {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .Institution-Resource-Form {
    .modal {
      .modal-content {
        width: 80%;

        .modal-body {
          .Field-Holder {
            flex-direction: column;

            .Label {
              justify-content: flex-start;
              margin-bottom: 7px;
            }
          }
        }

        .modal-footer {
          .Right {
            flex-direction: column;

            .Cancel-Button {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
