@import 'src/Style/Style.scss';

.Pass-Container {
  @include FlexColumn;

  .Primary-Info-Block {
    @include FlexProps(center, flex-start);
    background-color: #d6edf5;
    border: 1px solid #183d5b2e;
    border-radius: 4px;
    padding: 21px 33px;
    margin-bottom: 34px;

    .Percentage {
      @include FlexCenter;
      @include TextProps(36px, bold, $white);
      height: 78px;
      width: 117px;
      background-color: #7ec2d4;
      border-radius: 4px;
      margin-right: 23px;
    }

    .Description {
      @include FlexColumn;

      .Congratulations-Banner {
        @include FlexCenter;
        @include TextProps(18px, normal, $white);
        height: 40px;
        width: 202px;
        margin-bottom: 7px;
        background-image: url('../../../../Assets/Images/Congratulation-Banner.svg');
      }

      .Sub-Heading {
        @include TextProps(18px, normal, $bluish-black);
        text-align: center;
      }
    }
  }

  .Secondary-Info-Block {
    @include FlexCenter;
    width: 100%;

    .Pledge-Confirmation,
    .Learn-Content {
      @include FlexCenter;
      flex-direction: column;
      background-color: $white;
      border: 1px solid #183d5b2e;
      border-radius: 4px;
      padding: 30px;

      .Confirmation-Icon {
        height: 80px;
        width: 80px;
        background-image: url('../../../../Assets/Images/Circle-Tick.png');
        background-position: center;
        background-size: cover;
        margin-bottom: 31px;
      }

      .Heading {
        @include TextProps(24px, bold, $bluish-black);
      }

      .Paragraph {
        @include TextProps(18px, normal, $bluish-black);
        max-width: 50%;
        margin: 21px 0 80px;
        text-align: center;
        line-height: 27px;
        word-break: break-word;
      }

      .Link {
        width: fit-content;
      }

      .Profile-Link {
        @include TextProps(18px, normal, $bluish-black);
        text-decoration: underline;
      }
    }

    .Courses-Dashboard-Button,
    .CME-Claim-Button {
      @include FlexCenter;
      @include TextProps(20px, normal, $white);
      height: 58px;
      width: 353px;
      background-color: $bluish-black;
      border: 1px solid #183d5b2e;
      border-radius: 4px;
      margin-bottom: 20px;
      transition: .5s;

      .Text {
        @include TextProps(20px, normal, $white);
      }

      &:hover {
        background-color: $white;
        border: 1px solid $bluish-black;

        .Text {
          color: $bluish-black;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Pass-Container {
    .Primary-Info-Block {
      flex-direction: column;

      .Percentage {
        margin-right: 0;
        margin-bottom: 20px;
      }

      .Description {
        align-items: center;
      }
    }

    .Secondary-Info-Block {
      .Pledge-Confirmation {
        .Paragraph {
          max-width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .Pass-Container {
    .Secondary-Info-Block {
      .Link {
        width: 100%;
      }

      .Courses-Dashboard-Button,
      .CME-Claim-Button {
        width: 100%;
      }
    }
  }
}
