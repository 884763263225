.NavBar_profile {
  width: 100%;
  z-index: 10;
  min-height: 65px;
  display: flex;
  position: fixed;
  align-items: center;
  background-color: #6bd1be;
  justify-content: space-between;
  border-bottom: 5px solid #208c7a;
}

@media only screen and (max-width: 768px) {
  .NavBar_profile {
    flex-direction: column;
  }
}
