.modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  width: 720px;
  min-height: 300px;
  border: 1px solid #00030d;
  border-radius: 2px;
  overflow: auto;
}

.modal-content-small {
  background-color: white;
  padding: 20px;
  width: 450px;
  min-height: 300px;
  border: 1px solid #00030d;
  border-radius: 2px;
  overflow: auto;
}
