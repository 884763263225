.LicenseBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    padding: 40px 10px;
    margin: 5px 0;
    border: 1px solid #c5ced6;
    background-color: white;
    border-radius: 3px;
    height: 60px;
}

.LicenseBox i {
    font-size: 40px;
    margin: 0 20px;
    color: #183D5B;
}

.LicenseBox__divider {
    display: flex;
    align-items: center;
}

.LicenseBox__button {
    border: none;
    border-radius: 5px;
    font-size: 15px;
    color: white;
    margin: auto;
    margin: 20px 10px;
    height: 40px;
    width: 90px;
    background-color: rgb(24, 61, 91);
}
