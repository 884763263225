.modal-content-groups {
    background-color: white;
    width: calc(100% - 320px);
    min-height: 300px;
    border-radius: 2px;
    overflow: auto;
}

.AddUserToGroup {
    display: flex;
    flex-direction: column;
}

.AddUserToGroup-UserList {
    margin: 30px 20px;
    background-color: white;
    border: 1px solid #b6b6b6;
    padding: 20px;
}

.AddUserToGroup-Title {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);
    padding: 20px
}

.AddUserToGroup-SearchBox {
    margin: 0 20px;
    background-color: white;
    border: 1px solid #b6b6b6;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}