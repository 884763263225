.SentenceOrderingBox {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 15px;
}

.SentenceOrderingBox .Questions-List {
  display: flex;
  flex-direction: column;
  margin-left: 75px;
}

.SentenceOrderingBox .Questions-List .Question {
  min-height: 57px;
  width: calc(75% - 75px);
}

.SentenceOrderingBox .Numbers-List {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 15px;
  left: 15px;
  bottom: 15px;
}

.SentenceOrderingBox .Numbers-List .Number {
  display: flex;
  align-items: center;
  height: 57px;
}

.SentenceOrderingBox-container{
  padding: 5px;
}

.SentenceOrderingBox-QuestionBox {
  color: #193c5a;
  background-color: #ffffff;
  border: 1px solid #6bd1be;
  padding: 10px;
  border-radius: 4px;
  transition: 0.3s;
}

.SentenceOrderingBox-QuestionBox:hover {
  background-color: #6bd1be;
  transform: scale(1.03);
  box-shadow: #32325d40 0 13px 27px -5px, #0000004d 0 8px 16px -8px;
  transition: 0.3s;
}

.SentenceOrderingBox-QuestionBox .QuestionBox-Question-Bar {
  margin: 0;
}

.correct {
  border-color: #7de3bb;
}

.wrong {
  border-color: #D47e7e;
}

@media only screen and (max-width: 900px) {
  .SentenceOrderingBox .Questions-List .Question {
    width: 100%;
  }
}
