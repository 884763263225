@import 'src/Style/Style.scss';

.Notification-List-Container {
  position: relative;
  margin: 0 10px;

  .Icon-Container {
    position: relative;

    .Button {
      @include BacBorNone;
      color: $white;

      .Icon {
        font-size: 20px;
      }
    }

    .Not-Displayed-Count {
      @include FlexCenter;
      @include TextProps(13px, bold, $white);
      position: absolute;
      top: -10px;
      right: -9px;
      height: 20px;
      min-width: 20px;
      background: $red;
      border-radius: 50%;
    }
  }

  .List-Container {
    position: absolute;
    top: 40px;
    left: -140px;
    height: 300px;
    width: 300px;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
    padding: 5px;
    z-index: 5;

    .List-Title {
      @include FlexCenter;
      flex-direction: column;
      height: 60px;
      margin-bottom: 10px;
      border-bottom: 1px solid #193c5a1a;

      .Heading {
        @include TextProps(20px, bold, $bluish-black);
        margin-bottom: 5px;
      }

      .View-All {
        .Button {
          @include BacBorNone;

          p {
            @include TextProps(13px, normal, $bluish-black);
            text-decoration: underline;
          }
        }
      }
    }

    .List {
      @include FlexProps(center, flex-start);
      height: 70%;
      width: 100%;
      flex-direction: column;
      overflow-x: auto;

      .Empty-Notifications {
        @include FlexCenter;
        color: $dark-green;
      }
    }
  }
}
