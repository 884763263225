@import 'src/Style/Style.scss';

.Create-Edit-Workplace-Container {
  .Tooltip {
    @include FlexCenter;
    height: 20px;
    width: 20px;
    border: 1px solid $bluish-black;
    border-radius: 50%;
    margin: 0 5px;
    line-height: 20px;
    text-transform: initial;

    &:hover {
      color: $white;
      background-color: $green;
    }

    i {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 425px) {
  .Create-Edit-Workplace-Container {
    .Tooltip {
      .__react_component_tooltip {
        max-width: 250px;
      }
    }
  }
}
