@import 'src/Style/Style.scss';

.RevealHideBox-Container {
  @include FlexProps(flex-start, flex-start);
  @include FullHW;
  flex-direction: column;
  padding: 7px;

  .Button {
    margin-bottom: 5px;

    p {
      @include TextProps(13px, bold, $light-green);
    }
  }

  .Content {
    @include FlexProps(flex-start, flex-start);
    min-height: 25px;

    .Text {
      @include TextProps(18px, normal, $bluish-black);
      line-height: 1.25em;
      word-break: break-word;
    }
  }
}
