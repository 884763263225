@import 'src/Style/Style.scss';

.Packages-Container {
  .ql-editor {
    height: auto !important;
    width: auto !important;
  }
}


@media only screen and (min-width: 1537px) {
  .Onboarding {
    .container {
      max-width: 100%;
    }
  }
}
