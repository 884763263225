@import 'src/Style/Style.scss';

.B2B-Admin-Speak-Dashboard-Container {
  @include FlexColumn;

  .Speak-Dashboard-Tabs {
    @include FlexCenter;
    margin: 50px 62px 42px;
    height: 64px;
    width: auto;
    background: $light-gray;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);

    .Tab-Button {
      @include FlexCenter;
      @include TextProps(16px, bold, $bluish-black);
      width: 50%;
      height: 50px;
      border-radius: 5px;
      border: none;
      outline: none;
      line-height: 21px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .Active {
      height: 54px;
      width: 49%;
      background: $light-green;
      box-shadow: 0 3px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
    }
  }

  .User-Participation, .User-Performance {
    margin: 0 62px;

    .Title {
      @include TextProps(24px, bold, $bluish_black);
      margin-bottom: 10px;
    }

    .Sub-Title {
      @include TextProps(15px, bold, $bluish-black);
      margin-bottom: 10px;
    }

    .User-Participation-Container, .User-Performance-Container {
      margin-bottom: 30px;

      .Title-Bar {
        padding: 25px;
        background-color: $lightest-gray;
      }

      .Charts-Container, .User-Performance-Summary {
        display: flex;
        flex-wrap: wrap;
      }

      .Chart-Card {
        flex: 1 1 25%;
        min-width: 500px;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }

      .User-Performance-Card {
        flex: 1 1 15%;
        margin: 5px 5px;
        text-align: center;
        padding: 15px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        position: relative;

        .User-Performance-Card-Title {
          @include TextProps(13px, bold, $bluish-black);
        }

        .User-Performance-Card-Variation {
          position: absolute;
          top: 0;
          right: 0;
          margin: 2px 5px;

          .Variation {
            @include TextProps(10px, bold, $white);
            border-radius: 3px;

            &.Variation-Positive {
              background-color: rgb(95, 130, 121);
            }

            &.Variation-Negative {
              background-color: rgb(189, 101, 101);
            }
          }

          .Card-Variation-Label {
            @include TextProps(6px, bold, $bluish-black);
            text-transform: uppercase;
          }
        }

        .User-Performance-Content {
          display: flex;
          margin-top: 10px;

          .User-Performance-Value {
            padding: 0 10px;
            flex: 1 1;
            border-right: rgba(0, 0, 0, 0.1) 1px solid;

            .Value {
              @include TextProps(20px, bold, $bluish-black);
            }

            .Label {
              @include TextProps(13px, bold, $bluish-black);
            }

            &:last-child {
              border-right: none;
            }
          }
        }
      }

      .Content {
        background: $white;
        padding: 25px;
        margin-bottom: 10px;
        position: relative;

        .Phrases {
          @include FlexWrap;
          margin: 25px;
          justify-content: center;

          .Arrow-Holder {
            @include FlexColumn;
            margin: 10px 0 10px 0;

            .Top-Text {
              @include TextProps(14px, bold, $bluish-black);
              display: block;
              width: 200px;
              height: 30px;
              margin: 0 0 20px 10px;
              letter-spacing: 1px;
              text-transform: uppercase;
            }

            .Arrow {
              @include FlexCenter;
              height: 103px;
              width: 204px;
              position: relative;

              .Arrow-Icon {
                @include FullHW;
                @include Absolute0;
              }

              .Inside-Text {
                @include FullHW;
                @include Absolute0;
                @include FlexCenter;
                @include TextProps(14px, normal, $bluish_black);
                letter-spacing: 0.4px;
                text-transform: uppercase;

                span {
                  @include TextProps(24px, bold, $bluish_black);
                  margin-right: 4px;
                  letter-spacing: 1px;
                }
              }
            }
          }

          .Badge-Holder {
            @include FlexColumn;
            align-items: center;
            margin: 0 20px;

            .Top-Text {
              @include FlexCenter;
              @include TextProps(14px, bold, #38756a);
              margin: 0 auto 9px;
              letter-spacing: 1px;
              text-transform: uppercase;

              i.fa-info {
                @include FlexCenter;
                font-size: 10px;
                height: 8px;
                width: 8px;
                border: 1px solid;
                border-radius: 50%;
                margin-left: 2px;
                padding: 2px;
              }
            }

            .Badge {
              @include FlexCenter;
              height: 137px;
              width: 142px;
              position: relative;

              .Badge-Icon {
                @include FullHW;
                @include Absolute0;
              }

              .Inside-Text {
                @include FullHW;
                @include Absolute0;
                @include FlexCenter;
                @include TextProps(14px, normal, $bluish_black);
                flex-direction: column;
                letter-spacing: 0.4px;
                text-transform: uppercase;

                span {
                  @include TextProps(24px, bold, $bluish_black);
                  margin-bottom: 7px;
                  letter-spacing: 1px;
                }
              }
            }

            p {
              @include TextProps(16px, normal, $bluish_black);
              margin-top: 5px;
            }
          }
        }

        .Floating-Buttons {
          position: absolute;
          top: 20px;

          .Toggle-Button {
            @include TextProps(14px, none, $bluish-black);
            display: inline-block;
            padding: 7px 15px;
            border: rgb(165, 213, 201) 1px solid;
            cursor: pointer;
            background-color: #ffffff;

            &.Enabled {
              background-color: rgb(201, 230, 223);
            }

            &:first-child {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }

            &:last-child {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }

            &:not(:last-child) {
              border-right: none;
            }
          }
        }
      }
    }

    .Table-Container {
      min-width: 60px;
      overflow: auto;
      margin: 0 0 20px;
    }

    .Table {
      width: 100%;
      max-width: 100%;
      border: 1px solid #183c5b26;
      border-collapse: inherit;
      border-radius: 3px;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }

      thead {
        tr {
          background-color: #183c5b08;

          th {
            @include TextProps(12px, bold, $bluish_black);
            height: 38px;
            min-width: 150px;
            background: transparent;
            border: none;
            border-right: 1px solid #183c5b26;
            padding: 0 16px;
            vertical-align: middle;
            cursor: pointer;
            letter-spacing: 0.2px;
            word-break: break-word;
            text-align: left;

            &:first-of-type {
              font-size: 16px;
            }

            &:last-of-type {
              border-right: none;
            }
          }
        }
      }

      tbody {
        tr {
          &:nth-child(odd) {
            background-color: $white;
          }

          &:nth-child(even) {
            background-color: #183c5b1a;
          }

          td {
            @include TextProps(14px, bold, $bluish_black);
            height: 64px;
            min-width: 150px;
            background: transparent;
            border: none;
            border-top: 1px solid #183c5b26;
            border-right: 1px solid #183c5b26;
            text-align: left;
            vertical-align: middle;
            padding: 0 16px;
            word-break: break-word;
            letter-spacing: 0.2px;
            line-height: 18px;

            &:last-of-type {
              border-right: none;
            }

            .Pass {
              color: $dark-green;
            }

            .Fail {
              color: $light-red;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1560px) {
  .B2B-Admin-Speak-Dashboard-Container {
    .User-Participation, .User-Performance {
      .User-Participation-Container, .User-Participation-Container {
        .Chart-Card {
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .B2B-Admin-Speak-Dashboard-Container {
    .Learn-Dashboard-Tabs {
      flex-direction: column;
      height: auto;

      .Tab-Button {
        height: 50px;
        width: 100%;
      }
    }

    .User-Participation, .User-Performance {
      .User-Participation-Container, .User-Participation-Container {
        .Chart-Card {
          min-width: 280px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .B2B-Admin-Speak-Dashboard-Container {
    .Speak-Dashboard-Tabs {
      margin: 50px 35px 42px;
    }

    .User-Participation, .User-Performance {
      margin: 0 35px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .B2B-Admin-Speak-Dashboard-Container {
    .Speak-Dashboard-Tabs {
      margin: 50px 20px 42px;
    }

    .User-Participation, .User-Performance {
      margin: 0 20px;
    }
  }
}
