@import 'src/Style/Style.scss';

.Score-Card-Scale {
  @include FlexColumn;
  margin-bottom: 16px;

  .Scale-Header {
    @include FlexCenter;
    white-space: normal;

    h5 {
      @include TextProps(28px, bold, $bluish-black);
      letter-spacing: 0.8px;
      text-transform: uppercase;
      margin-bottom: 8px;
      z-index: 999;

      @media print {
        font-size: 29px;
      }
    }
  }

  .Scale-Legend {
    @include FlexColumn;
    position: relative;
    align-items: center;
    margin-top: 16px;

    .Bar {
      width: 100%;
      height: 10px;
      border-top: 2px solid $app-background;
      border-right: 2px solid $app-background;
      border-left: 2px solid $app-background;
    }

    .Text {
      @include TextProps(16px, normal, $bluish-black);
      position: absolute;
      bottom: 0;
      background-color: $white;
      padding: 0 20px;
      letter-spacing: 0.2px;
    }
  }

  .Scale {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 76px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 16px;

    @include tablet-down {
      @include FlexColumn;
      height: auto;
    }

    @media print {
      display: table;
      box-shadow: none;
      height:100px;
    }

    .Scale-Item {
      display: table-cell;
      position: relative;
      width: 100%;
      padding: 8px 16px;
      text-align: center;
      vertical-align: middle;
      border-right: 1px solid #193c5a;
      white-space: normal;

      p {
        @include TextProps(16px, bold, $bluish-black);
        margin-bottom: 0px;
        letter-spacing: 0.2px;
      }

      @include tablet-down {
        border-right: none;
        border-bottom: 2px solid $app-background;
      }

      @media print {
        border-right: 2px solid $app-background;
        border-bottom: none;
      }

      &:first-of-type {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        @include tablet-down {
          border-bottom-left-radius: initial;
          border-top-right-radius: 4px;
        }

        @media print {
          border-bottom-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }

      &:last-of-type {
        border-right: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        @include tablet-down {
          border-bottom: none;
          border-bottom-left-radius: 4px;
          border-top-right-radius: initial;
        }

        @media print {
          border-bottom: none;
          border-bottom-left-radius: none;
          border-top-right-radius: 4px;
        }
      }

      .Scale-Tooltip {
        @include FlexColumn;
        position: absolute;
        align-items: center;
        margin: 0 auto;
        z-index: 999;

        @include tablet-down {
          flex-direction: row;
        }

        @media print{
          flex-direction: column;
          bottom: -45px;
          left: 0;
          right: 0;
        }

        .Tooltip-Carat {
          width: 0;
          height: 0;
        }

        .Tooltip-Label {
          @include FlexCenter;
          height: 40px;
          width: 155px;
          background-color: $bluish-black;
          border-radius: 5px;
          padding: 4px 8px;

          @media print {
            height: 40px;
          }

          p {
            @include TextProps(16px, bold, $white);
          }
        }
      }
    }

    .base-tooltip {
      bottom: -45px;
      left: 0;
      right: 0;

      @include tablet-down {
        bottom: 4px;
        right: 8px;
      }

      @media print {
        bottom: 8px;
        left: 0;
        right: 0;
      }

      .Tooltip-Carat {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $bluish-black;

        @include tablet-down {
          border-right: 5px solid $bluish-black;
          border-bottom: 5px solid transparent;
          border-top: 5px solid transparent;
        }

        @media print {
          border-top: none;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid $bluish-black;
        }
      }
    }

    .target-tooltip {
      bottom: -45px;
      left: 0;
      right: 0;

      @include tablet-down {
        bottom: 4px;
        left: inherit;
        right: 8px;
      }

      @media print {
        bottom: 8px;
        left: 0;
        right: 0;
      }

      .Tooltip-Carat {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #546e7a;

        @include tablet-down {
          border-right: 5px solid $bluish-black;
          border-bottom: 5px solid transparent;
          border-top: 5px solid transparent;
        }

        @media print {
          border-top: none;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid $bluish-black;
        }
      }

      .Tooltip-Label {
        background-color: #546e7a !important;
      }
    }

    &.Has-Overlay {
      margin-bottom: 48px;
    }

    &.Small-Scale.Has-Overlay {
      margin-bottom: 20px;
    }

    &.Same-Overlay {
      margin-top: 48px;

      .base-tooltip {
        flex-direction: column-reverse;
        bottom: 70px;

        .Tooltip-Carat {
          border-top: 10px solid $bluish-black;
          border-bottom: 0;
        }
      }
    }

    &.Small-Scale.Same-Overlay {
      .base-tooltip {
        bottom: 45px;
      }
    }

    .Unscoreable {
      opacity: 0.43;
      background-color: #B9E9E0;
    }

    .Unscoreable-First {
      opacity: 0.43;
      background-color: #fff2e0;
    }
  }

  .Small-Scale {
    height: 50px;
    width: 80%;
    margin: 0 auto;

    .base-tooltip {
      bottom: -45px;

      @include tablet-down {
        bottom: 4px;
      }

      @media print {
        bottom: -22px;
      }
    }

    .target-tooltip {
      bottom: -45px;

      @include tablet-down {
        bottom: 4px;
      }

      @media print {
        bottom: -22px;
      }
    }
  }

  .Scale-Disclaimer {
    @include TextProps(16px, normal, $bluish-black);
  }
}

.Non-Qbs-Score {
  background-color: $sand;
}

.Qbs-Score {
  background-color: #80cbc4;
}

.waived {
  background-color: #e5e8eb;
}

.unrateable {
  background-color: #ff9999;
}


@media screen and (max-width: 1024px) {
  .Score-Card-Scale {
    .Small-Scale {
      height: auto;
    }
  }
}
