.UserDropdown {
  cursor: pointer;
  position: relative;
}

.UserDropdown-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-height: 60px;
  width: 250px;
  top: 40px;
  right: 10px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 0;
  z-index: 5;
  box-shadow: 0 3px 12px rgba(27,31,35,.15);
}

.Profile-Section {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.Profile-Section .Profile-Pic {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  margin-bottom: 10px;
  box-shadow: #32325d40 0 13px 27px -5px, #0000004d 0 8px 16px -8px;
}

.Profile-Section .User-Name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'LATO';
  color: #183c5b;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.UserDropdown-dropdown .Link {
  display: flex;
  border-bottom: 1px solid #193c5a1a;
  line-height: 1;
  transition: 0.3s;
}

.UserDropdown-dropdown .Link:hover {
  background-color: #f0f0f0;
  transition: 0.3s;
}

.UserDropdown-dropdown .Link:last-child {
  border-bottom: none;
}

.UserDropdown-dropdown .Link a {
  display: flex;
  font-family: 'LATO';
  font-size: 16px;
  color: #193c5a;
  padding: 13px 19px;
  width: 100%;
}

.UserDropdown button {
  display: flex;
  align-items: center;
}

.UserDropdown i {
  font-size: 20px;
  margin: 10px;
  color: white;
}

.UserDropdown-name {
  display: flex;
  align-items: center;
  color: white;
}

.UserDropdown-name .Profile-Pic {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  margin-right: 7px;
}

.ButtonBox-UserDropdown {
  background-color: transparent;
  border: none;
}

.ButtonBox-UserDropdown-dropdown {
  width: 100%;
  color: #3d6280;
  border: 1px solid #e9ebee;
}
  
.ButtonBox-UserDropdown-dropdown:hover {
  background-color: #f1f3f4;
}

@media only screen and (min-width : 3000px) and (max-width : 5120px) {
  .UserDropdown-dropdown {
    width: 260px;
  }

  .UserDropdown-dropdown a {
    font-size: 25px !important;
  }
}

@media screen and (min-width: 2300px) {
  .UserDropdown-dropdown a {
    font-size: 22px;
  }
}

@media only screen and (max-width: 768px) {
  .UserDropdown {
    display: none;
  }
}
