.tabs {
    display: flex;
    flex-direction: row;
    width: 100%;

    &__element {
        margin: 20px;
        padding: 10px;     
    }

    &__element-active {
        margin: 20px;
        padding: 10px;     
        border-bottom: solid 1px;
        color: #74a5b2;
    }
}

@media screen and (max-width: 768px) {
    .tabs {
        overflow: scroll;
    }
}

@media screen and (min-width: 769px) and (max-width: 912px) {
    .tabs {
        width: 440px;
    }
}

@media screen and (min-width: 913px) and (max-width: 1300px) {
    .tabs {
        width: 600px;
    }
}
