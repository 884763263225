@import 'src/Style/Style.scss';

.B2B-Admin-Learn-Dashboard-Container {
  @include FlexColumn;

  .Dynamic-Width {
    overflow-x: auto;
  }

  .Notes {
    @include FlexProps(center, flex-start);
    margin: 10px 0 5px;

    ul {
      li {
        @include TextProps(14px, normal, $bluish-black);
        line-height: 20px;
      }
    }
  }

  .Learn-Dashboard-Tabs {
    @include FlexCenter;
    margin: 50px 62px 42px;
    height: 64px;
    width: auto;
    background: $light-gray;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);

    .Tab-Button {
      @include FlexCenter;
      @include TextProps(16px, bold, $bluish-black);
      width: 50%;
      height: 50px;
      border-radius: 5px;
      border: none;
      outline: none;
      line-height: 21px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .Active {
      height: 54px;
      width: 49%;
      background: $light-green;
      box-shadow: 0 3px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
    }
  }

  .User-Participation {
    margin: 0 62px;

    .Title {
      @include TextProps(24px, bold, $bluish_black);
    }

    .No-Data {
      @include TextProps(16px, bold, $red);
      margin: 10px 0 20px;
    }

    .Level-Container {
      @include FlexSpaceBetween;
      flex-wrap: wrap;
      min-height: 220px;
      background-color: $white;
      border-radius: 4px;
      margin: 24px 0;

      .Level {
        @include FlexCenter;
        flex-wrap: wrap;
        margin: 25px;

        .Arrow-Holder {
          @include FlexColumn;
          margin: 10px;

          .Top-Text {
            @include TextProps(14px, bold, $bluish-black);
            margin: 0 0 20px 10px;
            letter-spacing: 1px;
            text-transform: uppercase;
          }

          .Arrow {
            @include FlexCenter;
            height: 103px;
            width: 204px;
            position: relative;

            .Arrow-Icon {
              @include FullHW;
              @include Absolute0;
            }

            .Inside-Text {
              @include FullHW;
              @include Absolute0;
              @include FlexCenter;
              @include TextProps(14px, normal, $bluish_black);
              letter-spacing: 0.4px;
              text-transform: uppercase;

              span {
                @include TextProps(32px, bold, $bluish_black);
                margin-right: 4px;
                letter-spacing: 1px;
              }
            }
          }
        }

        .Badge-Holder {
          @include FlexColumn;

          .Top-Text {
            @include FlexCenter;
            @include TextProps(14px, bold, #38756a);
            margin: 0 auto 9px;
            letter-spacing: 1px;
            text-transform: uppercase;

            i.fa-info {
              @include FlexCenter;
              font-size: 10px;
              height: 14px;
              width: 14px;
              border: 1px solid;
              border-radius: 50%;
              margin-left: 2px;
              padding: 2px;
            }

            .__react_component_tooltip.place-bottom {
              width: 300px;
              text-align: justify;
            }
          }

          .Badge {
            @include FlexCenter;
            height: 137px;
            width: 142px;
            position: relative;

            .Badge-Icon {
              @include FullHW;
              @include Absolute0;
            }

            .Inside-Text {
              @include FullHW;
              @include Absolute0;
              @include FlexCenter;
              @include TextProps(14px, normal, $bluish_black);
              flex-direction: column;
              letter-spacing: 0.4px;
              text-transform: uppercase;

              span {
                @include TextProps(32px, bold, $bluish_black);
                margin-bottom: 7px;
                letter-spacing: 1px;
              }
            }
          }
        }
      }
    }

    .User-Participation-Chart-Container {
    }

    .Average-Container {
      @include FlexCenter;
      min-height: 178px;
      background-color: $white;
      border-radius: 4px;
      margin: 24px 0;

      .Avg-Box {
        @include FlexColumn;
        @include FlexCenter;
        height: 130px;
        width: 20%;
        background-color: $white;
        border: 1px solid #193d5c1a;
        border-radius: 3px;
        margin: 24px 0 24px 24px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);

        &:last-of-type {
          margin-right: 24px;
        }

        p {
          @include TextProps(14px, bold, $bluish_black);
          margin-bottom: 25px;
          text-align: center;
          letter-spacing: 0.2px;
        }

        h4 {
          @include TextProps(32px, bold, $bluish_black);
          text-align: center;
          letter-spacing: 0.2px;
        }
      }
    }
  }

  .User-Performance {
    margin: 60px;

    .Title {
      @include TextProps(24px, bold, $bluish-black);
      margin-bottom: 24px;
    }

    .No-Data {
      @include TextProps(16px, bold, $red);
      margin-top: 10px;
    }

    .Table {
      width: 100%;
      max-width: 100%;
      border: 1px solid #183c5b26;
      border-collapse: inherit;
      border-radius: 3px;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }

      thead {
        tr {
          background-color: #183c5b08;

          th {
            @include TextProps(12px, bold, $bluish_black);
            height: 38px;
            min-width: 150px;
            background: transparent;
            border: none;
            border-right: 1px solid #183c5b26;
            padding: 0 16px;
            vertical-align: middle;
            cursor: pointer;
            letter-spacing: 0.2px;
            word-break: break-word;

            &:first-of-type {
              font-size: 16px;
            }

            &:last-of-type {
              border-right: none;
            }
          }
        }
      }

      tbody {
        tr {
          &:nth-child(odd) {
            background-color: $white;
          }

          &:nth-child(even) {
            background-color: #183c5b1a;
          }

          td {
            @include TextProps(14px, bold, $bluish_black);
            height: 64px;
            min-width: 150px;
            background: transparent;
            border: none;
            border-top: 1px solid #183c5b26;
            border-right: 1px solid #183c5b26;
            text-align: center;
            vertical-align: middle;
            padding: 0 16px;
            word-break: break-word;
            letter-spacing: 0.2px;
            line-height: 18px;

            &:last-of-type {
              border-right: none;
            }

            .Pass {
              color: $dark-green;
            }

            .Fail {
              color: $light-red;
            }
          }
        }
      }
    }

    .Average-Performance-Container {
      @include FlexColumn;
      background-color: $white;
      border-radius: 4px;
      padding: 24px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      margin-bottom: 24px;
    }

    .Users-Performance-Container {
      @include FlexColumn;
      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

      .Notes {
        padding: 0 20px 20px;
      }

      .Tabs-Container {
        @include FlexSpaceBetween;
        overflow-x: auto;

        .Tab-Holder {
          display: flex;
          flex-wrap: wrap;

          .Tab {
            @include FlexCenter;
            @include TextProps(18px, normal, $bluish-black);
            height: 65px;
            min-width: 228px;
            cursor: pointer;
            border-bottom: 2px solid transparent;
            padding: 0 20px;
          }

          .Tab.Active {
            border-color: #3d6280;
          }
        }

        .SearchBar {
          .Input {
            @include TextProps(16px, normal, $bluish-black);
            height: 47px;
            width: 300px;
            background-color: $white;
            border: 1px solid #7ec2d4;
            border-radius: 30px;
            margin:5px 0;
            padding: 0 8px 0 45px;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);
          }
        }

        .Filter-Holder {
          display: flex;
          flex-wrap: wrap;

          .Records-Per-Page, .Latest-Score {
            @include TextProps(16px, normal, $bluish_black);
            height: 47px;
            width: 185px;
            background-color: $white;
            border: 1px solid #7ec2d4;
            border-radius: 4px;
            margin: 5px 10px;
            padding: 0 8px 0 24px;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);
            letter-spacing: 0.2px;
          }
        }
      }

      .Table-Settings {
        @include FlexSpaceBetween;
        flex-wrap: wrap;
        padding: 15px 50px 0 25px;

        .Stats-Tabs {
          @include FlexCenter;
          height: 45px;
          width: 300px;
          background-color: $white;
          border: 1px solid $light-green;
          border-radius: 4px;
          cursor: pointer;

          .Stats-Tab {
            @include FlexCenter;
            @include TextProps(14px, bold, $bluish-black);
            height: 100%;
            min-width: 33.3%;
            text-align: center;

            &:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }

            &:last-child {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }

          .Active {
            background: #6bd1be80;
          }
        }

        .Actions {
          @include FlexCenter;
          margin: 5px 0;
        }
      }

      .Content {
        background: $white;
        padding: 25px;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .B2B-Admin-Learn-Dashboard-Container {
    .Learn-Dashboard-Tabs {
      flex-direction: column;
      height: auto;

      .Tab-Button {
        height: 50px;
        width: 100%;
      }
    }

    .User-Participation {
      .Average-Container {
        flex-direction: column;

        .Avg-Box {
          width: 100%;
          margin: 20px;

          &:last-of-type {
            margin-right: 20px;
          }
        }
      }
    }

    .User-Performance {
      .Users-Performance-Container {
        .Tabs-Container {
          flex-direction: column;

          .Tab-Holder, .Filter-Holder {
            justify-content: center;
          }
        }

        .Table-Settings {
          .Stats-Tabs {
            width: calc(100% - 50px);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .B2B-Admin-Learn-Dashboard-Container {
    .Learn-Dashboard-Tabs {
      margin: 50px 35px 42px;
    }

    .User-Participation {
      margin: 0 35px;
    }

    .User-Performance {
      margin: 60px 35px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .B2B-Admin-Learn-Dashboard-Container {
    .Learn-Dashboard-Tabs {
      margin: 50px 20px 42px;
    }

    .User-Participation {
      margin: 0 20px;

      .Average-Container {
        .Avg-Box {
          margin: 10px;

          &:last-of-type {
            margin-right: 10px;
          }
        }
      }
    }

    .User-Performance {
      margin: 60px 20px;

      .Users-Performance-Container {
        .Tabs-Container {
          .SearchBar {
            .Input {
              width: 250px;
            }
          }
        }
      }
    }
  }
}
