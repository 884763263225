.SectionBox {
    display: flex;
    flex-direction: column;
    margin: 25px 0;
    width: 100%;
    box-shadow: 0 3px 12px rgba(27,31,35,.15);
    padding: 20px;
}

.SectionBox-Menu {
  display: flex;
}

.SectionBox-Position {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  margin-right: 20px;
  background-color: #a2daea;
}

.SectionBox-DataBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #183D5B;
}

.SectionBox-InfoBox {
  display: flex;
  align-items: center;
  width: 920px;
  margin: 20px 20px 0 20px;
}

.SectionBox-TextContent {
  display: flex;
  flex-direction: column;
  width: 920px;
  margin: 20px 20px 0 20px;
}

.SectionBox-InfoBox-Label {
  margin-right: 15px;
}

.SectionBoc-SpanishKeyboard {
  display: flex;
  justify-content: center;
}

.SectionBox-DataBox-Buttons {
  display: flex;
  align-items: center;
}

.SectionBox-Single {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SectionBox-MediaContent {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}