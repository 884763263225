@import 'src/Style/Style.scss';

.Streak-Container {
  @include FlexProps(center, space-around);
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;

  .Streak-Box {
    flex: 0 1 375px;
    background-color: $white;
    border-radius: 5px;
    padding: 20px;
    margin: 5px 0;
    line-height: 1;

    .Streak-Card {
      @include FlexColumn;
      width: 100%;

      .Icon-And-Score {
        @include FlexCenter;
        position: relative;
        height: 110px;
        width: 100%;
        margin-bottom: 10px;

        .Icon {
          @include FullHW;
        }

        .Score {
          @include FlexCenter;
          @include TextProps(45px, bold, $blood-red);
          position: absolute;
          top: 45px;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      .Streak-Type-Heading,
      .Streak-Type-Instruction {
        @include FlexCenter;
        @include TextProps(16px, normal, $bluish-black);
        margin-bottom: 10px;
      }

      .Best-Streak-Container {
        @include FlexCenter;
        flex-direction: column;
        border: 1px solid $light-gray;
        border-radius: 5px;
        padding: 10px 30px;

        .Heading {
          @include TextProps(16px, bold, $bluish-black);
          margin-top: 10px;
        }

        .DataRows {
          @include FlexColumn;
          width: 100%;
          margin: 20px 0;

          .DataRow {
            @include FlexProps(center, space-between);
            margin-bottom: 5px;
            cursor: pointer;

            .Label {
              @include TextProps(14px, bold, $bluish-black);
            }

            .Value {
              @include TextProps(14px, normal, $bluish-black);
            }
          }
        }

        .Leaderboard-Button {
          @include FlexCenter;
          margin-top: 10px;

          .Leaderboard-Link {
            @include FlexProps(center, flex-end);
            @include BacBorNone;
            color: $green;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
