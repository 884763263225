@import 'src/Style/Style.scss';

.SpanishKeyboard {
  @include FlexCenter;
  width: 100%;
  margin: 20px 0;

  .Fixed-Spanish-Keyboard {
    @include FlexCenter;
    flex-wrap: wrap;
    text-align: center;
    max-height: 140px;
    overflow-y: auto;

    button {
      background-color: #7fc3d5;
      border-radius: 3px;
      border: none;
      color: #f8fcfd;
      width: 40px;
      height: 40px;
      margin: 2px;
      font-size: 18px;

      &:hover {
        background-color: #F0F0F0;
        color: #3d6280;
      }
    }
  }

  &.Fixed {
    height: 50px;

    .Fixed-Spanish-Keyboard {
      position: fixed;
      top: 65px;
      right: 0;
      left: 0;
      width: calc(100% - 309px);
      background: $white;
      box-shadow: 0 15px 10px -16px $gray;
      padding: 20px 0;
      margin: 0 auto;
      z-index: 99999;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .SpanishKeyboard {
    &.Fixed {
      height: 90px;

      .Fixed-Spanish-Keyboard {
        width: 87%;
      }
    }
  }
}
