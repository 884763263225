@-webkit-keyframes MoveUpDown {
  0%   { transform: translateY(50px); }
  100% { transform: translateY(0); }
}

@keyframes MoveUpDown {
  0%   { transform: translateY(50px); }
  100% { transform: translateY(0); }
}

.LessonsSummaryBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.LessonSummaryBox-Item {
  margin: 15px;
  -webkit-animation: MoveUpDown;
  animation: MoveUpDown;
  animation-duration: 1s;
}
