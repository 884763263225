.CreationForms-SelectBox select {
  height: 50px;
  width: 300px;
  color: #183D5B;
  border: 1px solid #183D5B;
  border-radius: 3px;
  font-size: 18px;
  margin: 10px 0;
  padding: 0 25px;
}

.CreationForms-SelectBox select::-ms-expand {
  background-color: #7fc3d5;
  color: #183D5B;
}