@import 'src/Style/Style.scss';

.LingoCard {
  .DashboardFormBox-Title {
    @include FlexCenter;

    .Title {
      @include TextProps(14px, bold, $bluish-black);
      letter-spacing: 0.2px;
      text-transform: uppercase;
    }

    .Dialect {
      @include TextProps(14px, normal, #193c5abf);
      font-style: italic;
      margin-left: 24px;
    }
  }

  .DashboardFormBox-Content_container {
    padding: 0;

    .ViewMode {
      .DashboardFormBox-Content {
        border-bottom: 1px solid #193c5a1a;
        padding: 22px;

        &:last-of-type {
          border-bottom: none;
        }

        .Dialect {
          @include TextProps(14px, normal, #193c5abf);
          font-style: italic;
        }

        .Native, .Dominant {
          @include TextProps(14px, normal, $bluish-black);
          text-transform: none;
        }

        .CanopyCredential {
          .Title {
            @include TextProps(14px, bold, $bluish-black);
            text-transform: none;
          }

          .DashboardFormBox-Label {
            @include TextProps(14px, normal, $bluish-black);
          }
        }

        .SelfRated, .Other, .Nothing {
          @include TextProps(14px, normal, $bluish-black);
          font-style: italic;
        }

        .Acquired {
          .DashboardFormBox-Label {
            @include TextProps(14px, bold, $bluish-black);
            text-transform: none;
          }
        }

        .Verified {
          .Title {
            @include TextProps(14px, bold, $bluish-black);
            text-transform: none;
          }

          .DashboardFormBox-Label {
            @include TextProps(14px, normal, $bluish-black);
          }
        }
      }
    }

    .EditMode {
      .DashboardFormBox-Content {
        border-bottom: 1px solid #193c5a1a;
        padding: 22px;

        &:last-of-type {
          border-bottom: none;
        }

        .DashboardFormBox-Line {
          margin: 8px 0 !important;
          grid-column-gap: 5%;

          .DashboardFormBox-Label {
            @include TextProps(13px, bold, $bluish-black);
            text-transform: none;

            .Dashed-Underline {
              border-bottom: 1px dashed;
              margin: 0 4px;
            }

            .Hint {
              @include TextProps(13px, normal, #193c5abf);
              margin-top: 4px;
            }
          }

          .DashboardFormBox-Label.Disabled, .DashboardFormBox-Select:disabled, .DashboardFormBox-Input:disabled {
            opacity: 0.2;
          }

          .DashboardFormBox-Input_container.LanguageCheckbox {
            line-height: 1;

            .CheckBox-Container {
              label {
                @include FlexProps(flex-start, inherit);
              }
            }
          }
        }

        .Acquired-Text {
          margin: 0 0 3px 5px;
        }

        .Bottom-Buttons {
          @include FlexSpaceBetween;
        }
      }
    }
  }

  .__react_component_tooltip {
    width: 200px;
    line-height: 20px;
  }
}

@media screen and (max-width: 768px) {
}
