.userDashboardSummary {
    background-color: #e7ebed;
    display: flex;
    flex-direction: column;
    width: 100vw;

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 95%;
        height: 100%;
        margin: auto;
    }
}

@media screen and (min-width: 769px) {
    .userDashboardSummary {
        height: 100%;
        width: 100%;
        &__inner {
            width: 100%;
            margin: 50px 0 0 100px;
        }
    }
}

