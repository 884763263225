.Pill-Container {
    display: flex;
    height: 30px;
    margin: 5px;
}

.Pill-Add {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6bd1be;
    border-radius: 20px;
    color: black;
    font-size: 75%;
    font-weight: 400;
    line-height: 28px;
    padding: 0 16px;
}
