::-webkit-inner-spin-button {
  display: none;
}

::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

::-webkit-calendar-picker-indicator:hover {
  background-color: #7fc3d5;
}

.InputBox-LogInBox {
  height: 50px;
  width: 100%;
  color: #183D5B;
  border: 1px solid #183D5B;
  border-radius: 3px;
  font-size: 18px;
  margin: 10px 0;
  padding: 0 25px;
}

.InputBox-LogInBox::placeholder {
  color: #183D5B;
  opacity: 0.7;
}

.InputBox-SignUp {	
  height: 50px;	
  width: 100%;	
  color: #183D5B;	
  background-color: #d6edf5;	
  border: 2px solid #7fc3d5;	
  border-radius: 3px;	
  font-size: 18px;	
  margin: 10px 0;	
  padding: 0 25px;	
}	

 .InputBox-SignUp::placeholder {	
  color: #183D5B;	
  opacity: 0.7;	
}

.InputBox-Small {
    width: 120px;
    height: 40px;
    margin-right: 10px;
    padding: 0px 8px;
    border: 1px solid #7ec2d4;
    border-radius: 4px;
    background-color: rgba(126,194,212,0.1);
    background-size: cover;
    font-family: Lato;
    font-size: 13px;
    color: #183c5b;
    text-decoration: none solid rgb(24, 60, 91);
}
