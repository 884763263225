@import 'src/Style/Style.scss';

.B2B-Admin-Compliance-Dashboard-Container {
  @include FlexColumn;

  .Product-Overview-Purchase {
    @include FlexCenter;
    flex-direction: column;
    @include BoxShadow;
    @include LeftToRightGradient($light-green, $sky-blue);
    padding: 50px;
    border-radius: 5px;
    margin: 30px 60px;

    .Icon {
      color: $bluish-black;
    }

    .Heading {
      @include TextProps(24px, bold, $bluish-black);
      margin: 20px 0;
    }

    .Description {
      @include TextProps(16px, bold, $bluish-black);
      text-align: center;
    }
  }

  .Dynamic-Width {
    overflow-x: auto;
  }

  .Compliance-Dashboard-Tabs {
    @include FlexCenter;
    margin: 50px 62px 42px;
    height: 64px;
    width: auto;
    background: $light-gray;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);

    .Tab-Button {
      @include FlexCenter;
      @include TextProps(16px, bold, $bluish-black);
      width: 50%;
      height: 50px;
      border-radius: 5px;
      border: none;
      outline: none;
      line-height: 21px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .Active {
      height: 54px;
      width: 49%;
      background: $light-green;
      box-shadow: 0px 3px 1px rgba(0,0,0,0.05), 0px 2px 2px rgba(0,0,0,0.1), 0px 3px 3px rgba(0,0,0,0.05);
    }
  }

  .Compliance-User-Participation {
    .Title {
      @include TextProps(24px, bold, $bluish-black);
      margin-left: 62px;
    }

    .No-Data {
      @include TextProps(16px, bold, $red);
      margin: 10px 0px 10px 62px;
    }

    .Badge-Container {
      @include FlexSpaceBetween;
      flex-wrap: wrap;
      min-height: 236px;
      background: $white;
      border-radius: 5px;
      margin: 24px 62px;
      padding: 24px;
      box-shadow: 0px 1px 2px rgba(0,0,0,0.2);

      .Arrow-Box-Holder  {
        @include FlexColumn;
        margin: 10px 0;

        .Text-Heading {
          @include TextProps(12px, bold, $bluish-black);
          margin: 0 0 20px 10px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        .Shape {
          @include FlexCenter;
          position: relative;
          height: 103px;
          width: 240px;

          .Arrow-Icon {
            @include FullHW;
            @include Absolute0;
          }

          .Box-Inner-Text {
            @include FullHW;
            @include Absolute0;
            @include FlexCenter;
            @include TextProps(12px, normal, $bluish-black);
            letter-spacing: 0.4px;
            text-transform: uppercase;

            .Digit {
              @include TextProps(20px, bold, $bluish-black);
              margin-right: 4px;
              letter-spacing: 1px;
            }
            .Text {
              display: flex;
              align-items: flex-end;
              height: 22px;
            }
          }
        }
      }

      .Combo-Badge-Holder {
        @include FlexColumn;
        margin: 10px 0;

        .Text-Heading {
          @include TextProps(12px, bold, #38756a);
          height: 17px;
          width: 152px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          text-align: center;
        }

        .Shape {
          @include FlexCenter;
          position: relative;
          height: 137px;
          width: 142px;

          .Image {
            @include FullHW;
          }

          .Inner-Text {
            @include FlexCenter;
            @include FullHW;
            @include Absolute0;
            flex-direction: column;

            .Digit {
              @include TextProps(20px, bold, #183c5b);
              @include FlexCenter;
              margin-bottom: 3px;
              letter-spacing: 1px;
            }

            .Text {
              @include TextProps(12px, none, $bluish-black);
              @include FlexCenter;
              letter-spacing: 0.4px;
              text-transform: uppercase;
            }
          }
        }

        .Total-Prograss {
          @include TextProps(12px, none, #183c5b80);
          @include FlexCenter;
          margin: 7px 0;
        }
      }
    }
  }

  .Compliance-User-Performance {
    margin: 0 62px 30px;

    .Title {
      @include TextProps(24px, bold, $bluish-black);
      margin: 32px 0 24px;
    }

    .No-Data {
      @include TextProps(16px, bold, $red);
      margin-top: 24px;
    }

    .Progress-Container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 24px;

      .Progress-Block {
        @include FlexCenter;
        height: 100px;
        width: 25%;
        background: $white;
        border-radius: 4px;
        margin: 5px 10px 5px 0;
        box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
        padding: 0 10px;

        .Text-Holder {
          @include FlexColumn;

          .Text {
            @include TextProps(14px, none, $bluish-black);
            display: flex;
            margin-bottom: 10px;
          }

          .Progress-Label {
            @include TextProps(30px, bold, #80cbc4);
            display: flex;
            text-transform: uppercase;
          }

          .Low {
            color: #193d5d4d;
          }

          .Mid {
            color: #7986cb;
          }
        }
      }
    }

    .Knowledge-Acquisition-Chart-Container {
      margin: 24px 0px;
    }

    .AllCharts {
      @include FlexCenter;
      width: 100%;

      .User-Pledges-Left-Chart-Container,
      .User-Pledges-Right-Chart-Container {
        @include FlexColumn;
        flex: 50%;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .B2B-Admin-Compliance-Dashboard-Container {
    .Compliance-Dashboard-Tabs {
      flex-direction: column;
      height: auto;

      .Tab-Button {
        height: 50px;
        width: 100%;
      }
    }

    .Compliance-User-Performance {
      .Progress-Container {
        flex-direction: column;

        .Progress-Block {
          width: 95%;
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .B2B-Admin-Compliance-Dashboard-Container {
    .Product-Overview-Purchase {
      padding: 30px 20px;
      margin: 10px;
    }

    .Compliance-Dashboard-Tabs {
      margin: 50px 35px 42px;
    }

    .Compliance-User-Participation,
    .Compliance-User-Performance {
      .Title {
        margin-left: 30px;
      }
    }

    .Compliance-User-Participation {
      .Badge-Container {
        margin: 16px 30px;
      }
    }

    .Compliance-User-Performance {
      margin: 0 30px;

      .AllCharts {
        flex-direction: column;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .B2B-Admin-Compliance-Dashboard-Container {
    .Compliance-Dashboard-Tabs {
      margin: 50px 20px 42px;
    }

    .Compliance-User-Participation,
    .Compliance-User-Performance {
      .Title {
        margin-left: 15px;
      }
    }

    .Compliance-User-Participation {
      .Badge-Container {
        margin: 10px 15px;
        padding: 12px;

        .Arrow-Box-Holder  {
          .Shape {
            width: 200px;
          }
        }
      }
    }

    .Compliance-User-Performance {
      margin: 0 15px;
    }
  }
}
