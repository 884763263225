.QuestionMini-QuestionBox {
  display: flex;
  align-items: center;
  margin: 20px;
}

.QuestionMini-QuestionBox-Position {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
}

.QuestionMini-QuestionBox-Question {
  width: 760px;
  margin: 0 20px;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.MultiButtonChoiceBox-QuestionBox-EditButtons {
  display: flex;
}

.MultiButtonChoiceBoxMini-ButtonBox-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.MultiButtonChoiceBoxMini-ButtonBox-div {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MultiButtonChoiceBoxMini-ButtonBox-div .Manage-Audio-Btns {
  display: flex;
  align-items: center;
}
