@import 'src/Style/Style.scss';

.Invitations-Container {
  @include FlexColumn;
  padding: 20px;

  .B2BAdminDashboard-Title {
    border-radius: 5px;
  }

  .Empty-Invitations {
    @include TextProps(16px, normal, $red);
    margin-top: 10px;
  }

  .Invitation-Batch-Container {
    @include FlexColumn;
    background-color: $white;
    border-radius: 10px;
    margin: 10px 0;
    padding: 10px 20px;

    .Batch-Info {
      @include FlexSpaceBetween;
      background-color: $white;

      .Info-Holder {
        line-height: 1.5;
      }
    }

    .Invitations-Info {
      .Info-Holder {
        @include FlexColumn;
        margin-top: 10px;

        .Tabs {
          display: flex;
          text-transform: capitalize;

          .Tab {
            @include FlexCenter;
            @include TextProps(18px, normal, $bluish-black);
            height: 39px;
            width: 33%;
            background-color: #dadada;
            cursor: pointer;
          }

          .Tab.Active {
            width: 33.33%;
            color: $white;
            background-color: $bluish-black;
          }
        }

        .Empty-Pills {
          @include TextProps(16px, normal, $red);
          margin-top: 10px;
        }

        .Pills {
          @include FlexProps(center, flex-start);
          flex-wrap: wrap;
          margin-top: 10px;
        }
      }
    }
  }
}
