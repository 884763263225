@import 'src/Style/Style.scss';

.Notification-Popup-Container {
  @include FlexProps(center, flex-start);
  height: auto;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid $light-gray;
  padding: 5px 10px;

  &:hover {
    background-color: $light-blue;
  }

  .modal-content {
    min-height: 230px;
  }

  .Notification {
    @include FlexProps(center, flex-start);
    @include FullHW;

    .Unread {
      @include FlexCenter;
      height: 10px;
      width: 10px;
      background-color: $light-green;
      border-radius: 50%;
      margin-right: 5px;
    }

    .Title {
      @include FlexProps(center, flex-start);
      @include TextProps(18px, normal, $bluish-black);
      flex: 90%;
      min-height: 40px;
    }
  }

  .Popup {
    .Heading {
      @include FlexCenter;
      @include TextProps(21px, bold, $bluish-black);
      height: 30px;
    }

    .Body {
      @include FlexColumn;
      margin: 20px 0;

      .Text {
        @include TextProps(17px, normal, $bluish-black);
        margin: 10px 0;
      }

      .Link {
        @include TextProps(18px, normal, $bluish-black);
        text-decoration: underline;
        margin: 5px 0;
      }
    }

    .Action {
      @include FlexCenter;
    }
  }
}
