.NavBar {
  background-color: rgb(107, 209, 190);
  width: 100%;
  min-height: 65px;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 9999;
  border-bottom: 5px solid #208c7a;
}

.Compliance-Platform .NavBar {
  border-bottom-color: #1A6588;
}

.NavBar-Items_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 18px 0px 18px 60px;
}

.NavBar-Items_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 18px 60px 18px 0px;
}

.NavBar-Items_right .icon {
  font-size: 25px;
}

.NavBar-Logo-Container {
  display: flex;
  align-items: center;
}

.NavBar-Logo {
  max-width: 100%;
  max-height: 34px;
  margin: 0 1px;
}

.NavBar-SubLogo {
  max-width: 100%;
  max-height: 34px;
  font-family: 'LATO';
  font-size: 29px;
  margin: 0 1px 15px 0;
}

.NavBar-SubLogo.gradient {
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.NavBar-Button {
  cursor: pointer;
  max-width: 150px;
  background-color: inherit;
  border: none;
  color: #f8fcfd;
  font-size: 14px;
  font-weight: bold;
  margin-right: 15px;
}

.NavBar-Button_visible_mobile {
  display: none;
}

.NavBar_position_fixed {
  position: fixed;
  z-index: 10;
}

.SideBar .ImageBox-Accordion {
  height: 34px;
  width: 34px;
}

.SideBar .SideBar-Close {
  height: 100%;
  position: fixed;
  background-color: #f5f5f5;
  cursor: pointer;
  margin-top: 65px;
}

.SideBar .SideBar-Close:hover {
  background: linear-gradient(0deg, rgba(230, 230, 230, 0.38) 0%, rgba(107, 209, 190, 0.55) 100%);
}

.SideBar .SideBar-Close .SideBar-Opener {
  padding: 20px 8px;
}

.SideBar .SideBar-Close .SideBar-Tooltip .Tooltip {
  padding: 20px;
  background-color: #1B466B;
  color: #6BD1BE;
  opacity: 1;
}

.SideBar .SideBar-Open .SideBar-Closer {
  height: 34px;
  width: 34px;
}

@media screen and (min-width: 900px) and (max-width: 1220px) {
  .NavBar .NavBar-Items_left {
    margin-left: 10px;
  }

  .NavBar .NavBar-Items_right {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .NavBar-Items_right,
  .NavBar {
    flex-direction: column;
  }

  .NavBar {
    min-height: 60px;
  }

  .NavBar-Items_left {
    width: 100%;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
  }

  .NavBar-Items_right {
    display: none;
    margin: 0px;
    width: 100%;
    background-color: #183D5B;
  }

  .NavBar-Button_visible_mobile {
    display: block;
    cursor: pointer;
    margin: 0 20px;
  }

  .NavBar-Items_visible_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: none;
  }

  .NavBar-Button {
    margin: 8px 0;
  }

  .NavBar-Button:hover {
    color: #7fc3d5;
  }
}


