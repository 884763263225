.MatchingBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.MatchingBox-Questions {
  width: 100%
}

.MatchingBox-Questions-QuestionBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 130px 5px 130px;
  position: relative;
}

.MatchingBox-Questions-QuestionBox:nth-child(odd) {
  background-color: #fafafa;
}

.Pin-Unanswered {
  width: 45px;
  height: 34px;
  color: #fff;
  background-color: #ff8f00;
  border-radius: 3px;
  position: absolute;
  top: 15px;
  left: -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

@media only screen and (max-width: 768px) {
  .MatchingBox-Questions-QuestionBox {
    padding: 5px 70px 5px 70px;
  }
}

@media only screen and (max-width: 500px) {
  .MatchingBox-Questions-QuestionBox {
    padding: 5px;
  }
}
