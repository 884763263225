@import 'src/Style/Style.scss';

.Comments-Container {
  @include FlexCenter;

  .Comments-Button {
    p {
      @include TextProp;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .Comments-View {
    .Inner-Modal {
      display: flex !important;
      height: 700px !important;
      width: 530px;
      top: 100px !important;
      left: 50% !important;
      right: 0 !important;
      transform: translate(-50%, 0) !important;

      .modal-content {
        margin: 0 !important;
      }
    }

    .Btn-Small {
      @include IconHW;
      color: #f8fcfd;
      background-color: #7fc3d5;
      border: none;
      border-radius: 3px;
      margin: 0 5px;
    }

    .modal {
      height: 100vh;
      width: 520px;
      top: 0;
      right: 0;
      bottom: 0;
      left: auto;

      .modal-content {
        @include FullHW;
        margin: 65px 0 0 0;
        padding: 0;
        border: none;
        box-shadow: -8px 0 15px rgba(0, 0, 0, 0.1);

        .Comments-View-Title {
          border-bottom: 1px solid #dedede;

          .Title-Container {
            @include FlexColumn;
            margin: 20px 25px;

            .Title-And-Btns {
              @include FlexSpaceBetween;
              margin-bottom: 20px;

              .Title {
                @include TextProp;
                font-size: 20px;
                font-weight: bold;
              }

              .Btns {
                display: flex;

                .Close {
                  background-color: transparent;
                  border: none;
                }
              }
            }

            .Description {
              @include TextProps(18px, normal, $bluish-black);
            }
          }
        }

        .Comments-View-Sticky,
        .Comments-View-NonSticky,
        .Comments-View-Flagged {
          @include FlexColumn;
          padding: 25px;
          border-bottom: 1px solid #dedede;

          .Comment-Container {
            display: flex;

            .Image {
              display: flex;
              align-items: flex-start;
              height: auto;
              width: auto;
              margin: 0 23px 0 0;

              img {
                @include IconHW;
                border-radius: 50%;
              }
            }

            .Comment {
              @include FlexColumn;
              margin-bottom: 20px;
              width: 100%;

              .Info {
                display: flex;
                align-items: center;
                margin: 0 0 4px 0;
                
                .User {
                  @include TextProp;
                  font-size: 16px;
                  font-weight: bold;
                  margin: 0 8px 0 0;          
                }

                .Time {
                  font-family: Lato;
                  font-size: 12px;
                  color: #6e6e6e;
                }
              }

              .Text {
                @include TextProp;
                max-height: 60px;
                font-size: 16px;
                margin: 0 0 8px 0;
                line-height: 1.25em;
                word-break: break-word;
                transition: max-height 1s ease;
                overflow: hidden;
              }

              .Show-More-Less-Btn {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 8px;

                .More-Less {
                  @include TextProp;
                  height: 24px;
                  width: 100px;
                  font-size: 10px;
                  font-weight: bold;
                  background-color: #f2f2f2;
                  border: 0;
                  border-radius: 4px;
                  transition: 0.25s;
                }
              }

              .Reaction {
                .Reaction-Container {
                  @include FlexSpaceBetween;

                  .Like-Container {
                    .Btn {
                      background-color: $lightest-gray;
                      border-radius: 30px;
                      border: 1px solid #6e6e6e;
                      padding: 0 10px;

                      .Like {
                        .Icon {
                          color: #6e6e6e;
                        }

                        .Text {
                          color: #6e6e6e;
                          margin-left: 5px;
                        }

                        .Count {
                          color: #6e6e6e;
                          margin-left: 5px;
                        }
                      }
                    }

                    .Btn.Liked {
                      border: 1px solid rgba(52, 194, 168, 0.5);
                      background-color: #6bd1be1a;

                      .Like {
                        .Icon {
                          color: rgb(52, 194, 168) !important;
                        }

                        .Text {
                          color: rgb(52, 194, 168) !important;
                        }

                        .Count {
                          color: rgb(52, 194, 168) !important;
                        }
                      }
                    }
                  }

                  .Flag-Container {
                    .Btn {
                      height: 25px;
                      width: 25px;
                      border-radius: 30px;
                      border: 1px solid #6e6e6e;
                      background: none;

                      .Flag {
                        color: #6e6e6e;

                        .Icon {
                          font-size: 12px;
                        }
                      }

                      &:hover {
                        border: 1px solid rgba(255, 138, 101, 0.5);
                        background: rgba(255, 138, 101, 0.1);

                        .Icon {
                          color: rgba(255, 138, 101, 0.8); 
                        }
                      }
                    }

                    .Flag-Modal {
                      .Close {
                        @include BacBorNone;
                        position: absolute;
                        top: 12px;
                        right: 8px;

                        i {
                          font-size: 16px;  
                        }
                      }

                      .Form {
                        margin-top: 50px;

                        .ReportReview {
                          text-align: center;
                          font-size: 24px;
                          color: #183d5b;
                          font-family: 'LATO';
                        }

                        .Flag-RadioBox {
                          @include TextProp;
                          padding: 25px 0 0px 30px;
                          font-size: 18px;
                          cursor: pointer;

                          .Radio {
                            margin-right: 5px;
                          }

                          .Flag-P {
                            font-size: 13px;
                            color: #6e6e6e;
                            font-family: 'LATO';
                            margin-left: 22px;
                          }

                          .Flag-I {
                            display: flex;
                            height: 28px;
                            width: 385px;
                            font-family: 'LATO';
                            font-size: 14px;
                            color: #6e6e6e;
                            border: 1px solid #dedede;
                            border-radius: 4px;
                            margin: 6px 20px;
                            padding: 0px 8px;
                          }
                        }

                        .Flag-Submit {
                          float: right;
                          margin: 20px;
                        }
                      }
                    }
                  }
                }
              }

              .Replies {
                margin-top: 10px;
              }

              .Flagger-Container {
                .Flagger {
                  td {
                    @include TextProp;
                    font-size: 16px;
                    border: 0;
                    padding: 5px;
                  }
                }
              }
            }
          }
        }

        .Comments-View-Sort {
          .Sort-Container {
            .Sort {
              select {
                @include BacBorNone;
                font-size: 16px;
                color: #6e6e6e;
                font-family: Lato;
                padding: 20px;
                cursor: pointer;
              }
            }
          }
        }

        .Comments-View-Form {
          display: flex;
          padding: 25px 25px 70px;

          .Form-Container {
            .Errors {
              @include FlexCenter;
              list-style: none;
              margin-bottom: 5px;

              li {
                @include TextProps(16px, normal, $red);
              }
            }

            .Form {
              display: flex;

              .Image {
                display: flex;
                margin: 0 23px 0 0;

                img {
                  height: 47px;
                  width: 47px;
                  border-radius: 50%;
                }
              }

              .Field {
                display: flex;
                position: relative;

                .Input {
                  height: 47px;
                  width: 400px;
                  font-family: Lato;
                  font-size: 14px;
                  padding: 0 64px 0 12px;
                  border: 1px solid #e3e3e3;
                  border-radius: 30px;
                }

                .Button {
                  position: absolute;
                  top: 0;
                  right: 14px;
                  left: auto;
                  bottom: 0;
                  background-color: transparent;
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width:  520px) {
  .Comments-View .modal {
    width: 100vw !important;
  }

  .Comments-View-Form .Field .Input {
    width: 100% !important;
  }

  .Inner-Modal {
    width: 100% !important;
  }
}
