.UploadMedia {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.UploadMedia-Title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #183D5B;
}

.UploadMedia-ButtonBox {
  width: 150px;
}

.UploadMedia-button {
  border: none;
  background-color: transparent;
}

.UploadMedia-button i {
  font-size: 30px;
}

.UploadMedia-button:hover {
  text-decoration: underline;
}

.UploadMedia-dropzone {
  margin: 20px 0;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  color: #3d6280;
  border: 1px solid #e9ebee;
  border-radius: 3px;
  background-color: white;
  cursor: pointer;
}

.UploadMedia-dropzone img {
  max-width: 410px;
  height: 300px;
  object-fit: contain;
  object-position: center;
}

.SubmitWait {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.2;
  display: none;
  z-index:  9;
}

.SubmitWait > span:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.Section-Separator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'LATO';
  font-size: 16px;
}
