.DragAndDropBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  width: 100%;
}

.DragAndDropBox-OptionsArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.DragAndDropBox-Questions {
  width: 100%;
}

.DragAndDropBox-Questions-QuestionBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
  position: relative;
}

.DragAndDropBox-Draggable__container {
  display: flex;
  align-items: center;
}

.DragAndDropBox-Questions-QuestionBox h5 {
  margin: 0 5px;
}

.Questions-DragAndDropBox-Hint {
  display: flex;
  width: 100%;
}

.DragElement {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  min-width: 240px;
  max-width: 300px;
  font-family: 'LATO';
  font-size: 18px;
  color: #193c5a;
  background-color: #ffffff;
  margin: 5px;
  padding: 3px 20px;
  border: 1px solid #6bd1be;
  border-radius: 4px;
  cursor: move;
}

.DragElement:hover {
  background-color: #6bd1be;
  transform: scale(1.03);
  box-shadow: #32325d40 0 13px 27px -5px, #0000004d 0 8px 16px -8px;
  transition: 0.3s;
}

.DropArea-Options {
  height: 45px;
  min-width: 240px;
  background-color: #f2f2f2;
  margin: 5px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.DropArea-Questions {
  height: 45px;
  min-width: 240px;
  background-color: #ffffff;
  margin: 5px;
  border: 1px dashed #6bd1be;
  border-radius: 4px;
}

.DragAndDropBox-correct {
  border-color: #7de3bb;
}

.DragAndDropBox-wrong {
  border-color: #D47e7e;
}

.DragAndDropBox-Questions-QuestionBox:nth-child(odd) {
  background-color: #fafafa;
}

.Pin-Unanswered {
  width: 45px;
  height: 34px;
  color: #fff;
  background-color: #ff8f00;
  border-radius: 3px;
  position: absolute;
  top: 15px;
  left: -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

@media only screen and (min-width: 768px) {
  .SortingBox.Lengthy-Drag .DragAndDropBox-OptionsArea,
  .MatchingBox.Lengthy-Drag .DragAndDropBox-OptionsArea,
  .DragAndDropBox.Lengthy-Drag .DragAndDropBox-OptionsArea {
    position: fixed;
    top: 65px;
    width: calc(100vw - 300px);
    max-height: 140px;
    background: white;
    box-shadow: 0 15px 10px -16px lightslategray;
    overflow-y: auto;
    padding: 20px 0;
    z-index: 99999;
  }

  .SortingBox.Lengthy-Drag .DragAndDropBox-Questions,
  .MatchingBox.Lengthy-Drag .DragAndDropBox-Questions,
  .DragAndDropBox.Lengthy-Drag .DragAndDropBox-Questions {
    margin-top: 240px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .SortingBox.Lengthy-Drag .DragAndDropBox-OptionsArea,
  .MatchingBox.Lengthy-Drag .DragAndDropBox-OptionsArea,
  .DragAndDropBox.Lengthy-Drag .DragAndDropBox-OptionsArea {
    width: calc(100vw - 100px);
  }
}

@media only screen and (max-width: 768px) {
  .DragAndDropBox {
    padding-top: 0;
  }

  .DragAndDropBox-OptionsArea {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 92px;
    height: 70px;
    width: auto;
    background-color: #f5f5f5;
    border: 1px solid #183d5b2e;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    margin: 0;
    padding: 30px 15px;
    overflow-y: scroll;
    z-index: 99999;
  }

  .DragElement {
    min-width: 100px;
    padding: 0 12px;
    font-size: 14px;
  }

  .DropArea-Options {
    min-width: 100px;
    padding: 0 12px;
  }

  .DropArea-Questions {
    min-width: 120px;
  }

  .DragAndDropBox-Questions-QuestionBox {
    flex-wrap: nowrap !important;
  }

  .DragAndDropBox-Questions-QuestionBox h5 {
    margin: 0 3px;
  }
}
