@import 'src/Style/Style.scss';

.Lingo-Container {
  @include FlexColumn;
  width: 100%;

  .ViewMode .DashboardFormBox-Line {
    margin: 13px 0 !important;
  }

  .EditMode .DashboardFormBox-Line {
    margin: 5px 0 !important;
  }

  .Info-Text {
    @include FlexSpaceBetween;
    @include TextProps(14px, normal, $bluish-black);
    min-height: 60px;
    background-color: $white;
    margin-bottom: 44px;
    padding: 0 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    line-height: 20px;
    letter-spacing: 0.2px;
  }

  .Add-Language-Info {
    @include FlexCenter;
    @include FlexColumn;

    p {
      @include TextProps(20px, normal, $bluish-black);
      letter-spacing: 0.2px;
    }

    .Add-Language-Target-Icon {
      height: 54px;
      width: 54px;
      margin: 9px 0 18px;
    }
  }

  .My-Lingos, .New-Lingos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .My-Lingo, .New-Lingo {
      display: flex;
      flex: 0 48%;

      .Acquired-Through-List {
        padding-inline-start: 17px;

        li {
          @include TextProps(14px, normal, $bluish-black);
          letter-spacing: 0.2px;
        }
      }

      .Lingo-Errors {
        @include FlexColumn;
        margin: 0 0 10px;

        li {
          font-family: 'LATO';
          font-size: 14px;
          color: $red;
        }
      }

      .CheckBox-Container {
        @include TextProps(14px, normal, $bluish-black);
        display: flex;
        margin: 0 0 5px;

        .CheckBox {
          height: 14px;
          width: 14px;
          margin: 0 3px 3px 0;

          input {
            height: 12px;
            width: 12px;
          }

          .CheckBox-Custom {
            height: 15px;
            width: 15px;
            background-color: $white;
            border-color: $light-green;

            &::after {
              top: 0;
              left: 3px;
              height: 8px;
              width: 5px;
              border-color: $white;
            }
          }
        }

        .CheckBox input:checked ~ .CheckBox-Custom {
          background-color: $light-green;
        }
      }

      .Add-Misc-Dialect {
        @include BacBorNone;
        height: 17px;
        width: auto;

        .Btn {
          @include FlexCenter;
          @include TextProps(14px, normal, $bluish-black);
          letter-spacing: 0.4px;

          .Icon {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .New-Lingo-ButtonBox {
    @include FlexCenter;
    margin: 0 0 15px;

    .New-Lingo-Button {
      height: 50px;
      width: 215px;
      background-color: $white;
      border: none;
      border-radius: 30px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

      .Btn {
        @include FlexCenter;
        @include TextProps(14px, bold, $bluish-black);
        letter-spacing: 0.4px;

        .Icon {
          margin-right: 4px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .Lingo-Container {
    .Info-Text {
      padding: 10px 20px;
    }

    .My-Lingos {
      flex-direction: column;
      flex-wrap: inherit;

      .My-Lingo {
        flex: 100%;
      }

      .My-Lingo:nth-child(odd) {
        margin-right: 0 !important;
      }
    }

    .New-Lingos {
      .New-Lingo {
        flex: 100%;
      }
    }
  }
}
