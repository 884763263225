.fa-play, .fa-pause, .fa-circle-notch {
  color: #6bd1be;
}

.ButtonBox-AudioPlayButton:disabled,
.Download-Button:disabled {
  cursor: not-allowed;
}

.Play-Download-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.No-Recording .AudioPlayButton {
  opacity: 0.25;
}

.Download-Button {
  border: none;
  background: none;
  color: #6BD1BE;
}

.Download-Icon {
  font-size: 20px;
}
