.QuizPage {
  max-width: calc(100vw - 300px);
  margin: 0 auto;
  padding-top: 20px;
}

.QuizPage-Backdrop {
  min-height: calc(100vh - 177px);
}

.QuizPage-AudioBox {
  padding-bottom: 30px;
}

.QuizPage-TextBox {
  padding: 30px;
}

.QuizPage-Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border: 2px solid #183d5b2e;
  border-radius: 4px;
  margin: 20px 0;
  padding: 40px;
}

.QuizPage-Submit-Title {
  margin: 0 0 20px 0;
}

.QuizPage .ql-editor,
.QuizPage .ql-editor p {
  margin: auto;
}

.QuizPage .ql-editor img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  overflow: hidden;
}

@media only screen and (max-width: 1000px) {
  .QuizPage {
    max-width: 876px;
  }
}

@media only screen and (max-width: 900px) {
  .QuizPage-Content {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .QuizPage {
    max-width: 95vw;
  }

  .QuizPage-Content {
    width: 100%;
  }

  .QuizPage-Content.drag_and_drop,
  .QuizPage-Content.sorting,
  .QuizPage-Content.matching {
    margin-bottom: 180px;
  }

  .QuizPage-BasicContent {
    margin: 0 30px;
  }

  .QuizPage-SingleContent {
    margin: 0 30px;
  }

  .QuizPage-Backdrop {
    min-height: calc(100vh - 192px);
  }

  .QuizPage .ql-editor,
  .QuizPage .ql-editor p,
  .QuizPage p,
  .QuizPage h5 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .QuizPage-Content {
    width: 100%;;
    padding: 20px;
  }
}
