@import 'src/Style/Style.scss';

.AdminHome {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;

  .Heading {
    color: #183D5B;
    margin: 15px 0;
  }

  .SubHeading {
    color: #183D5B;
    padding: 0 5px;
  }

  .Basic-Stats {
    @include FlexColumn;
    width: 100%;

    .Stats {
      @include FlexWrap;
      width: 100%;
    }
  }

  .License-Stats {
    @include FlexColumn;
    width: 100%;
    border: 1px solid $light-gray;
    border-radius: 4px;
    margin: 10px;

    .Tabs {
      @include FlexProps(center, flex-start);
      min-height: 45px;
      width: 100%;
      background-color: $light-gray;
      border-radius: 4px;
      margin-bottom: 10px;

      .Tab {
        @include FlexCenter;
        height: 41px;
        border-radius: 5px;
        margin: 0 3px;
        padding: 0 15px;
        cursor: pointer;
        transition: .5s;

        &.Active {
          background-color: $light-green;
          box-shadow: 0 3px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
          transition: 0.5s;
        }
      }
    }

    .Stats {
      @include FlexWrap;
      width: 100%;
    }
  }

  .Stats-Container {
    @include FlexColumn;
    height: auto;
    min-width: 350px;
    background-color: $white;
    border-radius: 4px;
    margin: 10px;
    padding: 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

    .Head {
      @include FlexProps(flex-start, flex-start);
      flex-direction: column;
      padding-bottom: 5px;
      border-bottom: 1px solid $darkest-green;

      .Title {
        @include TextProps(23px, bold, $bluish-black);
      }

      .Product {
        @include TextProps(16px, normal, $white);
        width: fit-content;
        background-color: $green;
        border-radius: 5px;
        margin: 3px 0;
        padding: 3px 5px;
      }
    }

    .Body {
      @include FlexProps(center, flex-start);
      margin: 15px 0;

      .Description {
        @include TextProps(16px, normal, $bluish-black);
      }
    }

    .Foot {
      @include FlexProps(center, flex-start);
      flex-wrap: wrap;

      .Empty {
        @include TextProps(16px, normal, $red);
      }
    }

    &.Small-Fonts {
      .StatStar-Container {
        .Star {
          .Value {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.B2B-Admin-Dashboard {
  display: flex;
  margin-top: 75px;
}

.B2B-Admin-Dashboard-Main {
  flex: 1 1;
}

.B2BAdminDashboard-Title {
  @include FlexProps(center, space-between);
  background-color: $white;
  height: 120px;
  width: 100%;
  padding: 0 180px;
}

.B2BAdminDashboard-Button {
  color: $white;
  background-color: #669dac;
  border: none;
  border-radius: 2px;
  height: 34px;
  width: 186px;
}

.B2BAdminDashboard-GroupList__container {
  width: 100%;
  padding: 0 180px;
  margin: 10px 0;
}

.B2BAdminDashboard-GroupList {
  margin: 30px 0;
  background-color: $white;
  border: 1px solid #b6b6b6;
  padding: 20px;
}

.B2BAdminDashboard-GroupList__line {
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #b6b6b6;
}

.B2BAdminDashboard-GroupList__item {
  width: 300px;
  color: #183D5B;
}

.B2BAdminDashboard-GroupList__line a {
  width: calc(100% - 900px);
  display: flex;
  justify-content: center;
  color: #007aff;
}

.B2BAdminDashboard-CourseProgress-Report {
  .Form {
    flex-wrap: wrap;

    .Generate-Report {
      @include FlexCenter;
      width: 100%;
      margin-top: 10px;
    }
  }

  .B2BAdminDashboard-GroupList {
    .B2BAdminDashboard-GroupList__line {
      .B2BAdminDashboard-GroupList__item {
        width: 150px;
        word-break: break-word;
      }
    }
  }
}

.red {
  background-color: #c62323;
}

.dark-blue {
  background-color: #183D5B;
}

.green {
  background-color: #7de3bb;
}

.SearchBox {
  @include FlexProps(center, space-between);
}

.SearchBox__label {
  @include FlexColumn;
}

.SearchBox__label span {
  color: rgb(24, 60, 91, 0.5);
}

.SearchBox__label input {
  height: 30px;
  width: 300px;
  font-size: 13px;
  margin-top: 10px;
  border: 1px solid black;
  padding: 0 10px;
}

.B2BAdminDashboard-Select {
  @include TextProps(13px, inherit, #183c5b);
  height: 38px;
  width: 200px;
  margin: 10px 0;
  padding: 0px 8px;
  border: 1px solid #7ec2d4;
  border-radius: 4px;
  background-color: $white;
  text-decoration: none solid rgb(24, 60, 91);
}

.B2BAdminDashboard-Date {
  @include TextProps(13px, inherit, #183c5b);
  height: 38px !important;
  width: 200px !important;
  margin: 10px 0;
  padding: 0px 8px;
  border: 1px solid #7ec2d4;
  border-radius: 4px;
  background-color: $white;
  text-decoration: none solid rgb(24, 60, 91);
}

.User-Detail-WorkPlace {
  @include FlexColumn;
}

.User-Detail-WorkPlace .workplace-title {
  width: 92%;
  margin: 30px auto 20px;
}

.User-Detail-WorkPlace .workplace {
  width: 92%;
  background-color: $white;
  margin: 0 auto;
  padding: 10px;
}

.User-Detail-WorkPlace .workplace select{
  border: 1px solid black;
}


@media only screen and (max-width: 500px) {
  .AdminHome {
    .SubHeading {
      text-align: center;
    }

    .License-Stats {
      .Tabs {
        flex-direction: column;
      }
    }

    .Stats-Container {
      width: calc(100% - 60px);
      min-width: 200px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .AdminHome {
    .License-Stats {
      align-items: center;
      margin-top: 30px;

      .Tabs {
        width: calc(100% - 22px);
        flex-direction: column;
        margin-top: -25px;

        .Tab, .Active {
          width: calc(100% - 40px);
          margin: 5px;
        }
      }
    }
  }
}
