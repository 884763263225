.Confirmation-Container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image:radial-gradient(at top left, rgba(107,209,190,1) 67%, rgb(255 255 255) 36% );
  background-size: 115% 60%,cover;
  background-repeat: no-repeat;
  width: 100%;
  min-width: 100%;
  height: 90vh;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.Confirmation-Alert {
  position: relative;
  background-color: white;
  text-align: center;
  width: 50vw;
  top: 0;
  margin: auto;
  padding: 40px;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
}

.Confirmation-Alert .Alert {
  text-align: center;
  font-size: 20px;
  color: #2c405a;
  font-family: 'LATO';
  margin-bottom: 20px;
}

.Confirmation-Alert .Button {
  background-color:rgb(44, 64, 90);
  border-radius: 4px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: 'LATO';
  height: 74px;
  font-size: 20px;
  color: white;
  padding: 20px;
}

.Confirmation-Alert .EmailSent {
  margin: 20px;
}
