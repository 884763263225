@import 'src/Style/Style.scss';

.InviteUsers.SharedAdminDashboard-GroupList__container {
  padding: 0 1rem;
}

.InviteUsers.B2BAdminDashboard-GroupList__container {
  padding: 0;
  margin: 10px auto;
  width: 90%;
}

.InviteUsers {
  .DashboardFormBoxNoEdit-Container.Adjust-Space {
    margin-top: -20px;
  }

  .DashboardFormBox-Content {
    .Invite-Tabs-Holder {
      @include FlexProps(center, space-between);
      height: 50px;
      width: 100%;
      background-color: $light-gray;
      border-radius: 5px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);

      .Invite-Tab {
        @include FlexCenter;
        @include TextProps(14px, bold, $white);
        height: 90%;
        width: 100%;
        border-radius: 5px;
        margin: 0 3px;
        cursor: pointer;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        flex: 1 1 40%;
      }

      .Selected-Tab {
        box-shadow: 0 3px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
        min-height: 40px;
        background-color: $light-green;
        border-color: $light-green;
        flex: 1 1 40%;
      }
    }

    .Excel-Tab-Content {
      @include FlexColumn;
      width: 100%;

      .Instructions {
        padding-left: 30px;

        li {
          @include TextProps(16px, normal, $bluish-black);
        }
      }

      .Excel-Field {
        @include FlexColumn;
        width: 100%;

        .Input {
          border: 1px solid #193c5a4d;
          border-radius: 5px;
          margin-top: 5px;
          padding: 10px 5px;
          font-size: 13px;
        }
      }
    }

    .Emails-Tab-Content {
      @include FlexColumn;
      width: 100%;

      .Instructions {
        padding-left: 30px;

        li {
          @include TextProps(16px, normal, $bluish-black);
        }
      }

      .Heading-Holder {
        @include FlexProps(center, space-between);
        width: 100%;

        .DashboardFormBox-Label {
          font-size: medium;
        }

        .InviteUsers-Update-Button {
          @include TextProps(18px, bold, $light-green);
          background-color: $white;
          align-self: start;
          border: 0;
          margin: 0;
          padding: 0;
          cursor: pointer;

          &:hover {
            color: #669dac;
          }
        }
      }

      .TextAreaBox-InviteUsers {
        width: 100%;
      }

      .Pill-Holder {
        @include FlexProps(center, flex-start);
        flex-wrap: wrap;
        padding-top: 20px;
      }
    }

    .Institution-Container {
      display: flex;
      justify-content: center;
      width: 100%;

      .Institution-Drop-Down {
        @include FlexProps(center, space-between);
        width: 60%;
      }
    }

    .License-Container {
      display: flex;
      justify-content: space-around;
      width: 100%;

      .License-Drop-Down {
        display: flex;
        justify-content: space-between;
        width: 60%;

        .CreationForms-RadioBox {
          justify-content: flex-start;
          flex-wrap: wrap;
        }
      }
    }

    .Buttons-Container {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .disabled_save_button {
        background-color: #dddddd;
        color: $black;
      }
    }
  }
}

.fdc {
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .InviteUsers {
    .DashboardFormBox-Content {
      .License-Container {
        .License-Drop-Down {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .InviteUsers {
    .DashboardFormBox-Content {
      .Invite-Tabs-Holder {
        flex-direction: column;
        height: 100px;
      }
    }
  }
}
