@import 'src/Style/Style.scss';

.Notification-Page-Container {
  @include FlexColumn;
  margin: 60px;

  .Page-Title {
    @include TextProps(25px, bold, $bluish-black);
    margin-bottom: 30px;
  }

  .Empty-Notifications {
    @include FlexProps(center, flex-start);
    @include TextProps(18px, normal, $red);
    height: 30px;
  }

  .Notifications {
    @include FlexColumn;
  }
}

@media only screen and (max-width: 425px) {
  .Notification-Page-Container {
    margin: 60px 10px;
  }
}
