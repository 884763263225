@-webkit-keyframes MoveUpDown {
  0%   { transform: translateY(50px); }
  100% { transform: translateY(0); }
}

@keyframes MoveUpDown {
  0%   { transform: translateY(50px); }
  100% { transform: translateY(0); }
}

.Bookmarks-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Bookmarks-Container .Bookmarks-Title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(100vw - 300px);
  width: 100%;
  padding: 20px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(25, 60, 90, 0.25);
}

.Bookmarks-Title .Bookmarks-Heading {
  font-weight: bold;
  -webkit-animation: MoveUpDown;
  animation: MoveUpDown;
  animation-duration: 1s;
}

.Bookmarks-Title .Bookmarks-Heading i {
  margin-right: 10px;
}

.Bookmarks-Container button {
  background-color: transparent;
  display: inline-block;
  border: 2px solid #193c5a;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: .7rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 3px;
  color: #193c5a;
  margin-bottom: 30px;
}

.Bookmarks-Container button p {
  font-weight: bold;
}

.Bookmarks-Container button:hover {
  color: #fff;
  background-color: #193c5a;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.Bookmarks-Container .Bookmarks-Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Bookmarks-Content .Bookmarks-Lesson-Container {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(100vw - 342px);
  width: 100%;
  border: 2px solid #eceff0;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  -webkit-animation: MoveUpDown;
  animation: MoveUpDown;
  animation-duration: 1s;
}

.Bookmarks-Lesson-Container .Bookmarks-Lesson {
  display: flex;
  align-items: center;
}

.Bookmarks-Lesson-Container .Bookmarks-Lesson .Bookmarks-Counter {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin: 0px 20px;
  color: #010316;
  border: 2px solid #c9d0d5;
  border-radius: 50%;
  flex-shrink: 0;
  font-size: 14px;
}

.Bookmarks-Lesson-Container .Bookmarks-Lesson i {
  margin-right: 10px;
}

.Bookmarks-Content .Bookmarks-Lesson-Container .Bookmarks-Section-Link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Bookmarks-Content .Bookmarks-Lesson-Container .Bookmarks-Section-Link:hover {
  text-decoration: underline;
}

.Bookmarks-Content .Bookmarks-Lesson-Container .Bookmarks-Section-Link i {
  font-weight: 600;
  margin-left: 20px;
}

.Bookmarks-Lesson-Container .Bookmarks-Toq-Container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 30px;
  margin-left: 75px;
  max-width: calc(1024px - 60px);
}

.Bookmarks-Lesson-Container .Bookmarks-Toq-Container .Bookmarks-Toq h5 {
  font-weight: bold;
  margin-bottom: 10px;
}

.Bookmarks-Lesson-Container .Bookmarks-Toq-Container .Bookmarks-Section-Container {
  margin-bottom: 10px;
}

.Bookmark-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Bookmark-Container .Bookmark-Title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(100vw - 300px);
  width: 100%;
  padding: 20px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(25, 60, 90, 0.25);
}

.Bookmark-Container .Bookmark-Title .Bookmark-Back {
  margin-bottom: 10px;
}

.Bookmark-Container .Bookmark-Content {
  display: flex;
  max-width: calc(100vw - 300px);
  width: 100%;
  background-color: #fff;
}

.Bookmark-Container .ButtonBox {
  background-color: transparent;
  display: inline-block;
  border: 2px solid #193c5a;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: .7rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 3px;
  color: #193c5a;
  margin-bottom: 30px;
}

.Bookmark-Container .ButtonBox p {
  font-weight: bold;
}

.Bookmark-Container .ButtonBox:hover {
  color: #fff;
  background-color: #193c5a;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

@media only screen and (max-width: 768px) {
  .Bookmarks-Container .Bookmarks-Title {
    max-width: 90vw;
  }

  .Bookmarks-Content .Bookmarks-Lesson-Container {
    max-width: 90vw;
    font-size: 14px;
    padding: 20px 0;
  }

  .Bookmark-Container .Bookmark-Title,
  .Bookmark-Container .Bookmark-Content {
    max-width: 90vw;
  }
}

@media only screen and (max-width: 320px) {
  .Bookmark-Content .FlashCard-NavBar {
    width: 290px;
  }
}
