@import 'src/Style/Style.scss';

.NetPromoterScore-Container {
  .modal {
    .modal-content {
      width: 50%;

      .modal-header {
        @include FlexSpaceBetween;
        border-bottom: 2px solid $lightest-gray;
        margin-bottom: 20px;
        padding-bottom: 10px;

        .Heading {
          @include TextProps(22px, bold, $bluish-black);
        }
      }

      .modal-body {
        .Success {
          margin-top: 30px;

          .Icon {
            @include FlexCenter;
            margin-bottom: 10px;
            color: $green;
          }

          .Response {
            @include FlexCenter;
            @include TextProps(23px, bold, $bluish-black);
            margin-bottom: 10px;
          }
        }

        .Form {
          .Error {
            @include TextProps(16px, normal, $red);
          }

          .Description {
            @include FlexCenter;
            @include TextProps(18px, normal, $bluish-black);
          }

          .Slider-Section {
            @include FlexCenter;
            height: 100px;
            padding: 20px;

            .Label {
              @include TextProps(20px, normal, $bluish-black);
              margin: 0 20px;
            }

            .Legend-Bar {
              height: 6px;
              width: 100%;
              background: $light-green;
              border-radius: 10px;

              .Pointer {
                height: 30px;
                width: 30px;
                background-color: $lightest-gray;
                border-radius: 50%;
                border: 10px solid #708A9D;

                .ToolTip {
                  @include TextProps(14px, normal, $white);
                  @include FlexCenter;
                  position: absolute;
                  top: -43px;
                  left: -10px;
                  height: 30px;
                  width: 30px;
                  background-color: $light-green;
                  border-radius: 5px;
                }
              }
            }
          }

          .Feedback-Section {
            border: 2px solid $light-gray;
            border-radius: 5px;
            padding: 10px;

            .Feedback {
              @include TextProps(17px, normal, $bluish-black);
              @include FullHW;
              min-height: 100px;
              resize: vertical;
            }
          }


          .Actions {
            @include FlexProps(center, flex-end);

            .Submit-Button {
              color: $white;
              margin-top: 20px;

              &:disabled {
                @include DisableBtn;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .NetPromoterScore-Container {
    .modal {
      .modal-content {
        width: 90%;

        .modal-body {
          .Form {
            .Slider-Section {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
