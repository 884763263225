@import 'src/Style/Style.scss';

.SearchUsers-Container {
  .Loader {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .Title {
    color: $bluish-black;
    margin: 20px 0;
  }

  .Filters-Box {
    @include FlexColumn;
    background: $white;
    border: 1px solid $light-gray;
    padding: 20px;
    margin-bottom: 20px;

    .Search {
      @include FlexProps(center, flex-start);
      margin: 10px;

      .Label {
        color: $gray;
        margin-right: 10px;
      }

      .Input {
        height: 35px;
        width: 300px;
        border: 1px solid $light-gray;
        border-radius: 4px;
        padding: 0 10px;
      }
    }

    .Tabs {
      @include FlexProps(center, flex-start);
      flex-wrap: wrap;
      width: 100%;
      margin: 10px;

      .Label {
        color: $gray;
        margin-right: 10px;
      }

      .Tab {
        @include FlexCenter;
        @include TextProps(18px, bold, $bluish-black);
        height: auto;
        width: 100px;
        border: 1px solid $light-gray;
        border-radius: 4px;
        margin: 3px;
        padding: 3px;
        cursor: pointer;

        &.Active {
          background-color: $light-green;
          border-color: $light-green;
          color: white;
        }
      }
    }
  }

  .Content-Container {
    background: $white;
    border: 1px solid $light-gray;
    padding: 20px;

    table {
      width: 100%;

      tr {
        border: 1px solid $light-gray;

        td {
          padding: 15px;
          border: none;
        }
      }
    }
  }
}
