@import 'src/Style/Style.scss';

.Pagination-Container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 28px;
  width: 100%;
  margin: 10px 0;

  .Previous, .Number, .Next {
    display: flex;

    .Item {
      @include FlexCenter;
      @include TextProps(14px, normal, $bluish_black);
      height: 28px;
      background-color: transparent;
      margin: 0 3px;
      padding: 0 10px;
      cursor: pointer;

      .Icon {
        font-size: 10px;
        margin: 0 5px;
      }
    }
  }

  .Previous, .Next {
    .Item.Active {
      color: $green;
    }

    .Item.Inactive {
      color: #cacaca;
    }
  }

  .Number {
    .Item.Active {
      color: $white;
      background-color: $green;
    }
  }
}
