.Admin-Dashboard {
  display: flex;
  margin-top: 75px;
}

.Admin-Dashboard-Main {
  flex: 1 1;
}

.AdminDashboard-Pages {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AdminDashboard-Manage {
  margin: 30px 0;
  width: 80%;
}

.AdminDashboard-Title {
  padding: 10px;
  border-bottom: 2px solid #3d6280;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AdminDashboard-Title-mini {
  margin: 30px;
  padding: 10px;
  border-bottom: 1px solid #3d6280;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AdminDashboard-InventoryList {
  display: flex;
  width: 100%;
  margin: 30px;
  padding: 10px;
}

.AdminDashboard-InventoryList .Inventory-List {
  display: flex;
  align-items: flex-end;
  width: 100%;
  grid-column-gap: 20px;
}

.AdminDashboard-InventoryList .Inventory-List select {
  font-size: 14px;
  border: 1px solid black;
}

.AdminDashboard-InventoryList .Inventory-List button {
  width: auto;
}

.AdminDashboard-InventoryList .Inventory-List button:disabled {
  background-color: #bfbfbf;
}

.AdminDashboard-CoursePackage-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 20px;
  justify-items: center;
}

.AdminDashboard-InfoBox {
  margin: 30px;
  display: flex;
  align-items: baseline;
}

.AdminDashboard-CourseList {
  margin: 30px;
  display: flex;
  flex-direction: column;
}

.Institution-List, .License-List {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .Admin-Dashboard {
  }
}
