@import 'src/Style/Style.scss';

.LeaderBoard-Container {
  .modal {
    .modal-content {
      .modal-header {
        @include FlexCenter;
        height: 70px;

        .Modal-Title {
          @include FlexCenter;
          @include FullHW;
          position: relative;

          .Leaderboard-Banner {
            position: absolute;
            top: -5px;
            width: 400px;
            height: 100px;
          }

          .Title {
            @include FlexCenter;
            @include TextProps(25px, bold, $white);
            text-transform: uppercase;
            letter-spacing: 0.4px;
            z-index: 999;
          }
        }
      }

      .modal-body {
        @include FlexCenter;
        flex-direction: column;
        margin: 30px 0;

        .LeaderBoard-Tabs {
          @include FlexCenter;
          height: 40px;
          width: 300px;
          background-color: $white;
          border: 1px solid $light-green;
          border-radius: 4px;
          margin-bottom: 20px;
          cursor: pointer;

          .Tab {
            @include FlexCenter;
            @include TextProps(14px, bold, $bluish-black);
            height: 100%;
            min-width: 50%;
            text-transform: capitalize;
          }

          .Active {
            background: #6bd1be80;
          }
        }

        .No-Record,
        .Not-Enough-Optin {
          @include TextProps(17px, normal, $light-silver);
          background-color: $lightest-gray;
          border-radius: 5px;
          padding: 15px;
        }

        .Leaderboard-Table {
          display: flex;
          width: 100%;
          max-height: 500px;
          overflow-y: auto;

          table {
            width: 100%;
            border: 1px solid #183c5b26;
            border-collapse: inherit;
            border-radius: 8px;

            thead {
              tr {
                background-color: #183c5b1a;

                th {
                  @include TextProps(16px, bold, $bluish-black);
                  height: 64px;
                  background: transparent;
                  border: none;
                  border-right: 1px solid #183c5b26;
                  text-align: center;
                  vertical-align: middle;
                  padding: 0 25px;
                }

                th:last-of-type {
                  border-right: none;
                }
              }
            }

            tbody {
              .CurrentUser-Row {
                background: $sky-blue;
              }

              tr {
                background-color: $white;

                td {
                  @include TextProps(16px, normal, $bluish-black);
                  height: 64px;
                  background: transparent;
                  border: none;
                  border-top: 1px solid #183c5b26;
                  border-right: 1px solid #183c5b26;
                  text-align: center;
                  vertical-align: middle;
                  padding: 0 24px;
                }

                .tl {
                  text-align: left;
                }

                td:last-of-type {
                  border-right: none;
                }
              }
            }
          }
        }
      }

      .modal-footer {
        @include FlexCenter;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .LeaderBoard-Container {
    .modal-content {
      width: 90%;

      .modal-body {
        .Content-Holder {
          margin: 30px 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .LeaderBoard-Container {
    .modal {
      .modal-content {
        width: 80%;

        .modal-header {
          .Modal-Title {
            .Leaderboard-Banner {
              width: 300px;
            }
          }
        }
      }
    }
  }
}
