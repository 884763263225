.QuestionBox-Position {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  background-color: #a2daea;
}

.QuestionBox-Question-Bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin: 0 0 0 20px;
}

.QuestionBox-Position-correct {
  background-color: #7de3bb;
}

.QuestionBox-Position-wrong {
  background-color: #D47e7e;
}

@media only screen and (max-width: 768px) {
  .QuestionBox-Position {
    width: 35px;
    height: 35px;
  }
}
