@import 'src/Style/Style.scss';

.B2B-Admin-Side-Bar {
  @include FlexColumn;
  min-height: calc(100vh - 79px);
  width: 330px;
  min-width: 330px;
  color: $white;
  background-color: rgba(24, 60, 91, 0.18);

  .B2B-Admin-Side-Bar-List {
    .Dashboard-360 {
      height: auto;
      margin: 57px 0px 38px 25px;
    }

    .B2B-Admin-Side-Bar-Item {
      display: flex;
      margin-left: 30px;
      align-items: center;
      padding: 10px 0 10px 12px;

      .B2B-Admin-Side-Bar-Image {
        @include IconHW;
        margin: 0 10px 0 10px;
      }

      span {
        color: $bluish-black;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      &:hover {
        background-color: #e7ebed;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }
    }

    .B2B-Admin-Side-Bar-Item-Selected {
      background-color: #e7ebed;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }

    .Professional-Overlay {
      padding: 20px 0;
      position: relative;
      border: 2px solid #3949ab99;
      background-color: $light-purple;

      .Verticaltext {
        p {
          top: 95px;
          left: -70px;
          color: #183c5b80;
          font-size: 14px;
          font-weight: bold;
          position: absolute;
          transform: rotate(-90deg);
        }
      }
    }

    .Home-Overlay {
      padding: 25px 0 10px;
    }

    .Clinical-Overlay {
      padding: 20px 0;
      position: relative;
      margin-bottom: 75px;
      border: 2px solid #546e7a;
      border-top: none;
      background-color: rgba(24, 60, 91, 0.2);

      .Verticaltext {
        p {
        top: 125px;
        left: -55px;
        font-size: 14px;
        font-weight: bold;
        position: absolute;
        transform: rotate(-90deg);
        color: rgba(24, 60, 91, 0.5);
        }
      }
    }

    .Sidebar-ButtonBox {
      width: 250px;
      height: 50px;
      display: block;
      margin: 25px auto;
      border-radius: 30px;
      border: 1px solid $bluish-black;
      background-color: transparent;

      p {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 2px;
        color: $bluish-black;
        text-transform: uppercase;
      }

      &.Resource-Button {
        background-color: $bluish-black;

        p {
          color: $white;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .B2B-Admin-Side-Bar {
    position: absolute;
    width: 285px;
    min-width: 285px;
    left: -285px;
    backdrop-filter: blur(10px);
    z-index: 10;
    transition: left 0.5s;

    .SideBar-Collapse-Button {
      position: absolute;
      top: -32px;
      right: -32px;

      i {
        color: $white;
      }
    }
  }

  .B2B-Admin-Side-Bar-Show {
    position: absolute;
    left: 0;
  }
}
