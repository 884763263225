@import 'src/Style/Style.scss';

.Indolence {
  .modal-content {
    min-height: 250px;
    width: 600px;

    .Title {
      @include FlexProps(center, flex-start);
      height: 50px;
      width: 100%;
      border-bottom: 1px solid $light-gray;

      .Text {
        @include TextProps(20px, bold, $bluish-black);
      }
    }

    .Content {
      @include FlexProps(center, flex-start);
      height: 100px;
      margin: 20px 0;

      .Text {
        @include TextProps(16px, normal, $bluish-black);
        line-height: 23px;
      }
    }

    .Actions {
      @include FlexProps(center, flex-end);

      .Stay, .Leave {
        margin: 0 3px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Indolence {
    .modal-content {
      width: 90%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .Indolence {
    .modal-content {
      width: 80%;

      .Actions {
        flex-direction: column;

        .Stay, .Leave {
          width: 100%;
          margin: 3px 0;
        }
      }
    }
  }
}
