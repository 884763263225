@import 'src/Style/Style.scss';

.Onboarding {
  .Process {
    @include FlexCenter;
    @include FullHW;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #193c5a54;
    z-index: 99;
    backdrop-filter: blur(5px);

    .Loader {
      @include FlexCenter;
      @include FlexColumn;
      min-height: 200px;
      min-width: 300px;
      background-color: #0000004d;
      border-radius: 20px;

      .Message {
        font-family: 'LATO';
        font-size: 25px;
        font-weight: bold;
        color: $white;
        margin-bottom: 10px;
      }
    }

    .Password-Modal.modal {
      background-color: transparent;

      .modal-content {
        max-width: 630px;
        border: none;
        border-radius: 5px;
        padding: 40px;

        .Form {
          @include FlexColumn;

          .Head {
            @include FlexColumn;
            margin-bottom: 30px;

            .Image {
              height: 48px;
              width: 48px;
              margin-bottom: 10px;
            }

            .Title {
              margin-bottom: 12px;
            }

            .Desc {
              @include TextProp;
              font-size: 16px;
            }
          }

          .Info {
            @include FlexColumn;
            margin-bottom: 20px;

            .Label {
              font-size: 14px;
              color: #193c5acc;
              margin-bottom: 8px;
            }

            .Value {
              @include TextProp;
              font-size: 16px;
            }
          }

          .Group {
            @include FlexColumn;
            margin-bottom: 20px;

            label {
              @include TextProp;
              font-size: 14px;
              margin-bottom: 6px;
            }

            input {
              @include TextProp;
              font-size: 16px;
              height: 41px;
              width: auto;
              background-color: #f8fcfd;
              border: 1px solid $light-green;
              border-radius: 4px;
              padding: 0 16px;
            }

            .Error {
              color: $red;
              border-color: $red;

              &:focus {
                color: $bluish-black;
              }
            }
          }

          .Note {
            font-family: 'LATO';
            font-size: 14px;
            color: $red;
            margin-bottom: 20px;
          }

          .Submit {
            display: flex;
            justify-content: center;

            .Btn {
              height: 47px;
              width: 432px;
              background-color: #183d5b;
              border: none;
              border-radius: 4px;

              &:disabled {
                @include DisableBtn;
              }

              p {
                font-family: 'LATO';
                font-weight: bold;
                color: $white;
              }
            }
          }
        }
      }
    }

    .Success-Modal.modal {
      background-color: transparent;

      .modal-content {
        max-width: 630px;
        border: none;
        border-radius: 5px;
        padding: 40px;

        .Success {
          @include FlexColumn;

          .Logo i {
            @include FlexCenter;
            font-size: 27px;
            height: 48px;
            width: 48px;
            color: $white;
            background-color: $light-green;
            border-radius: 50%;
            margin-bottom: 10px;
          }

          .Heading p {
            @include TextProp;
            font-size: 24px;
            margin-bottom: 24px;
          }

          .Desc p {
            @include TextProp;
            font-size: 16px;
            margin-bottom: 50px;

            a {
              text-decoration: underline;
              font-weight: bold;
            }
          }

          .Close {
            @include FlexCenter;

            .Btn {
              @include FlexCenter;
              height: 47px;
              width: 432px;
              background: none;
              border: 1px solid #183d5b;
              border-radius: 4px;

              p {
                @include TextProp;
                font-size: 16px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Onboarding {
    .Process {
      .Password-Modal.modal, .Success-Modal.modal {
        .modal-content {
          width: 80%;
        }
      }
    }
  }
}
