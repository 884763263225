@import 'src/Style/Style.scss';

.PledgeModal-Container {
  .modal {
    .Modal-Content {
      @include FlexColumn;
      height: auto;
      width: 50%;
      background-color: $white;
      border: 1px solid #183d5b2e;
      border-radius: 4px;
      padding: 44px 87px 71px;

      .Modal-Icon {
        height: 52px;
        width: 52px;
      }

      .Heading {
        @include TextProps(24px, bold, $bluish-black);
        margin: 21px 0;
      }

      .Sub-Heading {
        @include TextProps(18px, normal, $bluish-black);
        line-height: 27px;
        margin-bottom: 43px;
      }

      .Cancel-Link {
        @include TextProps(18px, normal, $bluish-black);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .PledgeModal-Container {
    .modal {
      .Modal-Content {
        width: 80%;
        padding: 20px;
      }
    }
  }
}
