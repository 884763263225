@import 'src/Style/Style.scss';
@import 'src/Style/Basic.scss';

.Title-Bar {
  padding: 18px 25px;
  background-color: $lightest-gray;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: rgba(1, 1, 1, 0.1) 1px solid;
  border-bottom: none;

  h3 {
    font-size: 17px;
  }
}

.Canopy-Image {
  height: 60px;
}

.Admin-Dashboard-Professional-Development-Stats {
  margin-bottom: 30px;

  .Content {
    background: $white;
    padding: 25px;
    margin-bottom: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: rgba(1, 1, 1, 0.1) 1px solid;
  }

  .Content {
    display: flex;

    .Card {
      @include FlexColumn;
      flex: 1 1;
      text-align: center;
    }
  }


  .Combo-Badge-Holder {
    @include FlexColumn;
    margin: 10px 0;
    align-items: center;

    .Text-Heading {
      @include TextProps(12px, bold, #38756a);
      height: 17px;
      width: 152px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    .Shape {
      @include FlexCenter;
      position: relative;
      height: 137px;
      width: 142px;

      .Image {
        @include FullHW;
      }

      .Inner-Text {
        @include FlexCenter;
        @include FullHW;
        @include Absolute0;
        flex-direction: column;

        .Digit {
          @include TextProps(20px, bold, #183c5b);
          @include FlexCenter;
          margin-bottom: 3px;
          letter-spacing: 1px;
        }

        .Text {
          @include TextProps(12px, none, $bluish-black);
          @include FlexCenter;
          letter-spacing: 0.4px;
          text-transform: uppercase;
        }
      }
    }

    .Total-Progress {
      @include TextProps(12px, none, #183c5b80);
      @include FlexCenter;
      margin: 7px 0;
    }
  }
}

.Admin-Dashboard-Pledge-Status {
  margin-bottom: 30px;

  .Floating-Buttons {
    position: absolute;

    .Toggle-Button {
      @include TextProps(16px, none, $bluish-black);
      display: inline-block;
      padding: 15px 30px;
      border: rgb(165, 213, 201) 1px solid;
      cursor: pointer;

      &.Enabled {
        background-color: rgb(201, 230, 223);
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &:not(:last-child) {
        border-right: none;
      }
    }
  }

  .Content {
    display: flex;

    .Chart {
      flex: 1 1;
    }
  }
}

.Admin-Dashboard-Product-User-Access {

}
