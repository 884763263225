.FillInTheBlankBoxMini-QuestionParts {
  margin: 0 3px;
}

.QuestionMini-QuestionBox-Question input {
  margin: 0 7px;
  border: none;
  border-bottom: 1px solid #7fc3d5;
  color: #183D5B;
  font-size: 18px;
  text-align: center;
  min-width: 80px;
  max-width: 600px;
}
