@import 'src/Style/Style.scss';

.Password.AuthPage {
  justify-content: center;
  height: 100vh;
}

.Password-Container {
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin: 20px auto !important;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px;
  background-color: white;

  .Heading {
    @include FlexCenter;
    flex-direction: column;
    background-color: #6bd1be;
    border-radius: 4px;
    margin: 20px 0;
    padding: 10px;

    p {
      font-size: 28px;
      font-weight: bold;
      color: #fff;
      font-family: 'LATO';
    }
  }

  .Subheading {
    font-size: 16px;
    color: #193c5a;
    font-family: 'LATO';
    margin: 20px 0;
  }

  .Success {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #6bd1be;
    color: #fff;
    border-radius: 5px;
    min-height: 50px;
    margin-bottom: 10px;
    padding: 10px;

    i {
      margin-bottom: 10px;
      font-size: 35px;
    }

    p {
      font-family: 'LATO';
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
  }

  .Danger {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #df4759;
    color: #fff;
    border-radius: 5px;
    min-height: 50px;
    margin-bottom: 10px;
  }

  .Form {
    display: flex;
    flex-direction: column;

    .Input {
      font-family: 'LATO';
      font-size: 16px;
      height: 40px;
      width: 100%;
      color: #193c5a;
      background-color: #f8fcfd;
      border: 1px solid #7ec2d4;
      border-radius: 4px;
      margin-bottom: 20px;
      padding: 0 8px;
    }

    .Submit {
      font-family: 'LATO';
      font-size: 16px;
      height: 47px;
      width: 100%;
      color: #fff;
      background-color: #183d5b;
      border: none;
      border-radius: 4px;
      text-transform: uppercase;

      &:disabled {
        background-color: #bfbfbf;
        cursor: not-allowed;
      }
    }
  }
}
