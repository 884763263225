@import 'src/Style/Style.scss';

.StatStar-Container {
  @include FlexCenter;
  flex-direction: column;
  height: auto;
  min-width: 150px;
  margin: 10px;

  .Stat {
    @include FlexCenter;
    @include TextProps(16px, bold, $darkest-green);
    margin: 0 auto 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
  }

  .Star {
    @include FlexCenter;
    position: relative;
    height: 120px;
    width: 130px;

    .Image {
      @include FullHW;
      @include Absolute0;
    }

    .Value {
      @include FlexCenter;
      @include FullHW;
      @include Absolute0;
      @include TextProps(24px, bold, $bluish-black);
      flex-direction: column;
      letter-spacing: 1px;
    }

    .Info {
      @include FlexCenter;
      @include FullHW;
      @include Absolute0;
      @include TextProps(14px, normal, $bluish-black);
      top: 15px;
    }

    &.With-Info {
      .Value {
        top: -15px;
      }
    }
  }
}
