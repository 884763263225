@import 'src/Style/Style.scss';

.Information-Modal {
  .modal {
    .modal-content {
      min-height: 200px;

      .modal-header {
        @include FlexCenter;
        border-bottom: 1px solid $light-gray;
        padding: 10px;

        .Title {
          @include TextProps(25px, normal, $bluish-black);
          text-transform: uppercase;
        }
      }

      .modal-body {
        margin: 40px 0;

        .Descriptions-Container {
          .Description {
            @include FlexCenter;
            @include TextProps(17px, normal, $bluish-black);
            margin: 8px 0;
            text-align: center;
            line-height: 23px;
          }
        }

        .Instructions-Container {
          background-color: $lightest-gray;
          border-color: #d3d6d8;
          border-radius: 5px;
          padding: 10px;

          .Instructions {
            .Instruction {
              @include TextProps(16px, normal, $bluish-black);
              line-height: 25px;
            }
          }
        }
      }

      .modal-footer {
        @include FlexCenter;

        .Action-Button {
          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Information-Modal {
    .modal {
      .modal-content {
        width: 90%;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .Information-Modal {
    .modal {
      .modal-content {
        width: 80%;

        .modal-body {
          .Instructions-Container {
            .Instructions {
              .Instruction {
                line-height: 25px;
              }
            }
          }
        }
      }
    }
  }
}
