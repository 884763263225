@import 'src/Style/Style.scss';

.Profile-Container {
  @include FlexColumn;

  .Tabs {
    @include FlexSpaceBetween;
    height: 46px;
    width: auto;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: bold;
    color: $white;
    background-color: $light-gray;
    border-radius: 5px;
    margin: 20px 68px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    box-shadow: inset 0px 0px 5px rgba(0,0,0,0.1);

    .Tab {
      @include FlexCenter;
      height: 86%;
      width: 30%;
      font-family: 'LATO';
      font-size: 14px;
      color: $white;
      border-radius: 5px;
      margin: 0 3px;
      cursor: pointer;
      letter-spacing: 0.4px;
      text-transform: uppercase;

      &.active {
        box-shadow: 0px 3px 1px rgba(0,0,0,0.05), 0px 2px 2px rgba(0,0,0,0.1), 0px 3px 3px rgba(0,0,0,0.05);
      }
    }
  }

  .Tab-Content {
    @include FlexCenter;
    margin: 0 68px;
    flex-direction: column;

    .General-Information-Container {
      @include FlexColumn;

      .Sides {
        display: flex;

        .Left-Side {
          @include FlexColumn;
          flex: 50%;
          margin-right: 10px;

          .EditUser {
            width: 100%;
          }
        }

        .Right-Side {
          @include FlexColumn;
          flex: 50%;
        }
      }
    }

    .Language-Background-Container {
      display: flex;
      width: 100%;
    }

    .My-Progress-Container {
      @include FlexColumn;
      width: 100%;

      .Pledges-Container,
      .Subscriptions-Container {
        display: flex;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .Profile-Container {
    .Tabs {
      flex-direction: column;
      height: auto;

      .Tab {
        height: 50px;
        width: 100%;
      }
    }

    .Tab-Content {
      .General-Information-Container {
        .Sides {
          flex-direction: column;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .Profile-Container {
    .Tabs {
      margin: 20px 18px;
    }

    .Tab-Content {
      margin: 0 20px;

      .General-Information-Container {
        .Sides {
          .Left-Side {
            margin-right: 0;
          }
        }
      }

      .My-Progress-Container {
        .Pledges-Container,
        .Subscriptions-Container {
        }
      }
    }
  }
}
