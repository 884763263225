@import 'src/Style/Style.scss';

.My-Progress {
  display: flex;
  width: 100%;

  .ProgressContainer {
    @include FlexColumn;
    width: 100%;
    margin: 10px 0;
    border-radius: 4px;
    background-color: $white;

    .TitleContainer {
      @include FlexSpaceBetween;
      height: 51px;
      padding: 0 20px;
      border-bottom: 1px solid #d8d8d8;

      tbody tr:nth-child(odd) {
        background: #eee;
      }

      .ProgressTitle {
        @include FlexProps(center, space-between);
        width: 100%;

        .Title {
          @include TextProps(14px, bold, $bluish-black);
          letter-spacing: 0.2px;
          text-transform: uppercase;
        }
      }

      .RenewSubs {
        @include TextProps(14px, bold, $white);
        display: flex;
        background-color: $light-green;
        border: none;
        border-radius: 4px;
        padding: 8px 20px;
        letter-spacing: 0.4px;
        text-transform: uppercase;

        .RenewIcon {
          margin-right: 5px;
        }
      }
    }

    .ProgressContent {
      @include FlexColumn;

      .ProgressContent-Holder {
        padding: 20px;
        border-bottom: 1px solid rgba(24, 60, 91, 0.1);

        .Content-Text p {
          @include TextProps(14px, normal, $bluish-black);
          display: flex;
          line-height: 20px;
          letter-spacing: 0.2px;
        }
      }

      .AddLanguage {
        @include FlexCenter;
        height: 243px;

        #Add-Language-ToolTip {
          @include FlexColumn;
          min-width: 150px;
          background-color: $bluish-black;

          p {
            @include FlexCenter;
            @include TextProps(14px, normal, $white);
          }
        }

        .Enable-Pledge-Btn {
          @include BacBorNone;
          width: 416px;
          height: 50px;
          background-color: $white;
          border-radius: 30px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

          p {
            @include FlexCenter;
            @include TextProps(14px, bold, $bluish-black);
            letter-spacing: 0.4px;

            .AddIcon {
              margin-right: 5px;
            }
          }

          &:disabled {
            opacity: 0.5;
          }
        }

        .__react_component_tooltip.show {
          @include FlexCenter;
          justify-content: space-evenly;
          height: 50px;
          opacity: 1;
        }
      }
    }
  }
}

.My-Pledges {
  @include FlexColumn;

  .My-Courses-Pledges, .My-Lingos-Pledges {
    @include FlexColumn;

    .My-Course-Pledges, .My-Lingo-Pledges {
      display: flex;
      height: 240px;
      width: 100%;
      border-bottom: 1px solid $lightest-gray;
      overflow: hidden;

      .Course-Title, .Lingo-Title {
        @include FlexCenter;
        @include TextProps(18px, bold, $dark-gray);
        flex: 0 0 230px;
        border-right: 1px solid $lightest-gray;
        text-align: center;
        letter-spacing: 0.4px;
        text-transform: uppercase;
      }

      .Pledge-Cards {
        display: flex;
        flex: 1;
        width: 200px;
        padding: 20px 10px;
        overflow-x: auto;

        .Pledge-Card {
          @include FlexCenter;
          flex-direction: column;
          height: 193px;
          margin: 0 10px;

          .Title {
            @include FlexCenter;
            @include TextProps(16px, bold, $dark-gray);
            margin-bottom: 14px;

            .Arm-Icon, .Badge-Icon {
              font-size: 24px;
              margin-left: 9px;
            }
          }

          .Card {
            @include FlexColumn;
            justify-content: space-between;
            height: 156px;
            width: 320px;
            border: 1px solid #6bd1be80;
            border-radius: 8px;
            padding: 18px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

            .Details-Container {
              @include FlexColumn;

              .Details {
                @include FlexProps(center, inherit);
                flex-wrap: wrap;
                line-height: 19px;

                .part {
                  @include TextProps(14px, normal, $bluish-black);
                  margin-right: 2px;
                }

                .part.bold {
                  font-weight: bold;
                }

                .Level-Field, .Target-Field {
                  @include TextProps(14px, normal, $bluish-black);
                  min-width: 50px;
                  background-color: $white;
                  border: none;
                  border-bottom: 1px solid $bluish-black;
                  margin: 0 4px;
                }

                .Level-Field.error, .Target-Field.error {
                  border-color: $red;
                }
              }

              .Behind-Target {
                @include FlexProps(center, inherit);
                @include TextProps(14px, normal, $dark-orange);
                letter-spacing: 0.2px;
                margin: 12px 0;

                img {
                  height: 12px;
                  width: 12px;
                  margin-right: 4px;
                }
              }
            }

            .Buttons-Container {
              @include FlexProps(flex-end, space-between);

              .Move-To-In-Progress {
                @include FlexCenter;
                height: 20px;
                width: 75px;
                background-color: $dark-green;
                border: none;
                border-radius: 4px;

                p {
                  @include TextProps(14px, bold, $white);
                }
              }

              .Move-To-Completed {
                @include FlexCenter;
                @include BacBorNone;

                p {
                  @include TextProps(14px, bold, $dark-green);
                }
              }

              .Back-To-Outstanding {
                @include FlexCenter;
                @include BacBorNone;

                p {
                  @include TextProps(14px, normal, #607d8b);
                }
              }

              .Back-To-In-Progress {
                @include FlexCenter;
                @include BacBorNone;

                p {
                  @include TextProps(14px, bold, #607d8b);
                }
              }
            }
          }
        }
      }
    }
  }

  .My-Add-Language {
    @include FlexProps(center, flex-start);
    padding: 25px 35px;

    .Btn {
      @include BacBorNone;

      .Add-Icon {
        height: 19px;
        width: 19px;
        margin-right: 4px;
      }

      p {
        @include FlexCenter;
        @include TextProps(14px, bold, $bluish-black);
        letter-spacing: 0.4px;
      }
    }
  }
}

.Enable-Lingo-Container, .Pledge-Complete-Container {
  .modal-content {
    padding: 0;
    min-height: 170px !important;
    width: 552px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

    .modal-header {
      position: relative;
      padding: 20px;
      border-bottom: 1px solid rgba(24, 60, 91, 0.1);

      h6 {
        @include TextProps(14px, bold, $bluish-black);
        letter-spacing: 0.2px;
        text-transform: uppercase;
      }

      .btn-close {
        button {
          @include BacBorNone;
          position: absolute;
          right: 6px;
          top: 9px;

          &:disabled {
            cursor: not-allowed;
          }

          p {
            color: #e0e0e0;
            font-size: 40px;
            text-decoration: none;
            padding:10px;
          }
        }
      }
    }

    .modal-body {
      padding: 20px;

      .Error-Container {
        ul {
          margin: 0 0 10px 20px;

          li {
            @include TextProps(16px, normal, $red);
          }
        }
      }

      .All-Lingos-Enabled-Text {
        p {
          @include TextProps(16px, normal, $bluish-black);
        }
      }

      .PlegdeText {
        @include TextProps(16px, normal, $bluish-black);
        display: flex;
        flex-wrap: wrap;
        font-style: italic;
        letter-spacing: 0.4px;
        margin-bottom: 20px;

        span {
          margin-right: 3px;
        }
      }

      .select {
        @include TextProps(16px, normal, $bluish-black);
        position: relative;
        min-width: 200px;
        border-radius: 4px;
        display: block;
        margin-bottom: 20px;
        background: none;
        text-decoration: none solid rgb(24, 60, 91);

        select {
          padding: 7px 40px 7px 12px;
          width: 339px;
          border: 1px solid $light-green;
          height: 50px;
          border-radius: 5px;
          background: $white;
          box-shadow: 0 1px 3px -2px #9098a9;
          cursor: pointer;
          font-family: inherit;
          font-size: 16px;
          transition: all 150ms ease;
        }
      }

      .AddLanguageButton,
      .PledgeCompleteButton, {
        min-width: 162px;
        height: 50px;
        border-radius: 4px;
        background-color: $bluish-black;
        margin-top: 40px;
        border: none;
        padding: 0 5px;

        &:disabled {
          @include DisableBtn;
        }

        p {
          @include FlexCenter;
          @include TextProps(14px, bold, $white);
          letter-spacing: 0.4px;
          text-transform: uppercase;

          .Icon-Pledge-Complete {
            height: 27px;
            width: 27px;
            margin-right: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .My-Progress {
    .ProgressContainer {
      .TitleContainer {
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 10px 20px;
      }
    }
  }

  .My-Pledges {
    .My-Courses-Pledges, .My-Lingos-Pledges {
      .My-Course-Pledges, .My-Lingo-Pledges {
        .Course-Title, .Lingo-Title {
          flex: 0 0 150px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .My-Pledges {
    .My-Courses-Pledges, .My-Lingos-Pledges {
      .My-Course-Pledges, .My-Lingo-Pledges {
        flex-direction: column;
        align-items: center;
        height: auto;

        .Course-Title, .Lingo-Title {
          flex: unset;
          height: 30px;
          width: 90%;
          padding: 10px;
          border: none;
        }

        .Pledge-Cards {
          width: 400px;
        }
      }
    }
  }

  .Enable-Lingo-Container, .Pledge-Complete-Container {
    .modal-content {
      width: 90%;

      .modal-body {
        .select {
          select {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .My-Pledges {
    .My-Courses-Pledges, .My-Lingos-Pledges {
      .My-Course-Pledges, .My-Lingo-Pledges {
        .Pledge-Cards {
          width: 350px;

          .Pledge-Card {
            .Card {
              width: 250px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .My-Pledges {
    .My-Courses-Pledges, .My-Lingos-Pledges {
      .My-Course-Pledges, .My-Lingo-Pledges {
        .Pledge-Cards {
          width: 260px;
        }
      }
    }
  }
}
