@import 'src/Style/Style.scss';

.NetPromoterScores-Container {
  margin: 50px;

  .Title {
    @include TextProps(22px, bold, $bluish-black);
    margin-bottom: 10px;
  }

  .Block {
    background-color: $white;
    border-radius: 5px;
    padding-bottom: 10px;

    .Header {
      margin-bottom: 20px;

      .Tab-Holder {
        @include FlexWrap;

        .Tab {
          @include TextProps(18px, normal, $bluish-black);
          @include FlexCenter;
          height: 65px;
          min-width: 228px;
          border-bottom: 2px solid transparent;
          cursor: pointer;
          padding: 0 20px;
        }

        .Tab.Active {
          border-color: #3d6280;
        }
      }
    }

    .Content {
      padding: 0 20px;
    }
  }
}

@media only screen and (max-width: 420px) {
  .NetPromoterScorePage-Container {
    margin: 50px 20px;

    .NetPromoterScore-Block {
      .Header {
        .Tab-Holder {
          .Tab {
            width: 100%;
          }
        }
      }
    }
  }
}
