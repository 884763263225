@import 'src/Style/Style.scss';

.ProfileCompletion {
  @include FlexCenter;
  z-index: 9999;

  .modal-content {
    min-height: 250px;
    margin: 20px auto;

    .ImageBox {
      @include FlexCenter;
      height: auto;
      width: 100%;
      margin-bottom: 10px;

      .Image {
        height: 125px;
        width: 125px;
      }
    }

    .ContentBox {
      @include FlexCenter;
      flex-direction: column;
      margin-bottom: 25px;

      .Content {
        @include FlexColumn;
        width: 100%;

        .Title, .Heading {
          @include TextProps(24px, normal, $bluish-black);
          text-align: center;
          margin-bottom: 10px;
        }

        .Benefits {
          @include FlexColumn;

          .Description {
            @include TextProps(16px, normal, $bluish-black);
            margin: 10px 0 5px;
          }

          .List {
            margin-bottom: 15px;

            li {
              @include TextProps(16px, normal, $bluish-black);
              line-height: 26px;
            }
          }
        }

        .Ask {
          @include TextProps(21px, normal, $bluish-black);
          text-align: center;
          margin-bottom: 20px;
        }

        .ActionBox {
          @include FlexCenter;
          flex-direction: column;

          .Actions {
            @include FlexCenter;

            .Primary-Btn {
              margin: 0 5px;
              padding: 0 10px;
            }
          }

          .Note {
            @include TextProps(16px, normal, $bluish-black);
            margin: 10px 0;
          }
        }
      }

      .Wings {
        @include FlexColumn;

        .Wing {
          @include FlexColumn;
          margin-bottom: 20px;

          .Name {
            @include TextProps(20px, bold, $bluish-black);
            margin-bottom: 10px;
          }

          .Item {
            @include FlexProps(center, flex-start);
            margin-bottom: 10px;

            .Icon {
              @include FlexCenter;
              @include IconHW;
              border-radius: 5px;
              margin-right: 10px;

              .fa {
                font-size: 20px;
                color: $white;
              }
            }

            .Icon.Check {
              background-color: $green;
            }

            .Icon.Spinner {
              background-color: $light-red;
            }

            .Text {
              @include TextProps(16px, normal, $bluish-black);
              width: calc(100% - 34px);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ProfileCompletion {
    .modal-content {
      width: 90%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .ProfileCompletion {
    .modal-content {
      width: 80%;
    }
  }
}
