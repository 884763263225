.Breadcrumbs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  font-size: 18px;
  line-height: 20px;
  margin: 90px 10px 10px;
}

.Breadcrumbs > div:first-child,
.Breadcrumbs .Breadcrumb-Progress {
  display: flex;
  width: 100%;
  max-width: calc(100vw - 300px);
}

.Breadcrumbs .Breadcrumb-Item {
  display: inline-block;
  color: #193c5a;
  opacity: 0.35;
}

.Breadcrumbs .Breadcrumb-Item:hover {
  color: #183D5B;
  opacity: 1;
}

.Breadcrumbs .breadcrumbs__section:last-of-type .Breadcrumb-Item {
  color: #183D5B;
  opacity: 1;
}

.Breadcrumbs .Breadcrumb-Item .Breadcrumb-Item-Title {
  text-transform: uppercase;
}

.Breadcrumbs i {
  margin: 0 10px;
  opacity: 0.35;
}

.Breadcrumbs .Breadcrumb-Progress .ProgressBar-container {
  width: 100%;
  margin-top: 10px;
}

.Breadcrumbs .Breadcrumbs-Heading {
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-top: 69px solid #6bd1be;
  width: 470px;
  height: 0;
  margin: -25px 0 0 0;
  position: relative;
}

.Compliance-Platform .Breadcrumbs .Breadcrumbs-Heading {
  border-top-color:  #2892D7;
}

.Breadcrumbs .Breadcrumbs-Heading .Breadcrumb-Item {
  display: none;
}

.Breadcrumbs .Breadcrumbs-Heading .Breadcrumbs-HeadingMain {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #183d5b;
  position: absolute;
  width: 100%;
  top: -60px;
  margin-top: 15px;
}

.Compliance-Platform .Breadcrumbs .Breadcrumbs-Heading .Breadcrumbs-HeadingMain {
  color: #ffffff;
}

.Breadcrumbs .Breadcrumbs-Heading .Breadcrumbs-HeadingSub {
  font-size: 13px;
  text-align: center;
  color: #183d5b;
  position: absolute;
  width: 100%;
  top: -40px;
}

@media only screen and (min-width : 3000px) and (max-width : 5120px) {
  .Breadcrumbs .Breadcrumbs-Heading .Breadcrumbs-HeadingMain {
    font-size: 24px;
  }
}

@media screen and (min-width: 2300px) {
  .Breadcrumbs .Breadcrumb-Item .Breadcrumb-Item-Title {
    font-size: 14px;
  }

  .Breadcrumbs .Breadcrumb-Item h4 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 768px) {
  .Breadcrumbs {
    width: 80%;
  }

  .Breadcrumbs > div:first-child,
  .Breadcrumbs .Breadcrumb-Progress {
    max-width: 95vw;
  }

  .Breadcrumbs * {
    font-size: 17px;
    height: auto;
  }

  .Breadcrumbs .Breadcrumb-Item {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .Breadcrumbs {
    width: 70%;
  }

  .Breadcrumbs * {
    height: auto;
    font-size: 13px;
  }

  .Breadcrumbs .Breadcrumb-Item {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .Breadcrumbs .Breadcrumbs-Heading {
    width: 80%;
  }
}
