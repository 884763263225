@import 'src/Style/Style.scss';

.User-Preference-Form {
  @include FlexColumn;
  margin-bottom: 5px;

  .Error {
    @include FlexColumn;
    margin: 0 0 10px;
    padding: 0 0 0 20px;

    li {
      @include TextProps(14px, normal, $red);
    }
  }

  .Preference-Item {
    @include FlexProps(center, flex-start);

    .Description {
      @include TextProps(16px, normal, $bluish-black);
      display: flex;
      width: 100%;
      margin-left: 10px;
    }
  }
}
