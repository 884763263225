@import 'src/Style/Style.scss';

.CourseProgressImport-Container {
  .modal {
    .modal-content {
      .Head {
        @include FlexProps(center, space-between);
        width: 100%;
        margin-bottom: 20px;
      }

      .Body {
        @include FlexColumn;
        width: 100%;

        .Success {
          @include FlexCenter;
          flex-direction: column;

          .Icon {
            color: $green;
          }

          .Heading {
            @include TextProps(24px, normal, $bluish-black);
            margin: 10px 0;
          }

          .Text {
            @include TextProps(18px, normal, $bluish-black);
          }
        }

        .Form {
          .Information, .Warning {
            @include FlexProps(center, flex-start);
            border: 1px solid;
            border-radius: 5px;
            padding: 10px;
            margin-bottom: 20px;

            .Icon {
              margin-right: 20px;
            }

            .Text {
              @include TextProps(16px, bold, $bluish-black);
            }
          }

          .Information {
            border-color: $blue;

            .Icon, .Text {
              color: $blue;
            }
          }

          .Warning {
            border-color: $blood-red;

            .Icon, .Text {
              color: $blood-red;
            }
          }

          .Horizontal-Rule {
            margin: 20px 0;
          }

          .Import-Action, .Decline-Action {
            @include FlexProps(center, space-between);

            .Text {
              @include TextProps(18px, normal, $bluish-black);
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .CourseProgressImport-Container {
    .modal {
      .modal-content {
        width: 90%;

        .Body {
          .Form {
            .Import-Action, .Decline-Action {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .CourseProgressImport-Container {
    .modal {
      .modal-content {
        width: 80%;
      }
    }
  }
}
