.LearningPage {
  max-width: calc(100vw - 300px);
  margin: 0 auto;
  padding-top: 20px;
}

.LearningPage-Backdrop {
  min-height: calc(100vh - 177px);
}

.LearningPage-Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border: 2px solid #183d5b2e;
  border-radius: 4px;
  margin: 20px 0;
  padding: 40px;
}

.LearningPage-Content.Empty {
  display: none;
}

.LearningPage-AudioBox {
  padding-bottom: 30px;
}

.LearningPage-VideoBox video {
  max-height: 100%;
  max-width: 100%;
}

.LearningPage-TextBox {
  padding: 15px;
  width: calc(100% - 30px);
}

.LearningPage-LessonAnswerButtons_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.LearningPage-Instructions {
  width: 100%;
  color: #183D5B;
  background-color: #fff;
  border: 2px solid #183d5b2e;
  border-radius: 4px;
  margin: 20px 0;
  padding: 30px 60px;
}

.LearningPage .ql-editor,
.LearningPage .ql-editor p {
  margin: auto;
}

.LearningPage .ql-editor img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  overflow: hidden;
}

@media only screen and (min-width : 3000px) and (max-width : 5120px) {
  .LearningPage-Instructions {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .LearningPage {
    max-width: 876px;
  }
}

@media only screen and (max-width: 900px) {
  .LearningPage-Content {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .LearningPage {
    max-width: 95vw;
  }

  .LearningPage-Content {
    width: 100%;
  }

  .LearningPage-Content.drag_and_drop,
  .LearningPage-Content.sorting,
  .LearningPage-Content.matching {
    margin-bottom: 180px;
  }

  .LearningPage-BasicContent {
    margin: 0 30px;
  }

  .LearningPage-SingleContent {
    margin: 0 30px;
  }

  .LearningPage-Backdrop {
    min-height: calc(100vh - 192px);
  }

  .LearningPage-Instructions {
    padding: 20px 15px;
    width: 100%;
  }

  .LearningPage .ql-editor,
  .LearningPage .ql-editor p,
  .LearningPage p,
  .LearningPage h5 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .LearningPage-Content {
    width: 100%;
    padding: 20px;
  }
}
