@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
	font-family: 'Lato', sans-serif;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 18px;
}

.App .Load-App {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: rgb(231, 235, 237);
}

.App .Load-App .Message {
  font-family: 'Lato';
  font-size: 25px;
  font-weight: bold;
  color: #193c5a;
  margin-bottom: 10px;
}

.Backdrop-white {
	width: 100%;
	min-height: 100vh;
	background-color: white;
}

.Backdrop-grey {
	width: 100%;
	min-height: 100vh;
	background-color: rgb(231, 235, 237);
}

.hidden {
	display: none;
}

a {
	color: inherit;
	text-decoration: none;
}

input, textarea {
	outline: none;
	box-sizing: border-box;
}

textarea {
	resize: none;
}

button {
	outline: none;
	cursor: pointer;
	font-size: 16px;
}

select {
	outline: none;
}

strong {
  font-weight: bold;
  font-family: sans-serif;
}

em {
  font-style: italic;
}

u {
  text-decoration: underline;
}

h1 {
	font-size: 36px;
	margin: 0px;
	color: #193c5a;
	line-height: 1.25em;
	font-weight: bold;
}

h2 {
  font-size: 24px;
  margin: 0px;
  line-height: 1.25em;
  font-weight: normal;
  color: #193c5a;
}

h3 {
  font-size: 20px;
  margin: 0px;
  line-height: 1.25em;
  font-weight: normal;
  color: #193c5a;
}

h4 {
  font-size: 18px;
  line-height: 1.25em;
  margin: 0px;
  font-weight: normal;
}

h5 {
  font-size: 16px;
  line-height: 1.25em;
  margin: 0px;
  font-weight: normal;
}

h6 {
  font-size: 8px;
  line-height: 1.25em;
  margin: 0px;
  font-weight: bold;
}

p {
  font-size: 18px;
  line-height: 1.25em;
  margin: 0px;
  font-weight: normal;
}

li {
  font-size: 18px;
  line-height: 1.25em;
}

button p {
	font-size: 16px;
	line-height: 1.25em;
}

.w-100 {
  width: 100%;
}

.mt-100 {
	margin-top: 100px;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ttn {
  text-transform: none !important;
}

.ttc {
  text-transform: capitalize;
}

.tdu {
  text-decoration: underline;
}

.Primary-Btn {
	height: 46px !important;
	min-width: 160px !important;
	border: 1px solid #193c5a !important;
	border-radius: 3px !important;
	background-color: #193c5a !important;
	transition: 0.25s;
	padding: 0 10px;
}

.Primary-Btn .Primary-Btn-Text {
	color: #ffffff !important;
	font-size: 16px !important;
	font-weight: bold !important;
	font-family: 'LATO' !important;
}

.Primary-Btn:hover {
	transform: translateY(-0.25em);
	box-shadow: 0 3px 7px 0 #41474c94;
}

.Static-Primary-Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  min-width: 200px;
  color: white;
  background-color: #193c5a;
  border: 1px solid #193c5a;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0 10px;
}

.Static-Primary-Btn p {
  font-family: 'LATO';
  font-weight: bold;
  font-size: 16px;
  color: white;
  letter-spacing: 1px;
}

.Secondary-Btn {
	height: 46px !important;
	min-width: 160px !important;
	border: 1px solid #193c5a !important;
	border-radius: 3px !important;
	background-color: #ffffff !important;
	transition: 0.25s;
	padding: 0 10px;
}

.Secondary-Btn .Secondary-Btn-Text {
	color: #193c5a !important;
	font-size: 16px !important;
	font-weight: bold !important;
	font-family: 'LATO' !important;
}

.Secondary-Btn .Secondary-Btn-Text i {
	margin: 0 7px;
}

.Secondary-Btn:hover {
	background-color: #193c5a !important;
	transform: translateY(-0.25em);
	box-shadow: 0 3px 7px 0 #41474c94;
}

.Secondary-Btn:hover .Secondary-Btn-Text {
	color: #ffffff !important;
}

.Green-Btn {
	height: 58px !important;
	min-width: 258px !important;
	border: 1px solid #183d5b !important;
	border-radius: 4px !important;
	background-color: #fff !important;
	transition: 0.25s;
}

.Green-Btn .Green-Btn-Text {
	color: #183d5b !important;
	font-size: 20px !important;
	font-family: 'LATO' !important;
}

.Green-Btn:hover {
	background-color: #6bd1be !important;
	transform: translateY(-0.25em);
	color: #fff !important;
}

.Green-Btn:hover .Green-Btn-Text {
	color: #fff !important;
}

.Normal-Btn {
	height: 45px !important;
	/*min-width: 198px !important;*/
	border: 1px solid #7ec2d4 !important;
	border-radius: 4px !important;
	color: #183d5b !important;
	background-color: #f8fcfd !important;
	background-size: cover !important;
	font-size: 12px !important;
	font-weight: normal !important;
	font-family: 'LATO' !important;
	text-decoration: none solid rgb(24, 61, 91);
	letter-spacing: 0.5px;
	text-transform: uppercase;
	text-align: center;
}

.Alert-Btn {
  height: 46px !important;
  min-width: 160px !important;
  border: 1px solid #ff0000 !important;
  border-radius: 3px !important;
  background-color: #e0524a !important;
  transition: 0.25s;
  padding: 0 10px;
}

.Alert-Btn .Alert-Btn-Text {
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: 'LATO' !important;
}

.Alert-Btn:hover {
  transform: translateY(-0.25em);
  box-shadow: 0 3px 7px 0 #41474c94;
}

.ql-editor {
	height: 100% !important;
	width: 100% !important;
}

.ql-editor-inline {
  display: inline-block;
  width: auto !important;
}

.Required-Asterisk {
  font-size: 20px;
  color: #ff0000;
  margin-left: 5px;
  height: 10px;
}

.Flash-Message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  font-family: 'LATO';
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  margin: 10px 0;
}

.Flash-Message.Success {
  background-color: #57bdaa;
}

.Flash-Message.Info {
  background-color: #347cff;
}

.Flash-Message.Danger {
  background-color: #ff0000;
}

.PhoneInput {
  width: 293px;
}

.PhoneInput .PhoneInputCountry {
  background-color: rgba(126, 194, 212, .1);
  border: 1px solid #7fc3d5;
  border-right: none;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 0 10px 0 25px;
  margin-right: 0;
}

.PhoneInput .PhoneInputInput {
  height: 40px;
  font-size: 13px;
  background-color: rgba(126, 194, 212, .1);
  color: #183d5b;
  border: 1px solid #7fc3d5;
  border-left: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0;
}

@media only screen and (min-width : 3000px) and (max-width : 5120px) {
  h1 {
    font-size: 46px !important;
  }

  h2 {
    font-size: 40px !important;
  }

  h3 {
    font-size: 34px !important;
  }

  h4 {
    font-size: 30px !important;
  }

  h5 {
    font-size: 28px !important;
  }

  h6 {
    font-size: 20px !important;
  }

  p {
    font-size: 30px !important;
  }

  .Primary-Btn .Primary-Btn-Text {
  	font-size: 24px !important;
  }
}

@media screen and (min-width: 2300px) {
  h1 {
    font-size: 46px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 34px;
  }

  h4 {
    font-size: 30px;
  }

  h5 {
    font-size: 22px;
  }

  h6 {
    font-size: 20px;
  }

  p {
    font-size: 24px;
  }

  .Primary-Btn .Primary-Btn-Text {
  	font-size: 20px;
  }
}

@media only screen and (max-width: 1500px) {
  .Responsive-To-Screen {
    width: 930px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .Responsive-To-Screen {
    width: 830px !important;
  }
}

@media only screen and (max-width: 1300px) {
  .Responsive-To-Screen {
    width: 730px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .Responsive-To-Screen {
    width: 630px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .Responsive-To-Screen {
    width: 530px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .Responsive-To-Screen {
    width: 430px !important;
  }
}

@media only screen and (max-width: 900px) {
  .Responsive-To-Screen {
    width: 330px !important;
  }
}

@media only screen and (max-width: 800px) {
  .Responsive-To-Screen {
    width: 290px !important;
  }
}

@media only screen and (max-width: 768px) {
  .Responsive-To-Screen {
    width: 620px !important;
  }

  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 34px;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 14px;
  }

  p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 700px) {
  .Responsive-To-Screen {
    width: 520px !important;
  }
}

@media only screen and (max-width: 600px) {
  .Responsive-To-Screen {
    width: 420px !important;
  }
}

@media only screen and (max-width: 500px) {
  .Responsive-To-Screen {
    width: 370px !important;
  }
}

@media only screen and (max-width: 480px) {
  .Responsive-To-Screen {
    width: 320px !important;
  }

  h1 {
      font-size: 32px;
  }
}

@media only screen and (max-width: 400px) {
  .Responsive-To-Screen {
    width: 250px !important;
  }
}

::-webkit-scrollbar-track
{
  background-color: #F0F0F0;
  border-radius: 5px;
}

::-webkit-scrollbar
{
  width: 5px;
  height: 5px;
  background-color: #F0F0F0;
}

::-webkit-scrollbar-thumb
{
  border-radius: 5px;
  background-color: #7f7f7f;
}
