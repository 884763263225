@import 'src/Style/Style.scss';

.Score-Card-Congratulation {
  .Congratulation-Heading {
    @include TextProps(24px, bold, $bluish-black);
    letter-spacing: 0.2px;
    text-transform: none;
    margin-bottom: 8px;
    z-index: 999;
    white-space: normal;

    @media print {
      .Congratulation-Heading {
        @include TextProps(24px, bold, $bluish-black);
        letter-spacing: 0.2px;
        text-transform: none;
        margin-bottom: 8px;
        z-index: 999;
      }
    }
  }

  .Congratulation-Body {
    @include FlexProps(center, space-between);
    position: relative;
    border: 2px solid $app-background;
    border-radius: 8px;
    padding: 16px 32px;
    margin-bottom: 32px;
    z-index: 1;

    @media print {
      @include FlexProps(center, space-between);
      position: relative;
      border: 2px solid $app-background;
      border-radius: 8px;
      padding: 16px 32px;
      margin-bottom: 32px;
      z-index: 1;
    }

    .Text-Container {
      display: flex;
      flex: 0 70%;
      white-space: normal;

      @media print {
        display: flex;
        flex: 0 70%;
      }

      .congrats-text {
        position: relative;

        @media print {
          position: relative;
        }

        p {
          @include TextProps(18px, normal, $bluish-black);
          letter-spacing: 0.2px;
          margin-bottom: 16px;
          line-height: 1.5;
        }

        ul {
          list-style: disc;
          color: $bluish-black;
          padding-left: 40px;
          margin-bottom: 16px;

          li {
            p {
              @include TextProps(18px, normal, $bluish-black);
              line-height: 24px;
              letter-spacing: 0.2px;
              margin-bottom: 0;

              a {
                color: $bluish-black;
                text-decoration: underline;
              }
            }
          }
        }

        .Disclaimer-Paragraph {
          font-size: 16px;
          margin-bottom: 0;
        }

        .canopy-caption {
          @include TextProps(13.6px, normal, $bluish-black);
        }
      }

      .Star-Watermark {
        position: relative;
        top: 25px;
        left: -53px;
        opacity: .6;

        @include mobile {
          display: none;
        }

        @media print {
          display: inherit;
        }
      }

      #watermark-badge {
        position: absolute;
        width: 300px;
        height: 300px;
        text-align: center;
        background: #e0f5f1;
        border-radius: 5px;
      }

      #watermark-badge:before,
      #watermark-badge:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 300px;
        width: 300px;
        background: #e0f5f1;
        border-radius: 5px;
      }

      #watermark-badge:before {
        transform: rotate(30deg);
      }

      #watermark-badge:after {
        transform: rotate(60deg);
      }

      #inset-watermark-badge {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 280px;
        height: 280px;
        text-align: center;
        background: $white;
        border-radius: 4px;
      }

      #inset-watermark-badge:before,
      #inset-watermark-badge:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 280px;
        width: 280px;
        background: white;
        border-radius: 4px;
      }

      #inset-watermark-badge:before {
        transform: rotate(30deg);
      }

      #inset-watermark-badge:after {
        transform: rotate(60deg);
      }
    }

    .Certificate-Ribbon {
      @include FlexColumn;
      position: absolute;
      top: -45px;
      left: auto;
      right: -45px;

      .Image {
        height: 230px;

        @media print {
          height: 220px;
          width: 320px;
        }
      }

      @include mobile {
        display: none;
      }

      @media print {
        @include FlexColumn;
        top: -40px;
        left: auto;
        right: -10px;
      }

      .ribbon-text {
        @include FlexColumn;
        position: absolute;
        left: 0;
        right: 0;
        top: 73px;
        align-items: center;
        line-height: 38px;
        margin: auto;

        @media print {
          position: absolute;
          display: flex;
          left: 0;
          right: 0;
        }

        p {
          @include TextProps(12px, bold, #452506);
          font-family: Merriweather;
          text-transform: uppercase;
          letter-spacing: 0.2px;
          margin-bottom: 20px;

          @media print {
            font-size: 11px;
          }
        }

        .id-number {
          @include TextProps(26px, bold, #452506);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Score-Card-Congratulation {
    .Congratulation-Body {
      .Text-Container {
        flex: 0 100%;
      }

      .Certificate-Ribbon, .Star-Watermark {
        display: none;
      }
    }
  }
}
