.FillInTheBlankBox {
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 10px 5px;
  position: relative;
}

.FillInTheBlankContainer {
  width: 100%;
}

.FillInTheBlankContainer > div:nth-child(odd){
  background-color: #fafafa;
}

.FillInTheBlankBox-QuestionBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
}

.FillInTheBlankBox-QuestionBox-input {
  margin: 7px;
  border: none;
  border-bottom: 2px solid #6bd1be;
  color: #183D5B;
  font-size: 22px;
  text-align: center;
  min-width: 120px;
  max-width: 900px;
  background-color: transparent;
  padding: 2px 5px;
}

.FillInTheBlankBox-QuestionBox h5 {
  margin: 0 5px;
}

.FillInTheBlankBox-QuestionBox h6 {
  margin: 0 3px;
  min-height: 35px;
  display: flex;
  align-items: center;
}

.FillInTheBlankBox-QuestionBox-input-small {
  margin: 5px;
  border: none;
  border-bottom: 2px solid #6bd1be;
  color: #183D5B;
  font-size: 18px;
  text-align: center;
  min-width: 80px;
  max-width: 500px;
  padding: 2px 5px;
}

.FillInTheBlankBox-QuestionBox-Position {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin: 0 0 0 20px;
  color: #c9d0d5;
  border: 2px solid #c9d0d5;
  border-radius: 50%;
  flex-shrink: 0;
}

.icon-correct {
  color: #7de3bb
}

.icon-wrong {
  color: #D47e7e;
  width: 16px;
}

.Questions-FillInTheBlankBox-Hint {
  display: flex;
  width: 100%;
}

.Pin-Unanswered {
  width: 45px;
  height: 34px;
  color: #fff;
  background-color: #ff8f00;
  border-radius: 3px;
  position: absolute;
  top: 15px;
  left: -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

@media only screen and (max-width: 1400px) {
  .FillInTheBlankBox-QuestionBox-input {
    max-width: 550px;
  }

  .FillInTheBlankBox-QuestionBox-input-small {
    max-width: 400px;
  }
}

@media only screen and (max-width: 768px) {
  .FillInTheBlankBox-QuestionBox {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    max-width: calc(100% - 40px);
  }

  .FillInTheBlankBox-QuestionBox h5 {
    margin: 0 3px;
  }

  .FillInTheBlankBox-QuestionBox-input {
    font-size: 20px;
    min-width: 80px;
    max-width: 300px;
  }

  .FillInTheBlankBox-QuestionBox-input-small {
    max-width: 200px;
  }

  .FillInTheBlankBox-QuestionBox h6 {
    align-items: flex-end;
  }

  .FillInTheBlankBox-QuestionBox-Position {
    margin: 0 0 0 5px;
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 450px) {
  .FillInTheBlankBox-QuestionBox-input,
  .FillInTheBlankBox-QuestionBox-input-small {
    max-width: 150px;
  }
}
