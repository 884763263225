.CreationForms-RadioBox {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: 10px 0;
}

.CreationForms-RadioBox input {
  margin-right: 5px;
}
