@import 'src/Style/Style.scss';

.UserRosterUploadProgress-Container {
  .modal {
    z-index: 999999;

    .modal-content {
      width: 70%;
      height: 75%;
      border-radius: 4px;

      .modal-header {
        @include FlexProps(center, flex-end);
        margin-bottom: 20px;
        height: 50px;

        .Close-Button {
          height: 100%;
          width: 50px;
          transition: .5s;

          &:hover {
            background-color: $lightest-gray;
            border-radius: 5px;
          }
        }
      }

      .modal-body {
        @include FlexColumn;

        .Progress-Message {
          @include FlexCenter;
          @include TextProps(21px, bold, $bluish-black);
          @include LightenBackground($light-gray, 17%);
          min-height: 50px;
          width: 100%;
          border-radius: 4px;
          padding: 10px;
          margin-bottom: 20px;

          &.error {
            color: white;
            background-color: $light-red;
          }

          &.success {
            color: white;
            background-color: $light-green;
          }
        }

        .Tables-Container {
          @include FlexProps(flex-start, center);
          flex-wrap: wrap;

          .Table-Container {
            @include FlexColumn;
            flex: 1 1 30%;
            margin: 5px;

            .Header {
              @include FlexCenter;
              @include TextProps(16px, bold, $bluish_black);
              height: 50px;
              width: 100%;
              background-color: #f2f8fa;
              border: 1px solid $light-gray;
              padding: 0 16px;
            }

            .Table {
              width: 100%;
              max-height: 300px;
              border: none;
              overflow-y: auto;

              table {
                @include FullHW;

                tbody {
                  tr {
                    min-height: 50px;

                    td {
                      @include TextProps(14px, bold, $bluish_black);
                      border: 1px solid $lightest-gray;
                      text-align: left;
                      vertical-align: middle;
                      padding: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .UserRosterUploadProgress-Container {
    .modal {
      .modal-content {
        height: 90%;
        width: 90%;
      }
    }
  }
}
