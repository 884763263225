@import 'src/Style/Style.scss';

.Study-Deck-Play-Container {
  .Player {
    .modal {
      .modal-content {
        @include FullHW;

        .modal-header {
          @include FlexSpaceBetween;

          .Title {
            @include TextProps(22px, bold, $bluish-black);
          }
        }

        .modal-body {
          display: flex;
          margin-top: 50px;

          .Autoplaying {
            visibility: hidden;
          }

          .Study-Cards {
            @include FlexCenter;
            flex-direction: column;
            width: 100%;

            .Control-Buttons {
              @include FlexProps(center, space-between);
              flex-wrap: wrap;
              gap: 10px;

              .ButtonBox-FlashCard {
                border: 1px solid $light-green !important;
                transition: 0.3s;

                p, span {
                  @include TextProps(16px, normal, $bluish-black);
                }

                i {
                  color: $bluish-black;
                  margin-left: 5px;
                }

                &:hover {
                  @include GrowScaleWithShadow;
                  background-color: $light-green !important;
                }
              }
            }

            .Study-Card {
              margin: 30px 0;

              .flip-card {
                height: 315px;
                width: 602px;
                background-color: $white;
                perspective: 1000px;
                border: 2px solid $light-green;
                border-radius: 4px;
                box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);

                .flip-card-inner {
                  @include FullHW;
                  position: relative;
                  text-align: center;
                  transition: transform 0.8s;
                  transform-style: preserve-3d;

                  .flip-card-front {
                    @include FlexCenter;
                    @include FullHW;
                    flex-direction: column;
                    background-color: $white;
                    position: absolute;
                    backface-visibility: hidden;
                  }

                  .flip-card-back {
                    @include FlexCenter;
                    @include FullHW;
                    flex-direction: column;
                    background-color: $light-green;
                    position: absolute;
                    backface-visibility: hidden;
                    transform: rotateY(180deg);
                  }

                  .flip-card-front-buttons {
                    @include FlexProps(center, flex-end);
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 10px;
                    backface-visibility: hidden;

                    .Card-Flip-Btn {
                      border: 2px solid $light-green;

                      i {
                        color: $light-green;
                        font-size: 28px;
                        margin-top: 2px;
                      }

                      &:hover {
                        @include GrowScaleWithShadow;
                        background-color: $light-green;

                        i {
                          color: $white;
                        }
                      }
                    }
                  }

                  .flip-card-front-text {
                    @include FlexProps(flex-start, center);
                    height: 60%;
                    width: 90%;
                    overflow-y: auto;

                    h1 {
                      font-size: 26px;
                      margin: auto;
                    }

                    .Result {
                      @include FlexProps(center, space-around);
                      flex-direction: column;
                      @include FullHW;

                      h4 {
                        @include TextProps(18px, normal, $bluish-black);
                      }

                      .Happy {
                        color: $light-green;
                      }

                      .Sad {
                        color: $red;
                      }
                    }
                  }
                }
              }

              .Cards-Progress {
                display: flex;
                height: 5px;
                width: 100%;
                background-color: $lightest-gray;
                border-radius: 5px;
                @include BoxShadow;
                margin-top: 3px;

                .Cards-Progress-Filler {
                  display: flex;
                  height: 100%;
                  background-color: $light-green;
                  transition: width 2s;
                  border-radius: 4px;
                }
              }
            }

            .Study-Card-Navigation {
              @include FlexProps(flex-start, space-evenly);
              width: 500px;
              margin-bottom: 30px;

              .Navigation-Left {
                @include FlexColumn;

                .Previous-Card-Btn {
                  height: 50px;
                  width: 50px;
                  border: 1px solid $light-green !important;

                  i {
                    color: $bluish-black;
                    font-size: 20px;
                    transform: scale(-1);
                  }

                  &:hover {
                    @include GrowScaleWithShadow;
                    background-color: $light-green !important;
                  }
                }

                .Review-Btn {
                  height: 48px;
                  width: 170px;
                  background-color: #f3807a;
                  border: 2px solid #ff3a30;
                  border-radius: 8px;

                  p {
                    @include TextProps(16px, normal, $white);
                    text-transform: uppercase;
                    letter-spacing: 0.36px;
                  }
                }

                .Review-Count {
                  @include FlexCenter;
                  margin-top: 10px;

                  .Count {
                    @include FlexCenter;
                    @include TextProps(24px, normal, #9e9e9e);
                    position: relative;
                    height: 53px;
                    width: 48px;
                    background-color: $white;
                    border: 2px solid #d0d0d0;
                    border-radius: 12px;
                    line-height: 24px;
                    text-decoration: none solid rgba(158, 158, 158);

                    &:before {
                      content: '';
                      height: 5px;
                      width: 5px;
                      background-color: $white;
                      transform: rotate(45deg);
                      position: absolute;
                      top: -5px;
                      border: 2px solid $white;
                      border-top-color: #d0d0d0;
                      border-left-color: #d0d0d0;
                    }
                  }
                }
              }

              .Navigation-Middle {
                display: flex;

                .Number {
                  @include TextProps(20px, normal, $bluish-black);
                  margin-top: 10px;
                  padding: 5px;
                  background-color: $white;
                  transition: background-color 0.5s ease;

                  &.Highlight {
                    background-color: $yellow;
                  }
                }

                .ButtonBox-FlashCard {
                  &:hover {
                    @include GrowScaleWithShadow;
                    background-color: $light-green !important;
                  }
                }
              }

              .Navigation-Right {
                @include FlexColumn;

                .Next-Card-Btn {
                  height: 50px;
                  width: 50px;
                  border: 1px solid $light-green !important;

                  i {
                    color: $bluish-black;
                    font-size: 20px;
                  }

                  &:hover {
                    @include GrowScaleWithShadow;
                    background-color: $light-green !important;
                  }
                }

                .Got-Btn {
                  height: 48px;
                  width: 170px;
                  background-color: #69d2b7;
                  border: 2px solid #4accac;
                  border-radius: 8px;

                  p {
                    @include TextProps(16px, normal, $white);
                    text-transform: uppercase;
                    letter-spacing: 0.36px;
                  }
                }

                .Got-Count {
                  @include FlexCenter;
                  margin-top: 10px;

                  .Count {
                    @include FlexCenter;
                    @include TextProps(24px, normal, #9e9e9e);
                    position: relative;
                    height: 53px;
                    width: 48px;
                    background-color: $white;
                    border: 2px solid #d0d0d0;
                    border-radius: 12px;
                    line-height: 24px;
                    text-decoration: none solid rgba(158, 158, 158);

                    &:before {
                      content: '';
                      height: 5px;
                      width: 5px;
                      background-color: $white;
                      transform: rotate(45deg);
                      position: absolute;
                      top: -5px;
                      border: 2px solid $white;
                      border-top-color: #d0d0d0;
                      border-left-color: #d0d0d0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Study-Deck-Play-Container {
    .Player {
      .modal {
        .modal-content {
          .modal-body {
            .Study-Cards {
              .Study-Card {
                width: 100%;

                .flip-card {
                  width: 100%;

                  .ButtonBox-FlashCard-icon  {
                    width: 40px;
                    height: 40px;
                  }
                }
              }

              .Study-Card-Navigation {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
              }
            }
          }
        }
      }
    }
  }
}
