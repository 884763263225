.Footer {
  background-color: #669dac;
  width: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.Footer h5 {
  color: #f0f0f0;
  font-size: 12px;
}
