@import 'src/Style/Style.scss';

.Quiz-Cant-Load {
  @include FlexCenter;
  max-width: calc(100vw - 300px);
  flex-direction: column;
  background-color: $green;
  border-radius: 4px;
  margin: 0 auto;
  padding: 30px 0;

  .Message {
    @include TextProps(25px, bold, $white);
    margin-bottom: 30px;
  }
}

#Answered-All-Error-Container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

#Answered-All-Error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  background-color: #ff0000cc;
  width: 100%;
  color: white;
  font-weight: bold;
  border-radius: 4px;
}
