@import 'src/Style/Style.scss';

.Credential-Dashboard {
  @include FlexColumn;
  margin-top: 32px;

  .ButtonHolder {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 65px;
  }

  .Dynamic-Width {
    overflow-x: auto;
  }

  .Link {
    width: fit-content;

    .Credential-Btn {
      width: 280px;
    }
  }

  .Latest-Assessment-Container {
    @include FlexColumn;
    margin: 30px 60px;

    .Title {
      font-weight: bold;
      color: #183c5b;
      margin-bottom: 24px;
    }

    .__react_component_tooltip.place-bottom {
      width: 350px;
      text-align: justify;
    }

    .CardHolder {
      display: flex;
      width: fit-content;
      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

      .Content-Container {
        display: flex;
        padding: 30px;
      }

      .BoxHolder {
        @include FlexColumn;
        position: relative;

        .Heading {
          @include TextProps(12px, bold, $bluish-black);
          margin-bottom: 22px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }


        .Arrow-Icon {
          height: 103px;
          width: 240px;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);
        }

        .Content {
          @include FlexColumn;
          @include FlexCenter;
          position: absolute;
          top: 35px;
          left: 0;
          right: 0;
          height: 103px;

          .Icon {
            height: 56px;
            width: 56px;
          }

          .Sub-Heading {
            @include TextProps(16px, normal, $bluish-black);
          }
        }
      }
    }
  }

  .My-Progress-Container {
    @include FlexColumn;
    margin: 30px 60px;

    .Title {
      font-weight: bold;
      color: #183c5b;
      margin-bottom: 24px;
    }
    
    .CardHolder {
      @include FlexColumn;
      width: fit-content;
      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

      .Content-Container {
        padding: 30px;
      }

      .AssessmentTable {
        width: 100%;
        max-width: 100%;
        border: 1px solid #183c5b26;
        border-collapse: inherit;
        border-radius: 8px;

        thead {
          tr {
            background-color: #183c5b08;

            th {
              @include TextProps(16px, bold, $bluish-black);
              height: 64px;
              border: none;
              border-right: 1px solid #183c5b26;
              background: transparent;
              padding: 0 24px;
              text-align: left;
              vertical-align: middle;
            }

            th:last-of-type {
              border-right: none;
            }
          }
        }

        tbody {
          tr:nth-child(odd) {
            background-color: $white;
          }

          tr:nth-child(even) {
            background-color: #183c5b1a;
          }

          tr {
            td {
              @include TextProps(14px, normal, $bluish-black);
              height: 64px;
              background-color: transparent;
              border: none;
              border-top: 1px solid #183c5b26;
              border-right: 1px solid #183c5b26;
              padding: 0 24px;
              text-align: left;
              vertical-align: middle;

              a {
                @include TextProps(18px, normal, $blue);
              }
            }

            td:last-of-type {
              border-right: none;
            }
          }


          tr.Pending,
          tr.Waived {
            td {
              font-style: italic;
            }

            td:first-of-type {
              font-style: normal;
            }
          }
        }
      }
    }
  }
}
