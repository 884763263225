@import 'src/Style/Style.scss';

.FlashCard {
  @include FlexProps(center, space-between);
  flex-direction: column;

  .Control-Buttons {
    @include FlexProps(center, space-between);
    flex-wrap: wrap;
    max-width: inherit;

    .ButtonBox-FlashCard {
      margin: 0 5px;
      border: 1px solid $light-green !important;
      transition: 0.3s;

      p, span {
        @include TextProps(16px, normal, $bluish-black);
      }

      i {
        color: $bluish-black;
        margin-left: 5px;
      }

      &:hover {
        @include GrowScaleWithShadow;
        background-color: $light-green !important;
      }
    }
  }

  .FlashCard-Cards {
    margin: 30px 0;

    .flip-card {
      width: 602px;
      height: 315px;
      background-color: $white;
      perspective: 1000px;
      border: 2px solid $light-green;
      border-radius: 4px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);

      .flip-card-inner {
        @include FullHW;
        position: relative;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;

        .flip-card-front {
          @include FlexCenter;
          @include FullHW;
          flex-direction: column;
          background-color: $white;
          position: absolute;
          backface-visibility: hidden;
        }

        .flip-card-back {
          @include FlexCenter;
          @include FullHW;
          flex-direction: row;
          background-color: $light-green;
          position: absolute;
          backface-visibility: hidden;
          transform: rotateY(180deg);
        }

        .flip-card-front-buttons {
          @include FlexProps(center, space-between);
          padding: 10px;
          position: absolute;
          top: 0;
          left: 0;
          width: 582px;
          backface-visibility: hidden;

          .Card-Flip-Btn {
            border: 2px solid $light-green;

            i {
              color: $light-green;
              font-size: 28px;
              margin-top: 2px;
            }

            &:hover {
              @include GrowScaleWithShadow;
              background-color: $light-green;

              i {
                color: $white;
              }
            }
          }
        }

        .flip-card-front-text {
          @include FlexProps(flex-start, center);
          height: 60%;
          width: 90%;
          overflow-y: auto;

          h1 {
            font-size: 26px;
            margin: auto;
          }

          .Result {
            @include FlexProps(center, space-around);
            flex-direction: column;
            @include FullHW;

            h4 {
              @include TextProps(18px, normal, $bluish-black);
            }

            .Happy {
              color: $light-green;
            }

            .Sad {
              color: $red;
            }
          }
        }
      }
    }

    .Cards-Progress {
      display: flex;
      height: 5px;
      width: 100%;
      background-color: $lightest-gray;
      border-radius: 5px;
      @include BoxShadow;
      margin-top: 3px;

      .Cards-Progress-Filler {
        display: flex;
        height: 100%;
        background-color: $light-green;
        transition: width 2s;
        border-radius: 4px;
      }
    }
  }

  .FlashCard-NavBar {
    @include FlexProps(flex-start, space-evenly);
    width: 500px;
    margin-bottom: 30px;

    .FlashCard-NavBar-Left {
      @include FlexColumn;

      .Previous-Card-Btn {
        height: 50px;
        width: 50px;
        border: 1px solid $light-green !important;

        i {
          color: $bluish-black;
          font-size: 20px;
          transform: scale(-1);
        }

        &:hover {
          @include GrowScaleWithShadow;
          background-color: $light-green !important;
        }
      }

      .Review-Btn {
        height: 48px;
        width: 170px;
        background-color: #f3807a;
        border: 2px solid #ff3a30;
        border-radius: 8px;

        p {
          @include TextProps(16px, normal, $white);
          text-transform: uppercase;
          letter-spacing: 0.36px;
        }
      }

      .Review-Count {
        @include FlexCenter;
        margin-top: 10px;

        .Count {
          @include FlexCenter;
          @include TextProps(24px, normal, #9e9e9e);
          position: relative;
          height: 53px;
          width: 48px;
          background-color: $white;
          border: 2px solid #d0d0d0;
          border-radius: 12px;
          line-height: 24px;
          text-decoration: none solid rgba(158, 158, 158);

          &:before {
            content: '';
            height: 5px;
            width: 5px;
            background-color: $white;
            transform: rotate(45deg);
            position: absolute;
            top: -5px;
            border: 2px solid $white;
            border-top-color: #d0d0d0;
            border-left-color: #d0d0d0;
          }
        }
      }
    }

    .FlashCard-NavBar-Middle {
      display: flex;

      .FlashCard-Number {
        @include TextProps(20px, normal, $bluish-black);
        margin-top: 10px;
        padding: 5px;
        background-color: $white;
        transition: background-color 0.5s ease;

        &.Highlight {
          background-color: $yellow;
        }
      }

      .ButtonBox-FlashCard {
        &:hover {
          @include GrowScaleWithShadow;
          background-color: $light-green !important;
        }
      }
    }

    .FlashCard-NavBar-Right {
      @include FlexColumn;

      .Next-Card-Btn {
        height: 50px;
        width: 50px;
        border: 1px solid $light-green !important;

        i {
          color: $bluish-black;
          font-size: 20px;
        }

        &:hover {
          @include GrowScaleWithShadow;
          background-color: $light-green !important;
        }
      }

      .Got-Btn {
        height: 48px;
        width: 170px;
        background-color: #69d2b7;
        border: 2px solid #4accac;
        border-radius: 8px;

        p {
          @include TextProps(16px, normal, $white);
          text-transform: uppercase;
          letter-spacing: 0.36px;
        }
      }

      .Got-Count {
        @include FlexCenter;
        margin-top: 10px;

        .Count {
          @include FlexCenter;
          @include TextProps(24px, normal, #9e9e9e);
          position: relative;
          height: 53px;
          width: 48px;
          background-color: $white;
          border: 2px solid #d0d0d0;
          border-radius: 12px;
          line-height: 24px;
          text-decoration: none solid rgba(158, 158, 158);

          &:before {
            content: '';
            height: 5px;
            width: 5px;
            background-color: $white;
            transform: rotate(45deg);
            position: absolute;
            top: -5px;
            border: 2px solid $white;
            border-top-color: #d0d0d0;
            border-left-color: #d0d0d0;
          }
        }
      }
    }
  }

  .Autoplaying {
    visibility: hidden;
  }
}

@media only screen and (max-width: 768px) {
  .FlashCard {
    width: 100%;

    .Control-Buttons {
      width: 100%;
      justify-content: center;

      .ButtonBox-FlashCard {
        margin: 5px;

        p, span {
          font-size: 14px;
        }
      }
    }

    .FlashCard-Cards {
      width: 100%;
      margin: 15px 0;

      .flip-card {
        width: 100%;
        height: 300px;

        .flip-card-inner {
          .flip-card-front-buttons {
            width: 100%;

            .Card-Flip-Btn {
              i {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .FlashCard {
    .FlashCard-NavBar {
      width: 100%;
      align-items: center;
      flex-direction: column;

      .FlashCard-NavBar-Middle {
        .FlashCard-Number {
          margin-top: 0;
        }
      }

      .FlashCard-NavBar-Left, .FlashCard-NavBar-Right {
        @include FlexCenter;
        flex-direction: row;

        .Review-Count, .Got-Count {
          margin-top: 0;
          margin-left: 5px;

          .Count:before {
            top: 23px;
            left: -5px;
            transform: rotate(-41deg);
          }
        }
      }
    }
  }
}
