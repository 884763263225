@import 'src/Style/Style.scss';

.Switch {
  position: relative;
  display: inline-block;
  height: 34px;
  width: 60px;
  margin-right: 10px;

  input {
    opacity: 0;
    height: 0;
    width: 0;

    &:checked + .Slider {
      background-color: $green;

      &:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }

    &:focus + .Slider {
      box-shadow: 0 0 1px $green;
    }
  }

  .Slider {
    @include Absolute0;
    width: 60px;
    cursor: pointer;
    background-color: $light-gray;
    border-radius: 34px;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      border-radius: 50%;
      background-color: $white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
}
