@import 'src/Style/Style.scss';

.AlertPopup {
  .modal-content {
    min-height: 250px;
    min-width: 500px;
    width: auto;
    padding: 25px;
    border-radius: 5px;
    border: none;

    .Head {
      @include FlexProps(center, flex-end);
      width: 100%;
      margin-bottom: 20px;

      .Close-Btn {
        cursor: pointer;
      }
    }

    .Body {
      @include FlexColumn;
      width: 100%;

      .Icon {
        @include FlexCenter;

        &.Success-Icon {
          color: $green;
        }

        &.Error-Icon {
          color: $red;
        }

        &.Info-Icon {
          color: $blue;
        }
      }

      .Title {
        @include FlexCenter;
        @include TextProps(23px, bold, $bluish-black);
        margin: 15px 0;
      }

      .Messages {
        @include FlexColumn;
        width: 100%;

        .Message {
          @include FlexCenter;
          @include TextProps(16px, bold, $bluish-black);
          margin: 3px 1px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .AlertPopup {
    .modal-content {
      min-width: 90%;
    }
  }
}
