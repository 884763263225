.ErrorsFlashBox-Message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ErrorsFlashBox-Error, .ErrorsFlashBox-Success {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  min-width: 60%;
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 3px;
}

.ErrorsFlashBox-Error {
  background-color: #D47e7e;
}

.ErrorsFlashBox-Success {
  background-color: #7de3bb;
}
