.AdminForm {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.AdminForm-DataBox {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  max-width: 600px;
  margin: 30px 50px 50px 50px;
  overflow-wrap: break-word;
}

.AdminForm-DataBox-title {
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AdminForm-DataBox-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.AdminForm-DataBox-InputBox-container {
  width: 100%;
  margin-bottom: 30px;
}

.AdminForm-DataBox-InputBox {
  width: 100%;
}

.AdminForm-Error {
  background-color: #D47e7e;
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 3px;
}

.AdminForm-DataBox-splitdiv {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

@media only screen and (max-width: 768px) {
  .AdminForm{
    height: calc(100% - 100px);
  }

  .AdminForm-ImageBox {
    display: none;
  }

  .AdminForm-DataBox {
    width: 90%;
    max-width: 500px;
    align-items: center;
    height: 100%;
  }

  .AdminForm-DataBox-title {
    margin: 60px 0 30px 0;
    align-items: center;
  }
}