@import 'src/Style/Style.scss';

.MultiButtonChoiceBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.MultiButtonChoiceContainer {
  width: 100%;
}

.MultiButtonChoiceContainer > div:nth-child(odd){
  background-color: #fafafa;
}

.MultiButtonChoiceBox-QuestionBox {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.MultiButtonChoiceBox-ButtonBox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 130px);
  margin: 0 65px;
}

.MultiButtonAnswerStack {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: initial;
  margin: 0 65px;

  .MultiButtonChoiceBox-ButtonBox-div {
    width: 100%;

    .TrueOrFalseBox-Button, .TrueOrFalseBox-Button__selected {
      min-height: 60px;
      width: 100%;

      p {
        line-height: 1.5rem;
      }
    }
  }
}

.MultiButtonChoiceBox-ButtonBox-div {
  margin: 15px 0;
  display: flex;
  flex: 0 50%;
}

.Pin-Unanswered {
  width: 45px;
  height: 34px;
  color: #fff;
  background-color: #ff8f00;
  border-radius: 3px;
  position: absolute;
  top: 15px;
  left: -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

@media only screen and (max-width:  900px) {
  .MultiButtonChoiceBox-ButtonBox-div {
    flex: 0 100%;
  }
}

@media only screen and (max-width: 768px) {
  .MultiButtonChoiceBox-ButtonBox-container {
    flex-direction: column;
    width: calc(100% - 20px);
    margin: 0 10px;
  }
}
