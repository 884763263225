@import '~highcharts/css/highcharts';
@import 'src/Style/Style.scss';

.LineChart {
  .highcharts-container {
    .highcharts-line-series {
      .highcharts-graph {
        stroke: #183c5b;
        stroke-width: 1px;
        opacity: 0.2;
      }
    }
  }
}
