@import 'src/Style/Style.scss';

.Score-Card-Header {
  @include FlexSpaceBetween;
  flex-wrap: wrap;

  .Header-Logo {
    .Image {
      height: 42px;

      @media print {
        margin-bottom: 20px;
      }
    }
  }

  .Header-Title {
    h4 {
      @include TextProps(36px, bold, $bluish-black);
      letter-spacing: 0.8px;
      text-transform: uppercase;
    }

    @media print {
      margin-bottom: 100px;
    }
  }

  .Header-Info {
    letter-spacing: 0.4px;

    .Info {
      @include FlexProps(center, flex-end);
      @include TextProps(16px, normal, $bluish-black);
      margin-bottom: 16px;

      .Heading {
        margin-right: 16px;
      }

      .Text {
        @include TextProps(16px, bold, $bluish-black);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Score-Card-Header {
    .Header-Info {
      @include FlexColumn;
      align-items: baseline;
    }
  }
}
