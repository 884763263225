.DropDownBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.DropDownContainer {
  width: 100%;
}

.DropDownContainer > div:nth-child(odd){
  background-color: #fafafa;
}

.DropDownBox-QuestionBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}

.DropDownBox-QuestionBox h5{
  margin: 0 5px;
}

.DropDownBox select {
/*  background-color: transparent;
  height: 46px;
  border: 2px solid #7fc3d5;
  font-size: 24px;
  color: #183D5B;
  -webkit-appearance: menulist;
  -moz-appearance: menulist; 
  appearance: menulist;*/

  background-color: #ffffff;
  min-height: 40px;
  width: 181px;
  color: #193c5a;
  font-size: 18px;
  border: 1px solid #6bd1be;
  border-radius: 4px;
  transition: 0.3s;
}

.DropDownBox select:hover {
  background-color: #6bd1be;
  transform: scale(1.03);
  box-shadow: #32325d40 0 13px 27px -5px, #0000004d 0 8px 16px -8px;
  transition: 0.3s;
}

.DropDownBox select::-ms-expand {
  background-color: #7fc3d5;
  color: #183D5B;
}

.Questions-DropDownBox-Hint {
  display: flex;
  width: 100%;
  margin: 2px 0 0 0;
}

.Pin-Unanswered {
  width: 45px;
  height: 34px;
  color: #fff;
  background-color: #ff8f00;
  border-radius: 3px;
  position: absolute;
  top: 15px;
  left: -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

@media only screen and (max-width: 768px) {
  .DropDownBox-QuestionBox h5{
    margin: 0 3px;
  }

  .DropDownBox select {
    height: 35px;
    border: 2px solid #7fc3d5;
    font-size: 20px;
  }
}
