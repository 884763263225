@import 'src/Style/Style.scss';

.Compliance-Dashboard {
  @include FlexColumn;
  margin-top: 32px;

  .ButtonHolder {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 65px;

    .Compliance-Btn {
      width: 280px;
    }
  }

  .Start-Learning-Link {
    width: fit-content;
  }

  .Dynamic-Width {
    overflow-x: auto;
  }

  .Pledges-Container {
    @include FlexColumn;
    margin: 30px 60px;

    .Title {
      font-weight: bold;
      color: #183c5b;
      margin-bottom: 24px;
    }

    .CardHolder {
      @include FlexColumn;
      background: $white;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

      .Tab-Container {
        @include FlexSpaceBetween;
        margin-bottom: 24px;
        overflow-x: auto;

        .Tabs {
          display: flex;

          .Tab {
            @include FlexCenter;
            @include TextProps(18px, normal, $bluish-black);
            height: 65px;
            min-width: 228px;
            border-bottom: 2px solid transparent;
            cursor: pointer;
          }

          .Tab.Active {
            border-color: #3d6280;
          }
        }

        .Link {
          margin-right: 40px;

          .ManagePledgeLink {
            @include TextProps(20px, normal, $blue);
          }
        }
      }

      .AllCharts {
        @include FlexCenter;
        width: 100%;

        .My-Pledge-Chart-Containter {
          display: flex;
          flex: 40%;
          flex-direction: column;
          border-right: 1px solid $light-gray;

          .Badge-Holder {
            display: flex;

            .Completed-Badge, .Progress-Badge {
              @include FlexCenter;
              @include TextProps(14px, normal, $white);
              position: relative;
              height: 51px;
              width: 122px;
              border-radius: 4px;
              margin: 10px;
              padding: 0px 5px;
              text-align: center;
              line-height: 1;

              .Badge {
                position: absolute;
                top: -10px;
                height: 20px;
                width: 20px;
                color: $black;
                border-radius: 10px;
              }

              .Icon-Pledge-Complete {}

              .Badge-Spinner-Icon {
                @include FlexCenter;
                background-color: $white;
              }
            }

            .Completed-Badge {
              background: $green;
            }

            .Progress-Badge {
              background: $purlple;
            }
          }
        }

        .communityPledgesChart {
          display: flex;
          flex: 60%;

          .Sub-Title {
            @include TextProps(16px, bold, $bluish-black);
          }
        }
      }
    }
  }

  .My-Achievements-Container {
    @include FlexColumn;
    margin: 30px 60px;

    .Title {
      font-weight: bold;
      color: #183c5b;
      margin-bottom: 24px;
    }

    .CardHolder {
      @include FlexColumn;
      background: $white;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

      .Title-Container {
        @include FlexSpaceBetween;
        padding: 24px;
        overflow-x: auto;

        .Sub-Title {
          @include TextProps(18px, normal, $bluish-black);
        }

        .Link {
          .LibraryButton {
            width: 200px;
            background-color: $bluish-black;
            border-radius: 4px;
            border: none;
            padding: 14px;
            text-transform: uppercase;

            p {
              @include TextProps(16px, bold, $white)
            }
          }
        }
      }

      .QuestionHolder {
        @include FlexColumn;
        margin: 0 24px 30px;

        .Question-Number {
          display: flex;

          h5 {
            @include TextProps(15px, normal, $bluish-black);
            margin-bottom: 10px;
          }
        }

        .Question-Detail {
          display: flex;

          .Answer {
            @include TextProps(18px, normal, $bluish-black);
            display: flex;
            flex: 60%;
            margin-bottom: 10px;
          }

          .Correct {
            @include TextProps(18px, bold, $green);
            display: flex;
            flex: 40%;
            font-style: italic;
            margin-bottom: 10px;
          }

          .Incorrect {
            display: flex;
            flex: 40%;
            margin-bottom: 10px;

            .Statement {
              @include TextProps(18px, bold, $red);
              display: flex;
              flex: 50%;
              font-style: italic;
            }

            .Link {
              display: flex;
              flex: 50%;
              margin-left: 20px;
              font-style: normal;
              text-decoration: underline;
              cursor: pointer;
              @include TextProps(18px, normal, $blue);
            }
          }
        }
      }
    }
  }

  .My-Activities-Container {
    @include FlexColumn;
    margin: 30px 60px;

    .Title {
      font-weight: bold;
      color: #183c5b;
      margin-bottom: 24px;
    }

    .CardHolder {
      @include FlexColumn;
      background: $white;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

      .Title-Container {
        @include FlexSpaceBetween;
        overflow-x: auto;

        .Link {
          margin-right: 40px;

          .RetakeButton {
            @include TextProps(16px, bold, $white);
            width: 200px;
            background-color: $bluish-black;
            border-radius: 4px;
            border: none;
            margin-top: 24px;
            padding: 14px;
            text-transform: uppercase;

            &:disabled {
              background-color: rgba(24, 60, 91, 0.3);
            }
          }
        }
      }

      .Content-Container {
        padding: 30px;

        .ActivityTable {
          width: 100%;
          max-width: 100%;
          border: 1px solid #183c5b26;
          border-collapse: inherit;
          border-radius: 8px;

          thead {
            tr {
              background-color: #183c5b08;

              th {
                font-size: 16px;
                height: 64px;
                color: #183c5b;
                border: none;
                border-right: 1px solid #183c5b26;
                background: transparent;
                padding: 0 24px;
                text-align: left;
                font-weight: bold;
                vertical-align: middle;
                cursor: pointer;
              }

              th:last-of-type {
                border-right: none;
              }
            }
          }

          tbody {
            tr:nth-child(odd) {
              background-color: #fff;
            }

            tr:nth-child(even) {
              background-color: #183c5b1a;
            }

            tr {
              td {
                font-size: 16px;
                height: 64px;
                color: #183c5b;
                background: transparent;
                border: none;
                border-top: 1px solid #183c5b26;
                border-right: 1px solid #183c5b26;
                padding: 0 24px;
                text-align: left;
                vertical-align: middle;

                a {
                  color: $blue;
                  cursor: pointer;
                }
              }

              td:last-of-type {
                border-right: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .Pledges-Container {
    .CardHolder {
      .Tab-Container {
        @include FlexColumn;

        .Tabs {
          @include FlexColumn;
        }
      }

      .AllCharts {
        @include FlexColumn;

        .My-Pledge-Chart-Containter{
          width: 100%;

          .myPledgesChart {
            @include FlexColumn;
            @include FlexCenter;
            flex:100%;
            width: inherit;
            margin: 15px 0px;
          }
        }

        .communityPledgesChart {
          @include FlexColumn;
          @include FlexCenter;
          width: inherit;
          margin-bottom: 15px 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .Compliance-Dashboard {
    .ButtonHolder {
      margin: 0 auto;
    }

    .Pledges-Container {
      margin: 30px 20px;

      .CardHolder {
        .Tab-Container,
        .AllCharts {
          width: 100% !important;
        }
      }
    }
  }
}
