@import 'src/Style/Style.scss';

.QuizReviewBox {
  width: 100%;

  .Title {
    @include FlexSpaceBetween;
  }

  .QuizReviewBox-Audio {
    @include FlexCenter;
  }

  .QuizReviewBox-Questions {
    background-color: $white;
    border: 2px solid #183d5b2e;
  }

  .QuizReviewBox-Questions > div:nth-child(odd) {
    background-color: #fafafa;
  }

  .QuizReviewBox-QuestionAnswer {
    display: flex;
    min-height: 100px;
  }

  .flex-column {
    flex-direction: column;
  }

  .flex-row {
    flex-direction: row;
  }

  .QuizReviewBox-Question__container {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }

  .QuizReviewBox-Answer__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .QuizReviewBox-QuestionAnswer__container {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }

  .QuizReviewBox-HalfQuestion__container {
    display: flex;
    flex: 50%;
    align-items: center;
    padding: 10px 0;
  }

  .QuizReviewBox-HalfAnswer__container {
    @include FlexCenter;
    flex:  50%;
    padding: 10px 0;
  }

  .QuizReviewBox-Question__position {
    @include FlexCenter;
    width: 50px;
    height: 50px;
    color: #c9d0d5;
    border: 2px solid #c9d0d5;
    border-radius: 50%;
    margin: 0 20px;
    flex-shrink: 0;
    position: relative;

    .Position {
      @include TextProp;
      font-size: 24px;
      line-height: 24px;
      opacity: 0.25;
    }

    .Tick,
    .Cross {
      position: absolute;
      top: -10px;
      left: -7px;
    }
  }

  .QuizReviewBox-Question__question {
    @include FlexProps(center, flex-start);
    flex-wrap: wrap;
  }

  .QuizReviewBox-Question__question_text {
    @include TextProp;
    font-size: 24px;
  }

  .QuizReviewBox-QA__part {
    @include FlexCenter;
    margin: 3px 0;

    .Text-Part {
      display: flex;
    }

    .Answer-Part {
      @include FlexCenter;
      min-height: 58px;
      min-width: 154px;
      color: $bluish-black;
      background-color: transparent;
      border: 1px solid;
      border-radius: 4px;
      margin: 0 10px;
      padding: 5px;
      position: relative;

      .Tick,
      .Cross {
        position: absolute;
        top: -10px;
        left: -10px;
      }
    }

    .Answer-Part.Correct-Answer {
      background-color: #7de3bb1a;
      border-color: #7de3bb;
    }

    .Answer-Part.Incorrect-Answer {
      background-color: #d47e7e1a;
      border-color: #d47e7e;
    }

    .__react_component_tooltip {
      @include FlexCenter;
      height: 66px;
      min-width: 116px;
      color: $light-green;
      background-color: $white;
      border: 1px solid #193c5a2a;
      font-size: 16px;
      opacity: 1;
      box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
    }
  }

  .QuizReviewBox-Answer__option {
    @include FlexCenter;
    flex: 50%;
    margin: 10px 0;
    position: relative;

    .Option {
      background-color: transparent;
      border: 1px solid #7fc3d5;

      &:hover {
        background-color: transparent;
        border: 1px solid #7fc3d5;
      }
    }

    .Option.Correct-Answer {
      color: #7de3bb;
      border-color: #7de3bb;
    }

    .Option.Incorrect-Answer {
      color: #d47e7e;
      border-color: #d47e7e;
    }

    .Arrow {
      position: absolute;
      left: 25%;
    }
  }
}

@media only screen and (max-width: 425px) {
  .QuizReviewBox {
    .QuizReviewBox-QuestionAnswer {
      flex-direction: column;

      .QuizReviewBox-QuestionAnswer__container {
        .QuizReviewBox-Question__question {
          .QuizReviewBox-QA__part {
            flex-direction: column;
          }
        }
      }
    }
  }
}
