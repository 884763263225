.CreationDashboards .Actions {
  display: flex;
}

.CreationDashboards .Actions .Scorm-Export {
  margin: 0 15px;
}

.CreationDashboards-DescriptionBox {
  margin: 0 60px 30px 60px;
  display: flex;
  align-items: baseline;
}

.CreationDashboards-DescriptionBox h5 {
  margin-right: 30px;
}

.CreationDashboards-LessonList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CreationDashboards-LessonList-Item {
  margin: 15px;
  width: 750px;
  display: flex;
  align-items: center;
}

.PositionButtons-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;
    margin: 0 10px;
}
