@import 'src/Style/Style.scss';

.Course-Pledge-Containter {
  @include FlexColumn;

  .Title {
    @include TextProps(36px, bold, $bluish-black);
    margin-bottom: 23px;
  }

  .Content {
    @include FlexColumn;
    background-color: $white;
    border: 1px solid #183d5b2e;
    border-radius: 4px;
    padding: 32px 27px;

    .Paragraph {
      @include TextProps(18px, normal, $bluish-black);
      line-height: 27px;
      margin-bottom: 65px;
    }
  }

  .Finalize-Pledge-Button {
    @include FlexProps(center, flex-end);
    margin-top: 41px;

    .Btn {
      @include FlexCenter;
      height: 58px;
      width: 258px;
      background-color: #183d5b;
      border: 1px solid #183d5b2e;
      border-radius: 4px;
      transition: .3s;

      .Text {
        @include TextProps(20px, normal, $white);
      }

      .fa {
        font-size: 25px;
        color: $white;
        margin-left: 15px;
      }

      &:hover {
        background-color: $white;
        border-color: #183d5b;

        .Text, .fa {
          color: $bluish-black;
        }
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
