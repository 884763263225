@import 'src/Style/Style.scss';

.EditUser {
  @include FlexColumn;
  align-items: center;

  .ProfilePhoto-Field {
    @include FlexCenter;
    @include TextProps(12px, normal, $bluish-black);
    height: 28px;
    width: 82px;
    background-color: #fcfcfc;
    border: 1px solid #e5e5ea;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);

    input {
      display: none;
    }
  }

  .ViewMode .DashboardFormBox-Line {
    margin: 13px 0 !important;
  }

  .EditMode {
    .DashboardFormBox-Line {
      margin: 5px 0 !important;
    }

    .Other-Race-Input {
      margin-top: 10px;
    }

    #Error-Container {
      #Users-Error {
        li {
          @include TextProps(14px, normal, $red)
        }
      }
    }
  }

  .DashboardFormBox-Line {
    .DashboardFormBox-Label {
      .ProfilePhoto-Container {
        @include FlexProps(flex-end, center);
        height: 79px;
        width: 79px;
        border: 2px solid #6bd1be;

        .ProfilePhoto {
          height: 60px;
          width: 60px;
        }
      }
    }

    .ProfilePhoto-Container.uploaded {
      align-items: center;
    }

    .Password-Requirement {
      margin-top: 20px;
      line-height: 15px;
      text-transform: none;
    }
  }
}

@media only screen and (max-width: 425px) {
  .Phone-Number {
    width: 250px;
  }
}
