@import 'src/Style/Style.scss';

.B2B-Admin-Credential-Dashboard-Container {
  @include FlexColumn;

  .Product-Overview-Purchase {
    @include FlexCenter;
    flex-direction: column;
    @include BoxShadow;
    @include LeftToRightGradient($light-green, $sky-blue);
    padding: 50px;
    border-radius: 5px;
    margin: 30px 60px;

    .Icon {
      color: $bluish-black;
    }

    .Heading {
      @include TextProps(24px, bold, $bluish-black);
      margin: 20px 0;
    }

    .Description {
      @include TextProps(16px, bold, $bluish-black);
      text-align: center;
    }
  }

  .Dynamic-Width {
    overflow-x: auto;
  }

  .No-Data {
    @include TextProps(16px, bold, $red);
    margin: 10px 0px 10px 62px;
  }

  .Title {
    @include TextProps(24px, bold, $bluish-black);
  }

  .Cerdential-Dashboard-Tabs {
    @include FlexCenter;
    margin: 50px 65px 42px;
    height: 64px;
    width: auto;
    background: $light-gray;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);

    .Tab-Button {
      @include FlexCenter;
      @include TextProps(16px, bold, $bluish-black);
      width: 50%;
      height: 59px;
      border-radius: 5px;
      border: none;
      outline: none;
      line-height: 21px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .Active {
      height: 54px;
      width: 49%;
      background: $light-green;
      box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05);
    }
  }

  .Credential-User-Participation {
    margin: 0 62px 64px;

    .Badge-Container {
      @include FlexSpaceBetween;
      flex-wrap: wrap;
      min-height: 220px;
      background: $white;
      border-radius: 5px;
      margin: 24px 0;
      padding: 24px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

      .Badge {
        @include FlexColumn;
        flex-wrap: wrap;
        margin: 10px 0;

        .Text-Heading {
          @include TextProps(12px, bold, $bluish-black);
          margin: 0 0 22px 17px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        .Shape {
          @include FlexCenter;
          position: relative;
          height: 103px;
          width: 240px;

          .Arrow-Icon {
            @include FullHW;
            @include Absolute0;
          }

          .Box-Inner-Text {
            @include FullHW;
            @include Absolute0;
            @include FlexCenter;
            @include TextProps(12px, normal, $bluish-black);

            .Digit {
              @include TextProps(24px, bold, $bluish-black);
              letter-spacing: 1px;
              margin-right: 8px;
            }

            .Text {
              @include TextProps(14px, normal, $bluish-black);
              letter-spacing: 0.4px;
              text-transform: uppercase;
            }
          }
        }
      }

      .Achieved-QBS-Status-Holder {
        @include FlexColumn;
        margin: 10px 0;

        .Text-Heading {
          @include TextProps(12px, bold, #38756a);
          letter-spacing: 1px;
          text-transform: uppercase;
          margin-bottom: 11px;
        }

        .Shape {
          @include FlexCenter;
          position: relative;
          height: 137px;
          width: 142px;

          .Image {
            @include FullHW;
          }

          .Inner-Text {
            @include FlexCenter;
            @include FullHW;
            @include Absolute0;
            flex-direction: column;

            .Digit {
              @include TextProps(24px, bold, $bluish-black);
              @include FlexCenter;
              letter-spacing: 1px;
            }

            .Text {
              @include TextProps(14px, normal, $bluish-black);
              @include FlexCenter;
              letter-spacing: 0.4px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .Credential-User-Performance {
    margin: 0 62px;

    .Table-And-Chart-Container {
      display: flex;
      justify-content: space-between;
      margin: 24px 0;

      .Score-Container {
        @include FlexColumn;
        width: 65%;
        background-color: $white;
        border-radius: 4px;
        padding: 24px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

        .Heading {
          @include TextProps(18px, normal, $bluish-black);
        }

        .Table-Container {
          position: relative;
          padding-top: 30px;
          overflow-x: auto;

          .ToolTip-Holder {
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 9;

            .ToolTip {
              @include TextProps(10px, normal, $white);
              @include FlexCenter;
              width: 119px;
              height: 22px;
              border-radius: 5px;
              background-color: $bluish-black;
              letter-spacing: 0.4px;
              text-transform: uppercase;
              text-align: center;
            }

            .ToolTip-Img {
              height: 10px;
              width: 25px;
              transform: rotate(180deg);
              position: absolute;
              left: 50px;
            }
          }

          .Table {
            width: 100%;
            max-width: 100%;
            border: none;
            border-collapse: inherit;

            thead {
              tr {
                th {
                  @include TextProps(12px, bold, $bluish-black);
                  position: relative;
                  height: 62px;
                  background-color: $white;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 100%;
                  border: none;
                  vertical-align: middle;
                  text-align: center;
                  letter-spacing: 0.2px;
                }

                .Yellow-Line-Out-Arrow {
                  background-image: url('../../../../Assets/Images/yellow-line-out-arrow.svg');
                }

                .Yellow-In-Out-Arrow {
                  background-image: url('../../../../Assets/Images/yellow-in-out-arrow.svg');
                }

                .Green-In-Out-Arrow {
                  background-image: url('../../../../Assets/Images/green-in-out-arrow.svg');
                }

                .Green-In-Line-Arrow {
                  background-image: url('../../../../Assets/Images/green-in-line-arrow.svg');
                }

                #qbs-threshold {
                  border-radius: 5px;
                  background-color: $bluish-black;

                  p {
                    @include TextProps(10px, normal, $white);
                  }
                }
              }
            }

            tbody {
              tr {
                &:nth-child(odd) {
                  background-color: $white;
                }

                &:nth-child(even) {
                  background-color: #183c5b1a;
                }

                &:first-of-type {
                  td {
                    &:first-child {
                      border-top-left-radius: 3px;
                    }

                    &:last-child {
                    }
                  }
                }

                &:last-of-type {
                  td {
                    border-bottom: 1px solid #193c5a26;

                    &:first-child {
                      border-bottom-left-radius: 3px;
                    }

                    &:last-child {
                      border-bottom-right-radius: 3px;
                    }
                  }
                }

                td {
                  &:last-of-type {
                    border-right: 1px solid #193c5a26;
                  }

                  @include TextProps(14px, bold, $bluish-black);
                  height: 64px;
                  border: none;
                  border-top: 1px solid;
                  border-left: 1px solid;
                  border-color: #193c5a26;
                  vertical-align: middle;
                  text-align: center;
                  letter-spacing: 0.2px;
                  word-wrap: break-word;

                  .Percentage {
                    @include TextProps(16px, bold, $bluish-black);
                  }

                  .Total {
                    @include TextProps(14px, normal, $bluish-black);
                    margin-top: 4px;
                  }
                }
              }
            }
          }
        }

        .Credential-Scores-Explanation {
          width: 100%;
          overflow: auto;
          margin-top: 50px;

          .Image {
            min-width: 100%;
          }
        }

        .Table-Info {
          @include TextProps(14px, normal, $bluish-black);
          margin: 17px 17px 50px;
          letter-spacing: 0.2px;
        }
      }

      .Fluency-Container {
        @include FlexColumn;
        width: 33%;
        background-color: $white;
        border-radius: 4px;
        padding: 24px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

        .Heading {
          @include TextProps(18px, normal, $bluish-black);
        }

        .SubHeading {
          @include TextProps(14px, normal, $bluish-black);
        }

        .Graph-Data-Holder {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 20px;

          .Graph-Data {
            display: flex;
            margin: 5px;

            .Legend-Holder {
              display: flex;
              align-items: flex-start;
              margin-top: 2px;

              .Underestimated-Legend,
              .Correctly-Estimated-Legend,
              .Overestimated-Legend {
                height: 8px;
                width: 8px;
                border-radius: 2px;
                margin-right: 4px;
              }

              .Underestimated-Legend {
                background-color: #546e7a;
              }

              .Correctly-Estimated-Legend {
                background-color: #00a6ed;
              }

              .Overestimated-Legend {
                background-color: #f6511d;
              }
            }

            .Text-Holder {
              .Text {
                @include TextProps(14px, bold, #333333);
                letter-spacing: 0.2px;
              }

              .User-Count {
                @include TextProps(12px, bold, #193c5ab3);
                margin-top: 6px;
                letter-spacing: 0.2px;
                text-transform: uppercase;
              }
            }
          }
        }

        .Bottom-Text {
          @include TextProps(12px, normal, $bluish-black);
          margin-top: 13px;
          letter-spacing: 0.2px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .B2B-Admin-Credential-Dashboard-Container {
    .Credential-User-Performance {
      .Table-And-Chart-Container {
        .Score-Container {
          .Table-Container {
            .Table {
              thead, tbody {
                tr {
                  th, td {
                    max-width: 75px;
                    padding: 0 10px 0 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .B2B-Admin-Credential-Dashboard-Container {
    .Credential-User-Performance {
      .Table-And-Chart-Container {
        .Score-Container {
          .Table-Container {
            .Table {
              thead, tbody {
                tr {
                  th, td {
                    max-width: 200px;
                    min-width: 110px;
                    padding: 0 10px;
                  }
                }
              }
            }
          }

          .Credential-Scores-Explanation {
            .Image {
              min-width: 800px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .B2B-Admin-Credential-Dashboard-Container {
    .Credential-User-Performance {
      .Table-And-Chart-Container {
        flex-direction: column;

        .Score-Container,
        .Fluency-Container {
          width: 100%;
          margin: 10px 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .B2B-Admin-Credential-Dashboard-Container {
    .Product-Overview-Purchase {
      padding: 30px 20px;
      margin: 10px;
    }

    .Cerdential-Dashboard-Tabs {
      flex-direction: column;
      height: 100%;
      margin: 50px 35px 42px;

      .Tab-Button {
        width: 100%;
      }
    }

    .Credential-User-Participation {
      margin: 0 35px;
    }

    .Credential-User-Performance {
      margin: 0 35px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .B2B-Admin-Credential-Dashboard-Container {
    .Cerdential-Dashboard-Tabs {
      margin: 50px 20px 42px;
    }

    .Credential-User-Participation {
      margin: 0 20px;
    }

    .Credential-User-Performance {
      margin: 0 20px;
    }
  }
}
