.User-Dashboard {
  display: flex;
  margin-top: 75px;
}

.User-Dashboard-Main {
  flex: 1 1;
}

.SidebarList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(24, 60, 91, 0.18);
  position: absolute;
  left: 0;
  margin: 0;
  padding-top: 40px;

  .BlurEffect {
    opacity: 35%;  
  }

  .SidebarLinks {
    list-style: none;
    padding: 15px 0px 15px 0;
    width: 100%;

    a {
      font-family: Lato;
      font-size: 16px;
      color: #183c5b;
      text-decoration: none solid rgb(24, 60, 91);
      letter-spacing: 2px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 15px 0px 15px 20px;
      width: 100%;
      
      .icon {
        color: #dee4ec;
        width: 30px;
        margin-right: 20px;
        display: inline-block;
      }
    }

    &:hover{
      background-color: #e7ebed;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      cursor: pointer;
    }
  }

  .ButtonsHolder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    list-style: none;
    margin: 20px 30px 0 0;

    .btn-sidebar {
      border: 1px solid #183c5b;
      border-radius: 30px;
      width: 239px;
      padding: 11px;
      margin-bottom: 25px;
      background-color: transparent;
      color: #183c5b;
      font-family: Lato;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;

      &:hover, :focus{
        background-color: #183c5b;
        color: #fff;
        border-color: #183c5b;
      }
    }
  }

  .ContactLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 20px;
      cursor: pointer;

      a {
        font-family: Lato;
        font-size: 14px;
        color: #183c5b;
        text-decoration: none solid rgb(24, 60, 91);
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: bold;
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }
}

.Line-Label {
  display: flex;
  justify-content: flex-start;
  color: #183D5B;
}

.Line-Label span {
  width: 150px;
  display: flex;
  justify-content: center;
}

.hr-1 {
  width: 30px;
  margin-left: 0;
  margin-right: 0;
}

.hr-2 {
  width: 300px;
  margin-left: 0;
  margin-right: 0;
}

.UserDashboard h1 {
  text-align: center;
}

.UserDashboard-FormLinks_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  width: 289px;
  min-height: calc(100vh - 79px);
  padding-top: 30px;
  background-color: rgba(24, 60, 91, 0.18);
}

.ButtonBox-UserDashboardProfile-FormLinks-selected {
  border: none;
  background-color: #e7ebed;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  width: 100%;
  margin-left: 20px;
}

.ButtonBox-UserDashboardProfile-FormLinks {
  border: none;
  background-color: transparent;
  width: 100%;
  margin-left: 20px;
}

.ButtonBox-UserDashboardProfile-FormLinks:hover {
  background-color: #e7ebed;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.ButtonBox-UserDashboardProfile-FormLinks-selected p {
  font-family: Lato;
  font-size: 16px;
  color: #183c5b;
  text-decoration: none solid rgb(24, 60, 91);
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 15px 0px 15px 20px;
  margin-left: 60px;
  text-align: left;
}

.ButtonBox-UserDashboardProfile-FormLinks p {
  font-family: Lato;
  font-size: 16px;
  color: #183c5b;
  text-decoration: none solid rgb(24, 60, 91);
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 15px 0px 15px 20px;
  margin-left: 60px;
  text-align: left;
}

.UserDashboard-Form_container {
  overflow: scroll;
}

.UserDashboard-Form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.UserDashboard-Title {
  color: #183c5b;
  font-size: 28px;
  margin-bottom: 20px;
}

.UserDashboardProfile_container {
  display: flex;
}

.UserDashboard-Learn-Links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 45px 0;
}

.UserDashboard-Console {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.UserDashboardCoursePackages.container {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  border-radius: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}

.UserDashboardCoursePackages { 
  align-items: center;
  margin-top: 30px;
}

.UserDashboardCoursePackages.Learn {
  background-color: #66ccb9 !important;
}

.UserDashboardCoursePackages.Credential {
  background-color: #5eb7a7;
}

.CoursePackageBox.w-628 {
  width: 628px;
}

.CoursePackageBox-Certificates-label .InfoText {
  font-size: 9px;
  text-align: center;
  font-style: italic;
  margin-top: 5px;
}

.Confirm-User-Message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 53px;
  width: auto;
  color: white;
  background-color: #57bdaa;
  margin: 30px 30px 0;
  padding: 0 30px;
}

.Confirm-User-Message .Left i {
  margin-right: 30px;
}

.Confirm-User-Message .Left span {
  font-family: 'LATO';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.Confirm-User-Message .Right i {
  cursor: pointer;
}

.UserDashboardCoursePackages.container.mt-50 {
  margin-top: 50px;
}

.UserDashboardCoursePackages.container.mt-0 {
  margin-top: 0;
}

.switch-button {
   background: #bfbfbf;
   border-radius: 50px;
   overflow: hidden;
   min-width: 380px;
   text-align: center;
   font-size: 16px;
   font-family: Lato;
   line-height: 21px;
   color: #fff;
   position: relative;
   padding-right: 180px;
   position: relative;
   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

.switch-button:before {
  content: "ENGLISH";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}

.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(180px);
  transition: transform 300ms linear;
}

.switch-button-checkbox + .switch-button-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  user-select: none;
  pointer-events: none;
}

.switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: #183d5b;
  height: 35px;
  width: 98%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 30px;
  margin: 2px;
  transform: translateX(0);
  transition: transform 300ms;
}

.switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}

.CoursePackage-Title-Container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  border-bottom: 1px solid #183c5b40;
}

.CoursePackage-Title {
  font-family: 'LATO';
  font-size: 24px;
  color: #183c5b;
  letter-spacing: 0.4px;
  padding: 32px;
}

.Canopy-Type-Container {
  display: flex;
  width: 90%;
  margin: 34px 0;
}

.Canopy-Type-Container .Canopy-Type {
  display: flex;
  flex: 50%;
  flex-direction: column;
}

.Canopy-Type-Container .Canopy-Type .Canopy-Type-Name {
  font-family: 'LATO';
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.Canopy-Type-Container .Canopy-Type .Canopy-Type-Desc {
  font-family: LATO;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.2px;
  margin:  8px 0;
}

.Canopy-Type-Container .Canopy-Switch {
  display: flex;
  flex: 50%;
  align-items: center;
  justify-content: right;
}

.UserDashboardCoursePackages-Packages__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin-top: 25px;
  margin-bottom: 10px;
}

.UserDashboardPurchasePackage{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 30px;
  overflow: scroll;
  height: 100%;
}

.UserDashboardPurchasePackage .heading {
  display: flex;
  flex: 100%;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 38px;
  font-family: "LATO";
  font-size: 42px;
}

.UserDashboardPurchasePackage .sub-heading.Coupon-Banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 100%;
  font-family: 'LATO';
  font-weight: bold;
  font-size: 25px;
  color: white;
  background-color: #57bdaa;
  border-radius: 4px;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 30px 0;
}


.UserDashboardPurchasePackage-title {
  margin: 20px 0;
}

.CardElement {
  min-height: 16px;
  color: #183d5b;
  background-color: rgb(248, 252, 253);
  border: 1px solid rgb(126, 194, 212);
  border-radius: 5px;
  font-size: 18px;
  margin: 5px 0 20px 0;
  padding: 12px;
}

.customeField {
  width: 30% !important;
  display: inline-grid;
  margin-right: 15px;
}

.UserDashboardPurchasePackage-Form {
  margin: 0 20px;
  padding: 20px 40px;
  width: 600px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 12px rgba(27,31,35,.15);
}

.UserDashboardPurchasePackage-InputBox{
  height: 40px;
  width: 100%;
  color: #183d5b;
  background-color: rgb(248, 252, 253);
  border: 1px solid rgb(126, 194, 212);
  border-radius: 5px;
  font-size: 18px;
  margin: 5px 0 20px 0;
  padding: 0 12px;
}

.UserDashboardPurchasePackage-Button__submit {
  cursor: pointer;
  width: 100%;
  text-align: center;
  height: 52px;
  border: 1px solid #183c5b;
  border-radius: 4px;
  background-color: #183c5b;
  color: #F0F0F0;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.UserDashboardPurchasePackage-Button__submit p {
  font-weight: bold;
  
}

.UserDashboardPurchasePackage__success {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Purchase-Desc {
  margin-top: 20px;
  font-family: 'LATO';
  font-size: 18px;
  color: #193c5a;
}

.Purchase-Desc a {
  text-decoration: underline;
  font-weight: bold;
}

.UserDashboardPurchasePackage__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin: 50px 0;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  height: 65vh;
}

.OrderSummaryCard {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.OrderSummaryCard .CardHeader {
  padding: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #7ec2d4;
  background-size: cover;
  font-family: Lato;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
}

.CardBody {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #7ec2d4;
  padding: 25px 19px;
}

.CardBody .InfoHolder {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.CardBody .InfoHolder .cardTitle {
  display: flex;
  flex: 70%;
  justify-content: flex-start;
  font-size: 18px;
  color: #2c405a;
}

.CardBody .InfoHolder .cardText {
  display: flex;
  flex: 30%;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 18px;
  color: #2c405a;
}

.CardBody .Divider {
  display: flex;
  border-bottom: 1px solid #7ec2d4;
  margin: 20px 0;
}

.icon-purchase-success {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 150px;
  font-size: 50px;
  color: white;
  background-color: #6bd1be;
  border-radius: 50%;
}

.learn-home-icons {
  color: white;
  background-color: #6bd1be;
  padding: 30px;
  border-radius: 50%;
}

.UserDashboardPurchasePackage-Container__submit {
  max-width: 400px;
  width: 100%;
  margin-top: 30px;
}

.PromoCodeBox__container {
  background-color: white;
  margin-bottom: 20px;
  padding: 15px;
}

.PromoCodeBox__inner {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 2px dotted #a1d4e1;
}

.strike-through {
  display: flex;
  text-decoration: line-through;
  text-decoration-color: red;
}

@media only screen and (max-width: 1350px) {
  .UserDashboardCoursePackages-Packages__container {
    grid-template-columns: 1fr 1fr ;
  }
}

@media only screen and (max-width: 1100px) {
  .UserDashboardPurchasePackage {
    align-items: center;
  }
}

@media only screen and (max-width: 900px) {
  .Canopy-Type-Container {
    flex-direction: column;
  }

  .Canopy-Type-Container .Canopy-Switch {
    justify-content: center;
    margin-top: 10px;
  }

  .CoursePackageBox.w-628 {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) {
  .UserDashboard-Form_container {
    margin: 20px;
    margin-left: 70px;
    width: 38%;
    max-width: 1000px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 768px) {
  .UserDashboardCoursePackages-Packages__container {
    grid-template-columns: 1fr;
  }

  .UserDashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .UserDashboard-FormLinks_container {
    height: calc(100vh - 106px);
  }

  .UserDashboardProfile_container {
    width: 100%;
  }

  .hr-2 {
    width: 250px;
  }

  .UserDashboardPurchasePackage {
    width: 100%;
    padding: 30px 0;
  }

  .UserDashboardPurchasePackage-Form {
    width: 100%;
    margin: 0;
  }

  .UserDashboard-Title {
    font-size: 20px;
  }

  .UserDashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .UserDashboard-FormLinks_container {
  }

  .UserDashboardProfile_container {
    width: 100%;
  }

  .hr-2 {
    width: 250px;
  }

  .customeField {
    width: 100% !important;
  }

  .UserDashboard-Console {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
}
