@import 'src/Style/Style.scss';

.StreakConfig {
  .modal {
    .modal-content {
      .modal-pre-header {
        @include FlexProps(center, flex-end);
      }

      .modal-header {
        @include FlexCenter;
        flex-direction: column;

        .Title {
          @include TextProps(23px, bold, $bluish-black);
          margin-bottom: 10px;
        }

        .Description {
          @include TextProps(16px, normal, $bluish-black);
          margin-bottom: 5px;
          text-align: center;
        }
      }

      .modal-body {
        .Errors-Container {
          margin-bottom: 10px;

          .Errors {
            li {
              @include TextProps(14px, normal, $red);
            }
          }
        }

        .Label {
          display: flex;

          .ToolTip {
            @include FlexCenter;
            height: 18px;
            width: 18px;
            border: 1px solid $bluish-black;
            border-radius: 50%;
            margin-left: 5px;


            .__react_component_tooltip {
              width: auto;
              max-width: 400px;
              line-height: 20px;
            }

            &:hover {
              color: $white;
              background-color: $green;
            }

            i {
              font-size: 12px;
            }
          }
        }

        .Input-Container {
          @include FlexColumn;
          margin-bottom: 20px;

          .Label {
            @include TextProps(16px, bold, $bluish-black);
            margin-bottom: 10px;
          }

          .Radio-Buttons {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;

            .Radio-Button input{
              margin: 5px 10px;
            }
          }

          .Selection-Buttons {
            display: flex;
            flex-wrap: wrap;

            .Selection-Button {
              @include TextProps(16px, normal, $bluish-black);
              height: 35px;
              background-color: $white;
              border: 1px solid $light-green;
              border-radius: 10px;
              margin: 5px;
              padding: 5px;
            }

            .Selection-Button.Active {
              color: $white;
              background-color: $green;
            }
          }

          .Select-List {
            width: 100%;
          }
        }

        .Checkbox-Container {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .Label {
            @include TextProps(16px, bold, $bluish-black);
            margin: 0 20px 0 0;
          }
        }

        .Submitted {
          @include FlexCenter;
          flex-direction: column;
          height: 300px;
          width: 100%;

          .Icon {
            height: 100px;
            width: 100px;
          }
        }
      }

      .modal-footer {
        @include FlexProps(center, flex-end);

        .Submit-Button {
          margin: 0 5px;

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .StreakConfig {
    .modal {
      .modal-content {
        width: 90%;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .StreakConfig {
    .modal {
      .modal-content {
        width: 80%;

        .modal-body {
          .Label {
            .ToolTip {
              .__react_component_tooltip {
                max-width: 80%;
              }
            }
          }
        }

        .modal-footer {
          flex-direction: column;

          .Submit-Button,
          .Close-Button {
            margin: 5px 0;
          }
        }
      }
    }
  }
}
