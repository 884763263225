@import 'src/Style/Style.scss';

.bg-grey {
  background-color: #e7ebed;
  height: 100%;
}

.container__checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ {
        .checkmark {
          background-color: #7ec2d4;

          &:after {
            display: block;
          }
        }
      }
    }
  }

  .checkmark {
    border: solid 1px #7ec2d4;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #ffffff;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 2px;
      width: 5px;
      height: 7px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.Signup-Container {
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin: 20px auto !important;
  border: solid 1px white;
  border-radius: 5px;
  padding: 10px;
  background-color: white;

  .Heading {
    @include FlexCenter;
    flex-direction: column;
    background-color: #6bd1be;
    border-radius: 4px;
    margin: 20px 0;
    padding: 10px;

    p {
      font-size: 28px;
      font-weight: bold;
      color: #fff;
      font-family: 'LATO';
    }
  }

  .Subheading {
    font-size: 16px;
    color: #193c5a;
    font-family: 'LATO';
    margin: 20px 0;
  }

  .Form {
    .Form-Error {
      display: flex;
      align-items: center;
      background-color: #d47e7e;
      color: white;
      font-family: 'LATO';
      font-size: 18px;
      font-weight: bold;
      margin: 0 0 20px;
      padding: 20px 10px;
      border-radius: 5px;
    }

    .Access-Code-Notice {
      color: #d47e7e;
      margin: 0 0 30px 0;

      p {
        font-size: 15px;
        font-family: 'Lato';
      }
    }

    .From-Loader {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-color: #000;
      opacity: 0.5;
      z-index: 9999;

      .SignedUp {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }

      &:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }
    }

    .CheckboxHolder {
      margin-bottom: 15px;

      .Field-Title {
        align-items: center !important;

        .Label {
          flex: inherit !important;
          margin-right: 10px;
          padding: 0 5px;
          white-space: nowrap;
        }

        .Text {
          font-family: 'LATO';
          font-size: 14px;
          width: 100%;
          color: #2c405a;
          text-decoration: none solid;
        }

        .Error {
          flex: inherit !important;
        }
      }

      .CheckBoxError {
        .CheckBox-Custom {
          background-color: #d47e7e0d;
          border-color: #d47e7e;
        }
      }
    }

    .Field {
      z-index: 999;
      display: inline-grid;
      margin-right: 20px;
      width: 100%;
      margin-bottom: 20px;

      .Field-Title {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 8px;

        .Label {
          display: flex;
          flex: 41%;
          align-items: center;
          justify-content: flex-start;
          font-size: 14px;
          color: #193c5a;
          font-family: Lato;
          text-transform: uppercase;
        }

        .Error {
          display: flex;
          flex: 59%;
          align-items: center;
          justify-content: flex-end;
          font-family: 'LATO';
          font-size: 14px;
          color: #d47e7e;
        }
      }

      .Input {
        height: 40px;
        font-family: Lato;
        font-size: 16px;
        color: #193c5a;
        background-color: #f8fcfd;
        background-size: cover;
        border: 1px solid #7ec2d4;
        border-radius: 4px;
        padding: 0px 8px;
      }

      .Input.Error {
        background-color: #d47e7e0d;
        border-color: #d47e7e;
      }
    }

    .NameField {
      display:inline-grid;
      margin-right:25px;
      width: 47%;
    }

    .Button {
      text-align: center;
      
      button {
        width: 100%;
        height: 47px;
        border-radius: 4px;
        background-color: #183d5b;
        font-family: Lato;
        font-size: 16px;
        color: #ffffff;
        text-transform: uppercase;
      }
    }
  }

  .Profile {
    display: flex;
    justify-content: space-around;
    height: auto;
    width: 100%;
    margin-top: 56px;

    .Text {
      display: flex;

      p {
        font-family: 'LATO';
        font-size: 18px;
        font-weight: bold;
        color: #193c5a;
        text-decoration: none solid rgb(25, 60, 90);
      }
    }

    .Login-Button {
      display: flex;

      button {
        font-family: 'LATO';
        font-weight: bold;
        font-size: 18px;
        color: #7ec2d4;
        background: none;
        border: none;
        text-decoration: none solid rgb(126, 194, 212);
        text-transform: uppercase;
      }
    }
  }

  .mr-0 {
    margin-right: 0 !important;
  }

  &__header {
    font-size: 21px;
    text-align: center;
    font-weight: bold;
  }

  &__subheader {
    font-size: 16px;
    margin: 20px 0 30px 0;
  }

  &__textinput {
    height: 40px;
    width: 100%;
    color: #183d5b;
    background-color: rgb(248, 252, 253);
    border: 1px solid rgb(126, 194, 212);
    border-radius: 5px;
    font-size: 18px;
    margin: 5px 0 20px 0;
    padding: 0 2px;
  }

  &__textinput-error {
    height: 40px;
    width: 100%;
    color: #183d5b;
    background-color: rgba(212, 126, 126, 0.05);
    border: 1px solid rgb(212, 126, 126);
    border-radius: 5px;
    font-size: 18px;
    margin: 5px 0 20px 0;
    padding: 0 2px;
  }

  &__label-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__label {
    font-size: 14px;
    font-weight: 400;
    color: rgb(25, 60, 90);
  }

  &__label-error {
    font-size: 14px;
    font-weight: 400;
    color: rgb(212, 126, 126);
  }

  &__button {
    border: none;
    border-radius: 5px;
    font-size: 15px;
    color: white;
    margin: auto;
    margin-top: 20px;
    height: 50px;
    width: 90vw;
    background-color: rgb(24, 61, 91);
  }

  &__button:disabled {
    background-color: #cccccc;
  }

  &__flash {
    background-color: #7de3bb;
    color: white;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 3px;
  }

  &__select {
    height: 40px;
    width: 100%;
    border:none;
    background-color: rgb(248, 252, 253);
    border: 1px solid rgb(126, 194, 212);
    border-radius: 5px;
    margin: 5px 0 20px 0;
    padding: 0 2px;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .Signup-Container {
    .NameField {
      width: 100%;
    }

    .Button {
      button {
        width: 100%;
      }
    }
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .container {
    padding: 30px;

    &__button {
      width: 50%;
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .container {
    width: 563px;
    margin: auto;
    margin-top: 100px;

    &__button {
      width: 50%;
    }
  }
}
