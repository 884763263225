@import 'src/Style/Style.scss';

.Session-Expired-Container {
  @include FlexCenter;
  height: 100vh;

  .Session-Expired-Card {
    @include FlexColumn;
    width: 30vw;
    background-color: $white;
    border-radius: 5px;
    margin: 20px auto;
    padding: 40px;

    .Heading {
      @include FlexCenter;
      flex-direction: column;
      background-color: $light-green;
      border-radius: 4px;
      margin: 20px 0;
      padding: 10px;

      p {
        @include TextProps(28px, bold, $white);
        margin-top: 5px;
      }
    }

    .Subheading {
      @include TextProps(16px, normal, $bluish-black);
      margin-bottom: 20px;
    }

    .Timer-Holder {
      @include FlexCenter;
      width: 100%;

      .Timer {
        @include FlexCenter;
        width: 80px;

        .CircularProgressbar {
          .CircularProgressbar-trail {
            stroke: #e8eff2;
            stroke-width: 5px;
          }

          .CircularProgressbar-path {
            stroke: #7de3ce;
            stroke-width: 5px;
          }

          .CircularProgressbar-text {
            @include TextProps(40px, normal, $light-green);
            fill: $light-green;
          }
        }
      }
    }
  }
}
