.CreationDashboards-TopicList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 750px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.CreationDashboards-TopicList-Item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c5ced6;
  width: 100%;
  margin: 3px 0;
}

.CreationDashboards-TopicList-Item-TopicBox {
  width: 500px;
}

.CreationDashboards-TopicList-Item-Buttons {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 25px;
}

.Archive-Quiz {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 40px;
}
