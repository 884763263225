.TopicSummaryBox {
  background-color: white;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TopicSummaryBox-Placeholder {
    padding: 30px;
}
