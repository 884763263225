.recorder {
  display: none;
}

.AudioRecordButton {
  display: flex;
}

.No-Recording .Icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  width: 43px;
  color: inherit;
  opacity: 0.3;
}

.No-Recording .__react_component_tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  font-size: 16px;
  font-family: 'LATO';
}

.No-Recording .__react_component_tooltip::after {
  border-bottom-color: #455a64 !important;
}
