@import 'src/Style/Style.scss';

.AudioAnalyzeButton-Container {
  @include FlexCenter;

  .Analyze-Btn {
    @include FlexCenter;
    color: $light-green;
    height: 43px;
    width: 43px;
    margin-right: 10px;
    margin-left: -5px;

    i {
      font-size: 25px;
    }
  }

  .modal {
    justify-content: flex-start;
    z-index: 99;

    .modal-content {
      min-height: 350px;
      max-height: 700px;
      width: 60%;
      border-radius: 5px;
      border: none;
      margin-top: 100px;

      .modal-header {
        @include FlexSpaceBetween;
        height: 50px;
        border-bottom: 1px solid $light-gray;
        margin-bottom: 10px;
        padding-bottom: 10px;

        .Title {
          @include TextProps(23px, bold, $bluish-black);
        }

        .Close-Btn {
          cursor: pointer;
        }
      }

      .modal-body {
        @include FlexCenter;
        flex-direction: column;

        .Result-Container {
          @include FlexColumn;
          width: 100%;
          margin-bottom: 20px;

          .Summary-Level-Result {
            @include FlexColumn;
            width: 100%;
            margin-bottom: 20px;

            .Title {
              @include TextProps(18px, bold, $bluish-black);
              margin-bottom: 5px;
            }

            .Legends {
              display: flex;
              gap: 20px;
              margin-bottom: 5px;
              flex-wrap: wrap;

              .Legend {
                display: flex;
                align-items: center;

                .Box {
                  height: 15px;
                  width: 15px;
                  border-radius: 5px;
                  margin-right: 3px;
                }

                .Text {
                  @include TextProps(16px, normal, $bluish-black);
                }

                &.Good {
                  .Box {
                    background-color: $light-green;
                  }
                }

                &.Average {
                  .Box {
                    background-color: $yellowish-orange;
                  }
                }

                &.Bad {
                  .Box {
                    background-color: $light-red;
                  }
                }
              }
            }

            .Summary-Section {
              @include FlexColumn;
              width: 100%;
              background-color: $white;
              border: 2px solid $lightest-gray;
              border-radius: 5px;
              box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
              padding: 10px;

              .ScoreDimensions {
                @include FlexSpaceBetween;
                width: 100%;
                flex-wrap: wrap;

                .ScoreDimension {
                  position: relative;
                  height: 150px;
                  width: 150px;
                  margin: 20px 5px 40px;

                  .Dot {
                    position: absolute;
                    inset: 5px;
                    z-index: 10;
                    animation: DotRotateOnCircle 1s linear forwards;

                    &::before {
                      position: absolute;
                      inset: -7px 0 0 50%;
                      content: '';
                      width: 15px;
                      height: 15px;
                      background-color: $light-green;
                      border-radius: 50%;
                      transform: translateX(-50%);
                      box-shadow: 0 0 10px $light-green, 0 0 30px $light-green;
                    }
                  }

                  svg {
                    position: relative;
                    height: 150px;
                    width: 150px;
                    transform: rotate(270deg);

                    circle {
                      @include FullHW;
                      fill: transparent;
                      stroke-width: 6px;
                      stroke: $lightest-gray;
                      transform: translate(5px, 5px);
                    }

                    circle:nth-child(2) {
                      stroke: $light-green;
                      stroke-dasharray: 440;
                      stroke-dashoffset: calc(440 - (440 * var(--progressbar)) / 100);
                      animation: FromInvisibleToVisible 1s linear forwards;
                      animation-delay: 1.1s;
                      opacity: 0;
                    }
                  }

                  .Percentage-Holder {
                    @include Absolute0;
                    @include FlexCenter;
                    flex-flow: column;
                    padding: 0 20px;

                    .Percentage {
                      @include TextProps(50px, bold, $light-green);
                      margin-left: 10px;

                      span {
                        font-size: 20px;
                        margin-left: 2px;
                      }
                    }
                  }

                  .Label {
                    @include FlexCenter;

                    .Text {
                      @include TextProps(15px, normal, $bluish-black);
                    }

                    .Info-Icon {
                      @include FlexCenter;
                      height: 15px;
                      width: 15px;
                      font-size: 9px;
                      border: 1px solid $bluish-black;
                      border-radius: 50%;
                      margin-left: 3px;
                    }

                    .Label-Tooltip {
                      @include TextProps(13px, normal, $bluish-black);
                      width: 300px;
                      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    }
                  }

                  &.bad {
                    .Dot::before {
                      background-color: $light-red;
                      box-shadow: 0 0 10px $light-red, 0 0 30px $light-red;
                    }

                    svg {
                      circle:nth-child(2) {
                        stroke: $light-red;
                      }
                    }

                    .Percentage-Holder {
                      .Percentage {
                        color: $light-red;
                      }
                    }
                  }

                  &.average {
                    .Dot::before {
                      background-color: $yellowish-orange;
                      box-shadow: 0 0 10px $yellowish-orange, 0 0 30px $yellowish-orange;
                    }

                    svg {
                      circle:nth-child(2) {
                        stroke: $yellowish-orange;
                      }
                    }

                    .Percentage-Holder {
                      .Percentage {
                        color: $yellowish-orange;
                      }
                    }
                  }
                }
              }

              .Formula {
                @include FlexColumn;

                .Text {
                  @include TextProps(16px, normal, $bluish-black);
                  margin: 1.5px 0;

                  .Code {
                    @include TextProps(16px, normal, $bluish-black);
                    font-style: italic;
                    color: $red;
                  }
                }

              }
            }
          }

          .Word-Level-Result {
            @include FlexColumn;
            width: 100%;

            .Title {
              @include TextProps(18px, bold, $bluish-black);
              margin-bottom: 5px;
            }

            .Legends {
              display: flex;
              gap: 20px;
              margin-bottom: 5px;
              flex-wrap: wrap;

              .Legend {
                display: flex;
                align-items: center;

                .Box {
                  @include TextProps(16px, normal, $bluish-black);
                  @include FlexCenter;
                  height: 25px;
                  width: 25px;
                  border-radius: 5px;
                  margin-right: 3px;
                }

                .Text {
                  @include TextProps(16px, normal, $bluish-black);
                }

                .Info {
                  font-size: 11px;
                  border: 1px solid $bluish-black;
                  border-radius: 50%;
                  padding: 3px 6px;
                  margin-left: 3px;
                }

                .Tooltip {
                  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                  width: 300px;
                }

                &.Mispronunciation {
                  .Box {
                    background-color: $yellow;
                  }
                }

                &.Insertion {
                  .Box {
                    background-color: $light-red;
                    color: $white;
                  }
                }

                &.Omission {
                  .Box {
                    background-color: $light-gray;
                    color: $bluish-black;
                  }
                }

                &.UnexpectedBreak {
                  .Box {
                    background-color: $light-blue;
                  }
                }

                &.MissingBreak {
                  .Box {
                    background-color: $light-gray;
                  }
                }

                &.Monotone {
                  .Box {
                    background-color: $light-purple;
                  }
                }
              }
            }

            .Word-Section {
              display: flex;
              width: 100%;
              background-color: $white;
              border: 2px solid $lightest-gray;
              border-radius: 5px;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              padding: 10px;

              .Words {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                padding: 10px 5px;

                .Word {
                  margin: 3px;
                  height: fit-content;
                  border-radius: 5px;
                  margin: 0 1px;
                  padding: 0 2px;

                  .Text {
                    @include TextProps(18px, normal, $bluish-black);
                  }

                  &.Mispronunciation {
                    background-color: $yellow;

                    .Text {
                      color: $bluish-black;
                      text-decoration: underline;
                    }
                  }

                  &.Insertion {
                    background-color: $light-red;

                    .Text {
                      color: $white;
                      text-decoration: line-through;
                      text-decoration-color: $white;
                    }
                  }

                  &.Omission {
                    background-color: $light-gray;

                    .Text {
                      color: $bluish-black;
                    }
                  }

                  &.UnexpectedBreak::before {
                    content: '[ ]';
                    background-color: $light-blue;
                    color: $bluish-black;
                    text-decoration: line-through;
                    margin-right: 3px;
                    padding: 2px;
                  }

                  &.MissingBreak::before {
                    content: '[ ]';
                    background-color: $light-gray;
                    color: $bluish-black;
                    margin-right: 3px;
                    padding: 2px;
                  }

                  &.Monotone {
                    text-decoration-line: underline;
                    text-decoration-style: wavy;
                    text-decoration-color: $light-purple;
                  }
                }
              }
            }
          }
        }

        .Feedback-Container {
          @include FlexColumn;
          width: 100%;

          .Word-Level-Feedback {
            @include FlexColumn;
            width: 100%;

            .Title {
              @include TextProps(18px, bold, $bluish-black);
              margin-bottom: 5px;
            }

            .Word-Section {
              display: flex;
              width: 100%;
              background-color: $white;
              padding: 10px;
              border: 2px solid $lightest-gray;
              border-radius: 5px;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

              .Words {
                @include FlexColumn;
                width: 100%;
                padding: 10px 5px;

                .Word {
                  @include FlexProps(center, flex-start);
                  flex-wrap: wrap;
                  padding-bottom: 10px;
                  border-bottom: 1px solid $lightest-gray;
                  margin-bottom: 10px;

                  &:last-of-type {
                    padding-bottom: 0;
                    border: none;
                    margin-bottom: 0;
                  }

                  .Text-Box {
                    @include FlexCenter;
                    height: 120px;
                    min-width: 70px;
                    color: $bluish-black;
                    background-color: $yellow;
                    padding: 5px;
                    border-radius: 5px;
                    margin: 5px;
                    text-decoration: underline;
                  }

                  .Error-Box {
                    @include TextProps(16px, normal, $red);
                    margin: 5px;
                  }

                  .Clip-Box {
                    @include FlexColumn;
                    height: 120px;
                    width: 225px;
                    margin: 5px;

                    .User-Recorded, .AI-Recorded {
                      @include FlexProps(center, space-between);
                      height: 56px;
                      background-color: #6bd1be33;
                      padding: 0 10px;
                      border: 1px solid $light-green;
                      border-radius: 5px;
                      margin: 2px 0;

                      .Text {
                        @include TextProps(16px, normal, $bluish-black);
                      }

                      .Play-Download-Container {
                        .AudioPlayButton {
                          .ButtonBox-AudioPlayButton {
                            height: 24px;
                            width: 24px;

                            h3 {
                              font-size: inherit;

                              i {
                                font-size: 18px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  .Wave-Box {
                    @include FlexColumn;
                    height: 120px;
                    width: 210px;
                    margin: 5px;

                    .User-Recorded, .AI-Recorded {
                      height: 56px;
                      width: 100%;
                      margin: 2px 0;
                    }
                  }

                  &.Original {
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .AudioAnalyzeButton-Container {
    .modal {
      .modal-content {
        max-height: 500px;
        width: 90%;
        margin-top: 80px;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .AudioAnalyzeButton-Container {
    .modal {
      .modal-content {
        .modal-body {
          .Result-Container {
            .Summary-Level-Result {
              .Summary-Section {
                .ScoreDimensions {
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
