.SectionBoxMini {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    width: calc(100% - 40px);
}

.SectionBoxMini-Left__container {
    display: flex;
    align-items: center;
}

.icon-save {
    color: rgb(126, 194, 212);
    margin-right: 40px;
}