// Variables

$app-background:   #e7ebed;
$white:            #ffffff;
$black:            #000000;
$bluish-black:     #193c5a;
$blue:             #347cff;
$light-blue:       #bfe3f9;
$compliance-blue:  #2892D7;
$darker-blue:      #1A6588;
$light-green:      #6bd1be;
$green:            #57bdaa;
$dark-green:       #009688;
$darker-green:     #208c7a;
$darkest-green:    #38756a;
$light-red:        #e0524a;
$red:              #ff0000;
$blood-red:        #ad2828;
$yellow:           #ffff00;
$yellowish-orange: #ff9500;
$light-orange:     #ff7043;
$orange:           #f4511e;
$dark-orange:      #ef5350;
$lightest-gray:    #f0f0f0;
$light-gray:       #bfbfbf;
$gray:             #8c8c8c;
$dark-gray:        #455a64;
$light-purple:     #9EABCA;
$purlple:          #7986CC;
$light-silver:     #41464b;
$sky-blue:         #8fc5e2;
$light-sea-blue:   #d6edf5;
$sand:             #ffe0b2;

// Mixins

@mixin FadeColor($color, $per) {
  color: rgba($color, $per);
}

@mixin FadeBackgroundColor($color, $per) {
  background-color: rgba($color, $per);
}

@mixin FlexProps($ai, $jc) {
  display: flex;
  align-items: $ai;
  justify-content: $jc;
}

@mixin FlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin FlexSpaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin FlexSpaceAround {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@mixin FlexColumn {
  display: flex;
  flex-direction: column;
}

@mixin FlexWrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin FlexRow {
  display: flex;
  flex-direction: row;
}

@mixin TextProp {
  font-family: 'LATO';
  color: $bluish-black;
}

@mixin TextProps($fs, $fw, $color) {
  font-family: 'LATO';
  font-size: $fs;
  font-weight: $fw;
  color: $color;
}

@mixin FullHW {
  height: 100%;
  width: 100%;
}

@mixin IconHW {
  height: 34px;
  width: 34px;
}

@mixin BacBorNone {
  background: none;
  border: none;
}

@mixin DisableBtn {
  background-color: $light-gray !important;
  cursor: not-allowed;
}

@mixin Border2LightestGray {
  border: 2px solid $lightest-gray;
}

@mixin AutoMarginPadding {
  margin: auto;
  padding: auto;
}

@mixin Fixed0 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin Absolute0 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin tablet-down {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 768) {
    @content;
  }
}

@mixin LightenBackground($color, $percentage) {
  background-color: lighten($color, $percentage);
}

@mixin LightenColor($color, $percentage) {
  color: lighten($color, $percentage);
}

@mixin BoxShadow {
  box-shadow: #0000001a 0 10px 15px -3px, #0000000d 0 4px 6px -2px;
}

@mixin GrowScaleWithShadow {
  transform: scale(1.02);
  box-shadow: #32325d40 0 13px 27px -5px, #0000004d 0 8px 16px -8px;
  transition: 0.3s;
}

@mixin LeftToRightGradient($pColor, $sColor) {
  background-image: linear-gradient(to right, $pColor, $sColor);
}

// Key Frames

@keyframes Glow {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes FromInvisibleToVisible {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes DotRotateOnCircle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(calc(3.6deg * var(--progressbar)));
  }
}
