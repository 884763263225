@import 'src/Style/Style.scss';

.Stats-Users {
  @include FlexColumn;
  width: calc(100% - 40px);
  margin: 20px;

  .Flash-Message {
    @include TextProps(18px, bold, $white);
    @include FlexCenter;
    height: 50px;
    width: 100%;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .Flash-Message.Info {
    background-color: $green;
  }

  .Flash-Message.Danger {
    background-color: $red;
  }

  .Title {
    display: flex;
    margin-bottom: 10px;
  }

  .Top-Filters {
    @include FlexCenter;
    flex-direction: column;
    width: 80%;
    margin: 0 auto 20px;

    .Input-Container {
      @include FlexProps(center, space-evenly);
      width: 100%;

      .Institution-Select-Autocomplete {
        width: 70%;
      }
    }
  }

  .Bottom-Filters {
    display: flex;
    margin-bottom: 10px;
    width: 100%;

    .Left-Filter,
    .Right-Filter {
      @include FlexCenter;
      flex-direction: column;
      width: 50%;
      border: 1px solid $light-gray;
      border-radius: 5px;
      padding: 20px;

      &:first-of-type {
        border-right: 0;
      }

      .Input-Container {
        @include FlexProps(center, space-between);
        width: 60%;
        margin-bottom: 7px;

        .Label {
          @include TextProps(16px, normal, $bluish-black);
          margin-right: 10px;
        }

        .Input {
          height: 30px;
          padding: 0 5px;
          font-size: 14px;
          border: 1px solid $bluish-black;
          background-color: $white;
          border-radius: 4px;
        }
      }
    }
  }

  .Actions {
    @include FlexCenter;
    width: 100%;
    margin-bottom: 10px;

    .Generate-Report,
    .Excel-Download-Btn {
      margin-left: 5px;
    }
  }

  .Content {
    @include FlexColumn;
    width: 100%;
  }
}
