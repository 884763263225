@import 'src/Style/Style.scss';

.All-Comments-Loader {
  @include FlexCenter;
  margin-top: 100px;
}

.AdminDashboard-Pages {
  flex-direction: row;

  .AdminDashboard-Manage {
    width: 95%;
    padding: 0 2rem;

    h2 {
      @include TextProp;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 2px solid #183c5b;
    }

    .Recent-Comments-Container, .Flag-Comments-Container {
      padding: 20px;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 1px 2px rgba(0,0,0,0.2);

      h3 {
        color: #183c5b;
        margin: 20px 0;
        font-size: 20px;
        font-weight: bold;
        display: flex;

        img {
          margin-right: 5px;
        }
      }

      .Bulk-Action {
        position: relative;
        margin: 20px 0;

        .bulk-actions-btn {
          @include BacBorNone;
          padding: 0;

          .bulk-actions {
            @include FlexSpaceBetween;
            @include TextProp;
            height: 47px;
            width: 185px;
            font-size: 14px;
            border: 1px solid #7ec2d4;
            border-radius: 4px;
            letter-spacing: 0.2px;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
            padding: 0 15px;
          }
        }

        .bulk-actions-section {
          @include FlexColumn;
          position: absolute;
          top: 50px;
          left: 0px;
          min-height: 60px;
          min-width: 250px;
          background-color: #ffffff;
          border: 1px solid #7ec2d480;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          padding: 24px;
          z-index: 2;

          .action {
            button {
              @include BacBorNone;

              p {
                @include TextProp;
                font-size: 16px;
                letter-spacing: 0.2px;
                height: 30px;
              }
            }
          }

          .action.danger {
            button {
              p {
                color: #ef5350;
              }
            }
          }
        }

        select {
          width: 185px;
          height: 47px;
          padding: 0px 8px;
          border: 1px solid #7ec2d4;
          border-radius: 4px;
          font-size: 14px;
          color: #183c5b;
          letter-spacing: 0.2px;
          box-shadow: 0px 2px 2px rgba(0,0,0,0.04);
        }
      }

      table {
        width: 100%;
        border-collapse: collapse;

        tr {
          @include TextProp;
          width: 312px;
          height: 61px;
          font-size: 14px;
          border-color: #183c5b;
          text-align: left;

          .Course-Slide {
            @include FlexColumn;

            .Links {
              @include FlexSpaceBetween;

              .Slide {
                color: #009688;
                font-size: 14px;
                cursor: pointer;
              }
            }
          }

          .Email {
            color: #009688;
            font-size: 14px;
            text-align: left;
          }

          th {
            font-weight: bold;
            background-color: rgba(24, 60, 91, 0.03);
          }

          td:first-of-type, th:first-of-type {
            min-width: 20px !important;
          }

          td, th {
            border: 1px solid #ccc;
            vertical-align: middle;
            color: #183c5b;
            font-size: 14px;
            padding: 10px 24px;
            min-width: 100px;
            max-width: 500px;
            word-break: break-all;
            line-height: 20px;
          }
        }

        tr:nth-of-type(odd) {
          background: #fff;
        }

        tr:nth-of-type(even) {
          background: rgba(24, 60, 91, 0.1);
        }
      }

      select {
        @include BacBorNone;
      }
    }

    .Pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 28px;
      width: auto;
      margin: 7px 0 40px;

      .Prev, .Next, .Number {
        display: flex;

        .item {
          @include FlexCenter;
          font-size: 14px;
          height: 28px;
          color: #183c5b;
          background-color: transparent;
          margin: 0 3px;
          padding: 0 10px;
          cursor: pointer;

          i {
            font-size: 10px;
            margin: 0 5px;
          }
        }
      }

      .Prev, .Next {
        .item.active {
          color: #57bdaa;
        }

        .item.inactive {
          color: #cacaca;
        }
      }

      .Number {
        .item.active {
          background-color: #57bdaa;
          color: #fefefe;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Recent-Comments-Container, .Flag-Comments-Container {
    table {
      width: 100%;
    }

    thead {
      display: none;
    }

    tr:nth-of-type(2n) {
      background-color: inherit;
    }

    tr td:first-child {
      background: #f0f0f0; 
      font-weight:bold;font-size:1.3em;
    }

    tbody td {
      text-align:center;
    }

    tbody td:before { 
      content: attr(data-th); 
      display: block;
      text-align:center;  
    }
  }
}
