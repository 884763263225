@import 'src/Style/Style.scss';

.Details-Container {
  .Learner-Tabs {
    @include FlexCenter;
    height: 45px;
    width: 450px;
    background-color: $white;
    border: 1px solid $light-green;
    border-radius: 4px;
    cursor: pointer;
    margin: 15px 25px 0;

    .Learner-Tab {
      @include FlexCenter;
      @include TextProps(14px, bold, $bluish-black);
      height: 100%;
      min-width: 33.3%;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .Active {
      @include LightenBackground($light-green, 20%);
    }
  }

  .Empty-Component {
    @include TextProps(18px, normal, $red);
    margin: 15px 25px 0;
  }

  .Table-Holder {
    margin: 20px;

    .Table {
      width: 100%;
      margin-bottom: 30px;

      thead {
        th {
          @include TextProps(18px, bold, $bluish-black);
          width: 20%;
          height: 50px;
          vertical-align: middle;
          padding: 0 20px;

          &:last-of-type {
            width: 40%;
          }
        }
      }

      tbody {
        tr {
          td {
            @include TextProps(16px, normal, $bluish-black);
            width: 30%;
            height: 40px;
            vertical-align: middle;
            white-space: break-spaces;
            word-break: break-word;
            padding: 0 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Details-Container {
    .Learner-Tabs {
      flex-direction: column;
      width: fit-content;
      height: auto;

      .Learner-Tab {
        height: 30px;
        padding: 0 20px;
      }
    }
  }
}
