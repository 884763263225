.ImageBox {
  width: inherit;
  height: inherit;
  object-fit: scale-down;
  object-position: center;
}

.ImageBox-Accordion {
  height: 17px;
  width: 17px;
}

.Greeting-ImageBox {
  max-width: -webkit-fill-available;
}

.ImageBox-profile-large {
  border-radius: 50%;
  margin-right: 10px;
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.ImageBox-HintBox {
  margin: 0 10px;
}

.ImageBox-LessonBoxMini {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.ImageBox-AudioPlayButton {
  height: 46px;
  width: 46px;
}

.ImageBox-UserDropdown {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 0 10px;
}

.ImageBox-UploadMedia {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-right: 20px;
}

.ImageBox-InstitutionBox {
  height: 40px;
  width: 40px;
  border: 1px solid #F0F0F0;
  margin-right: 40px;
}
