@import 'src/Style/Style.scss';

.Course-Completion-Container {
  min-height: 100vh;
  background: #e7ebed;
  margin: 50px 150px;

  .Preview-Mode-Container {
    @include FlexCenter;
    flex-direction: column;

    .Preview-Mode-Text {
      @include FlexCenter;
      @include TextProps(20px, normal, $bluish-black);
      width: 100%;
      background-color: $light-sea-blue;
      border: 1px solid #183d5b2e;
      border-radius: 4px;
      padding: 21px 33px;
      margin-bottom: 34px;
    }
  }
}


@media only screen and (max-width: 768px) {
  .Course-Completion-Container {
    margin: 20px 50px;
  }
}

@media only screen and (max-width: 450px) {
  .Course-Completion-Container {
    margin: 20px;
  }
}
