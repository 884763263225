.TrueOrFalseBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
} 

.TrueOrFalseContainer { 
  width: 100%;
}

.TrueOrFalseContainer > div:nth-child(odd){
  background-color: #fafafa;
}

.TrueOrFalseBox-QuestionBox {
  display: flex;
  align-items: center;
}

.TrueOrFalseBox-QuestionBox h6 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.TrueOrFalseBox-ButtonBox-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.TrueOrFalseBox-ButtonBox-div {
  margin: 15px;
}

.TrueOrFalseBox-Button {
  background-color: #ffffff;
  min-height: 40px;
  width: 190px;
  color: #193c5a;
  border: 1px solid #6bd1be;
  border-radius: 4px;
  transition: 0.3s;
}

.TrueOrFalseBox-Button:hover {
  background-color: #6bd1be;
  transform: scale(1.03);
  box-shadow: #32325d40 0 13px 27px -5px, #0000004d 0 8px 16px -8px;
  transition: 0.3s;
}

.TrueOrFalseBox-Button__selected {
  background-color: #6bd1be;
  min-height: 40px;
  width: 190px;
  color: #193c5a;
  border-radius: 4px;
}

.TrueOrFalseBox-Button__correct {
  background-color: #f5fcf9;
  min-height: 40px;
  width: 120px;
  color: #183D5B;
  border: 2px solid #7de3bb;
  border-radius: 3px;
}

.TrueOrFalseBox-Button__wrong {
  background-color: #fcf9f9;
  min-height: 40px;
  width: 120px;
  color: #183D5B;
  border: 2px solid #D47e7e;
  border-radius: 3px;
}

.Pin-Unanswered {
  width: 45px;
  height: 34px;
  color: #fff;
  background-color: #ff8f00;
  border-radius: 3px;
  position: absolute;
  top: 15px;
  left: -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

@media only screen and (max-width: 768px) {
  .TrueOrFalseBox {
    flex-direction: column;
  }

  .TrueOrFalseBox-ButtonBox-container {
    flex-direction: column;
  }
}
