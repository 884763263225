.TextAreaBox-SignUp {	
    height: 150px;
    width: 100%;	
    color: #183d5b;
    background-color: rgb(248, 252, 253);
    border: 1px solid rgb(126, 194, 212);
    border-radius: 5px;
    font-size: 18px;
    margin: 5px 0 20px 0;
    padding: 0 2px;	
}	

.TextAreaBox-SignUp::placeholder {	
  color: #183D5B;	
  opacity: 0.7;	
}

.TextAreaBox-CreationForms {
  height: 150px;
  width: 100%;
  color: #183D5B;
  border: 1px solid #183D5B;
  border-radius: 3px;
  font-size: 18px;
  margin: 10px 0;
  padding: 10px 25px;
}

.TextAreaBox-CreationForms::placeholder {
  color: #183D5B;
  opacity: 0.7;
}

.TextAreaBox-InviteUsers {
  border-radius: 3px;
  border: 1px solid #183D5B;
  color: #183D5B;
  font-size: 18px;
  height: 150px;
  margin: 10px 0;
  padding: 10px 25px;
  width: 60%;
}
