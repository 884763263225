.ProgressBar-Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ProgressBar-Container .ProgressBar {
  flex-grow: 9;
  height: 10px;
  border-radius: 15px;
}

.ProgressBar-Container .ProgressBar .ProgressBar-Filler {
  height: 100%;
  border-radius: 15px;
  transition: width 2s;
}

.ProgressBar-Container .ProgressBar-Text {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.ProgressBar-Container .ProgressBar-Text p:first-child {
  display: none;
}

.ProgressBar-Container .ProgressBar-Text p:last-child {
  margin-right: 5px;
}

.ProgressBar-Move-Container {
  display: block;
  width: 100%;
  margin-top: 10px;
}

.ProgressBar-Move-Container .ProgressBar {
  height: 12px;
  background-color: #f2f2f2;
  border-radius: 13px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.ProgressBar-Move-Container .ProgressBar .ProgressBar-Filler {
  height: 100%;
  transition: width 2s;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}

.ProgressBar-Move-Container .ProgressBar-Text p {
  color: #6bd1be;
  font-size: 13px;
  margin-top: 5px;
  transition: margin-left 2s;
}

@media only screen and (max-width: 768px) {
  .ProgressBar-Container {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .ProgressBar-Container .ProgressBar {
    display: inline-block;
    width: 100%;
    height: 8px;
    border-radius: 15px;
  }

  .ProgressBar-Container .ProgressBar-Text {
    margin-left: 0px;
  }

  .ProgressBar-Container .ProgressBar-Text p:first-child {
    display: initial;
    margin-right: 5px;
    margin-bottom: 15px;
  }
}
