.Admin-Sidebar {
  min-height: calc(100vh - 79px);
  display: flex;
  flex-direction: column;
  background-color: rgb(26, 26, 26);
  width: 330px;
  color: white;
}

.Sidebar-User {
  margin: 20px auto;
  text-align: center;
}

.Admin-Sidebar-Menu {
  margin: 20px;
}

.Admin-Sidebar-Menu-Title {
  margin-bottom: 30px;
}

.Admin-Sidebar-Menu-Items {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-bottom: 20px;
}

.Admin-Sidebar-Menu-Items a {
  margin-bottom: 15px;
}

@media only screen and (max-width: 768px) {
  .Admin-Sidebar {
    position: absolute;
    z-index: 10;
    width: 285px;
    left: -285px;
    transition: left 0.5s;
  }

  .Admin-Sidebar .SideBar-Collapse-Button {
    position: absolute;
    top: -32px;
    right: -32px;
  }

  .Admin-Sidebar .SideBar-Collapse-Button .ButtonBox-SideBar i {
    color: #ffffff;
  }

  .Admin-Sidebar-show {
    position: absolute;
    left: 0;
  }
}
