@import 'src/Style/Style.scss';

.UserPreference {
  @include FlexColumn;
  width: 100%;

  .Email-Notifications {
    .Sub-Title {
      @include TextProps(15px, bold, $bluish-black);
      margin-bottom: 10px;
    }

    .Notice {
      @include FlexProps(center, flex-start);
      border: 1px solid $blue;
      border-radius: 5px;
      padding: 10px;

      .Icon {
        margin-right: 20px;
        color: $blue;
      }

      .Text {
        @include TextProps(16px, bold, $blue);
      }
    }
  }
}
