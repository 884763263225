@import 'src/Style/Style.scss';

.InstitutionSwitch-Container {
  @include FlexCenter;
  height: 50px;
  width: 100%;
  background-color: $white;
  border-bottom: 1px solid $gray;
  padding: 0 5px;

  .Switcher {
    display: flex;
    @include FullHW;
    @include TextProps(16px, bold, $bluish-black);
    border: none;
    text-indent: 40px;
  }
}
