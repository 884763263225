.CheckBox {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 24px;
  width: 24px;
  clear: both;
}

.CheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.CheckBox .CheckBox-Custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 3px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid #7fc3d5;
}


.CheckBox input:checked ~ .CheckBox-Custom {
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity:1;
  border: 2px solid #7fc3d5;
}


.CheckBox .CheckBox-Custom::after {
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
  height: 0px;
  width: 0px;
  border-radius: 3px;
  border: solid #7fc3d5;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity:1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}


.CheckBox input:checked ~ .CheckBox-Custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity:1;
  left: 5px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid #7fc3d5;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}
