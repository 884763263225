.SectionSummaryBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 960px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.ViewComments .modal {
  width: 30%;
  left: auto;
  right: 0;
  margin-top: 78px;
}

.ViewComments .modal .modal-content {
  height: 100%;
  width: 100%;
}

.ViewComments .ViewComments-Title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ViewComments .ViewComments-Title div {
  display: flex;
}

.ViewComments .ViewComments-New {
  margin: 5px 0;
}

.ViewComments .ViewComments-Content .Comment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}

.Comments-Load {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.2;
  z-index: 99999;
}

.Comments-Load {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
