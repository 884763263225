.TextContentBox-FunctionBox {
  margin: 10px;
}

.TextContentBox-TableBox {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .TextContentBox-TableBox {
    margin: 20px 0;
  }
}