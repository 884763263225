@import 'src/Style/Style.scss';

.Modify-User-Container {
    .ErrorsFlashBox-Error {
        width: auto;
    }

    .Manage-User-Access {
        .Full-Width {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .CreateUser-Update-Button {
            margin-bottom: 5px;
        }

        .Verify-Body {
            position: relative;

            .ValidEmail {
                background-color: $light-green;
                border-color: rgb(107, 209, 190);
                color: white;
            }

            .Check-Icon {
                display: inline-block;
                position: absolute;
                right: 20px;
                top: 5px;
                color: $white;
                font-size: 30px;
            }
        }
    }

    .User-Types-Container {
        .RadioBox-Holder {
            .RadioBox {
                @include TextProps(16px, normal, $bluish-black);
                margin-bottom: 10px;

                input {
                    margin-right: 5px;
                }
            }
        }

        .Selected-Type {
            .Note {
                @include TextProps(16px, normal, $bluish-black);
                background-color: $lightest-gray;
                border-radius: 5px;
                padding: 20px;
                margin: 20px 0;
                line-height: 23px;
            }
        }
    }

    .Button-Container {
        @include FlexProps(center, space-between);

        .DashboardFormBox-Button_cancel {
            border: 1px solid $lightest-gray;
            margin: 0;
        }
    }
}

.CreateUser {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    border: none;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
}

.CreateUser-Update-Button {
    align-self: start;
    background-color: white;
    border: 0;
    color: #6bd1be;
    cursor: pointer;
    font-family: 'system-ui';
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
    padding: 0px;
}

.CreateUser-Update-Button:hover {
    color: #669dac;
}

.CreateUser .disabled_save_button {
    background-color: #dddddd;
    color: #000000;
}

.SharedAdminDashboard-GroupList__container {
    padding: 0 1rem;
}

.CreateUser input[type=text] {
    background-color: rgb(248, 252, 253);
    border-radius: 5px;
    border: 1px solid rgb(126, 194, 212);
    color: #183d5b;
    font-size: 18px;
    height: 40px;
    margin: 5px 0 20px 0;
    padding: 0 8px;
}

.CreateUser input[type=password] {
    background-color: rgb(248, 252, 253);
    border-radius: 5px;
    border: 1px solid rgb(126, 194, 212);
    color: #183d5b;
    font-size: 18px;
    height: 40px;
    margin: 5px 0 20px 0;
    padding: 0 8px;
}

.CreateUser i.fas {
    display: none;
}

.CreateUser i.ValidEmail.fas {
    color: #6bd1be;
    color: white;
    display: inline-block;
    font-size: 30px;
    
    line-height: 40px;
    position: absolute;
}

/* Phones (portrait phones, 0px and up) */
@media (min-width: 0px) {
    .CreateUser i.ValidEmail.fas {
        left: 75vw;
    }
    .createUserContainer {
        width: 85vw;
        margin: auto;
        margin-top: 30px;
        padding: 2.5vw;
        &__button {
            width: 50%;
        };
    }
    .formWidth {
        width: 100%;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .CreateUser i.ValidEmail.fas {
        left: 70vw;
    }
    .createUserContainer {
        width: 70vw;
        margin: auto;
        margin-top: 30px;
        padding: 2.5vw;
        &__button {
            width: 50%;
        }
    }
    .formWidth {
        width: 80%;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
    .CreateUser i.ValidEmail.fas {
        left: 80vw;
    }
    .createUserContainer {
        width: 60vw;
        margin: auto;
        margin-top: 30px;
        padding: 2.5vw;
        &__button {
            width: 50%;
        }
    }
    .formWidth {
        width: 80%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .CreateUser i.ValidEmail.fas {
        left: 73vw;
    }
    .createUserContainer {
        width: 50vw;
        margin: auto;
        margin-top: 30px;
        padding: 2.5vw;
        &__button {
            width: 50%;
        }
    }
    .formWidth {
        width: 70%;
    }
}

/* Wide devices (desktops, 1400px and up) */
@media (min-width: 1400px) {
    .CreateUser i.ValidEmail.fas {
        left: 68vw;
    }
    .createUserContainer {
        width: 40vw;
        margin: auto;
        margin-top: 30px;
        padding: 2.5vw;
        &__button {
            width: 50%;
        }
    }
    .formWidth {
        width: 70%;
    }
}
