.User-Side-Bar {
  color: white;
  display: flex;
  width: 330px;
  flex-direction: column;
  min-height: calc(100vh - 79px);
  background-color: rgba(24, 60, 91, 0.18);
}

.User-Side-Bar-Switch {
  display: flex;
  height: auto;
  width: 100%;
}

.User-Side-Bar-List {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.User-Side-Bar-Item {
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #183c5b;
  margin: 10px 0 10px 30px;
  cursor: pointer;
  letter-spacing: 2px;
}

.User-Side-Bar-Item:hover {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: rgb(231, 235, 237);
}

.User-Side-Bar-Item-Selected {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: rgb(231, 235, 237);
}

.User-Side-Bar-Item.Disabled {
  opacity: 0.5;
}

.User-Side-Bar-Item-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 100%;
  margin-bottom: 24px;
}

.User-Side-Bar-Item-2 .Item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 240px;
  font-family: 'LATO';
  font-size: 14px;
  font-weight: bold;
  color: #183c5b;
  border: 1px solid #183c5b;
  border-radius: 30px;
  letter-spacing: 2px;
}

.User-Side-Bar-Item-2.Resource-Button .Item,
.User-Side-Bar-Item-2.Study-Cards-Button .Item {
  background-color: #193c5a;
}

.User-Side-Bar-Item-2.Resource-Button .Item span,
.User-Side-Bar-Item-2.Study-Cards-Button .Item span {
  color: #ffffff;
}

.User-Side-Bar-Image {
  width: 35px;
  height: 35px;
  margin: 0 20px 0 18px;
}

@media only screen and (max-width: 768px) {
  .User-Side-Bar {
    z-index: 10;
    width: 285px;
    left: -285px;
    position: absolute;
    transition: left 0.5s;
    background-color: #183c5b;
  }

  .User-Side-Bar .SideBar-Collapse-Button {
    position: absolute;
    top: -32px;
    right: -32px;
  }

  .User-Side-Bar .SideBar-Collapse-Button .ButtonBox-SideBar i {
    color: #ffffff;
  }

  .User-Side-Bar-Item {
    color: #fff;
  }

  .User-Side-Bar-Item-Selected {
    color: #183c5b;
  }

  .User-Side-Bar-Item-2 .Item {
    color: #fff;
    border-color: #fff;
  }

  .User-Side-Bar-Show {
    left: 0;
    position: absolute;
  }
}
