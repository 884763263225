.DialogBox{
  width: calc(100% - 20px);
  margin-bottom: 20px;
  padding: 0 10px;
}

.No-Recording-RolePlay button {
  opacity: 0.25;
}

.DialogBox-RolePlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DialogBox-Box {
  display: flex;
  align-items: center;
  min-height: 117px;
  background-color: #ffffff;
  background-size: cover;
  padding: 5px 150px;
}

.DialogBox-Box:nth-child(odd) {
  background-color: #fafafa;
}

.DialogBox-Audio {
  display: flex;
  align-items: flex-start;
  height: 100px;
}

.DialogBox-Content {
  display: flex;
  flex-direction: column;
}

.DialogBox-Picture {
  margin: 0 10px 20px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #dadada;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.DialogBox-Picture img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.DialogBox > div:nth-child(even){
  flex-direction: row-reverse;
}

.DialogBox-Content-Body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.DialogBox-Content-Body p {
  margin: 0 2px;
}

.DialogBox-Content-Body input {
  margin: 3px 5px;
  border: none;
  border-bottom: 2px solid #6bd1be;
  color: #183D5B;
  font-size: 18px;
  text-align: center;
  min-width: 80px;
  max-width: 600px;
}

.DialogBox-ImageBox {
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}

.DialogBox-Content-Hint {
  min-height: 20px;
  margin: 13px 0 0 0;
}

.DialogBox-Content-Hint .Hint-Control {
  height: 16px;
  width: 100%;
  justify-content: flex-start;
  padding: 0;
}

.DialogBox-Content-Hint .Hint-Control h3 {
  font-family: 'LATO';
  font-size: 13px;
  font-weight: bold;
  color: #6bd1be;
}

.DialogBox-Content-Hint .Hint-Reveal {
  display: flex;
  align-items: center;
}

.DialogBox-Content-Hint .Hint-Text {
  color: #183d5b90;
  padding: 3px 0;
}

@media screen and (min-width: 2300px) {
  .DialogBox-Content-Hint .Hint-Control h3 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1300px) {
  .DialogBox-Content-Body input {
    max-width: 400px;
  }
}

@media only screen and (max-width:  1100px) {
  .DialogBox-Box {
    padding: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .DialogBox-Content-Body input {
    max-width: 250px;
  }
}

@media only screen and (max-width: 500px) {
  .DialogBox-Box {
    flex-wrap: wrap;
  }

  .DialogBox-Content-Body input {
    max-width: 200px;
  }
}
