.CreateEditTableEntry-dropzone {
  margin: 20px 0;
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  color: #3d6280;
  border: 1px solid #cccccc;
  background-color: white;
  cursor: pointer;
}