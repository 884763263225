@import 'src/Style/Style.scss';

.B2B-Admin-Filters-Container {
  @include FlexColumn;
  margin: 0 62px 20px;

  .Top-Filters {
    display: flex;
    width: 100%;
  }

  .Mid-Filters {
    display: flex;
    width: 100%;
    margin: 5px 0;

    .Languages-Filter {
      @include FlexCenter;
      height: 45px;
      width: auto;
      background-color: $white;
      border: 1px solid $light-green;
      border-radius: 4px;
      cursor: pointer;

      .Language {
        @include FlexCenter;
        @include TextProps(14px, bold, $bluish-black);
        height: 100%;
        width: 150px;
        text-align: center;

        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      .Selected {
        background: #6bd1be80;
      }
    }
  }

  .Bottom-Filters {
    @include FlexProps(center, flex-end);
    width: 100%;
    flex-wrap: wrap;
  }

  .Filters {
    .Filter-Dropdown {
      @include FlexCenter;
      position: relative;
      background-color: $white;
      border: 1px solid #7ec2d4;
      border-radius: 4px;
      margin: 2px 9px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      .Icon, .Image {
        position: absolute;
        color: #6D86A1;
        left: 16px;
        width: fit-content;
      }

      .Icon, .Image, .Drop-Down-Arrow-Icon {
        pointer-events: none;
      }

      .Select {
        @include TextProps(16px, normal, $bluish_black);
        @include BacBorNone;
        width: 220px;
        height: 45px;
        text-transform: capitalize;
        outline: none;
        appearance: none;
        padding: 0px 26px 0px 45px;
        cursor: pointer;

        option {
          @include FlexCenter;
          height: 22px;
          margin-bottom: 1px;
          padding-left: 39px;
          text-decoration: start;

          &:hover {
            color: $white;
            background: #00a6ed;
          }
        }
      }

      .Drop-Down-Arrow-Icon {
        position: absolute;
        right: 8px;
        color: #6A6A6A;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .B2B-Admin-Filters-Container {
    .Mid-Filters {
      margin-right: 0;
      margin-bottom: 10px;
    }

    .Bottom-Filters {
      justify-content: flex-start;
    }
  }
}

@media only screen and (max-width: 450px) {
  .B2B-Admin-Filters-Container {
    margin: 0 20px 20px;

    .Mid-Filters {
      .Languages-Filter {
        width: 230px;
      }
    }

    .Filters {
      .Filter-Dropdown {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
